const songData: {id: number; singerKor: string; singerEng: string; songKor: string; songEng: string;}[] = [
    {"id":1,"singerKor":"aespa","singerEng":"aespa","songKor":"Dreams Come True","songEng":"Dreams Come True"},
    {"id":2,"singerKor":"aespa","singerEng":"aespa","songKor":"Girls","songEng":"Gee"},
    {"id":3,"singerKor":"aespa","singerEng":"aespa","songKor":"Illusion","songEng":"Illusion"},
    {"id":4,"singerKor":"aespa","singerEng":"aespa","songKor":"Next Level","songEng":"Next Level"},
    {"id":5,"singerKor":"aespa","singerEng":"aespa","songKor":"Savage","songEng":"Savage"},
    {"id":6,"singerKor":"aespa","singerEng":"aespa","songKor":"도깨비불 (Illusion)","songEng":"Illusion"},
    {"id":7,"singerKor":"AKMU (악동뮤지션)","singerEng":"AKMU (악동뮤지션)","songKor":"200%","songEng":"200%"},
    {"id":8,"singerKor":"AKMU (악동뮤지션)","singerEng":"AKMU (악동뮤지션)","songKor":"Crescendo (크레셴도)","songEng":"Crescendo"},
    {"id":9,"singerKor":"AKMU (악동뮤지션)","singerEng":"AKMU (악동뮤지션)","songKor":"Give Love","songEng":"Give Love."},
    {"id":10,"singerKor":"AKMU (악동뮤지션)","singerEng":"AKMU (악동뮤지션)","songKor":"낙하 (With 아이유)","songEng":"Nakka (With IU)"},
    {"id":11,"singerKor":"AKMU (악동뮤지션)","singerEng":"AKMU (악동뮤지션)","songKor":"라면인건가","songEng":"Is this Love?"},
    {"id":12,"singerKor":"AKMU (악동뮤지션)","singerEng":"AKMU (악동뮤지션)","songKor":"매력있어","songEng":"Attractive."},
    {"id":13,"singerKor":"AKMU (악동뮤지션)","singerEng":"AKMU (악동뮤지션)","songKor":"어떻게 이별까지 사랑하겠어_ 널 사랑하는 거지","songEng":"How can I love you up to Separation_ I love you."},
    {"id":14,"singerKor":"AKMU (악동뮤지션)","singerEng":"AKMU (악동뮤지션)","songKor":"오랜 날 오랜 밤","songEng":"Long Night"},
    {"id":15,"singerKor":"AKMU (악동뮤지션)","singerEng":"AKMU (악동뮤지션)","songKor":"외국인의 고백","songEng":"Confession of a Foreigner"},
    {"id":16,"singerKor":"AKMU (악동뮤지션)","singerEng":"AKMU (악동뮤지션)","songKor":"콩떡빙수","songEng":"Kong Tteok Bing Su"},
    {"id":17,"singerKor":"AKMU (악동뮤지션)","singerEng":"AKMU (악동뮤지션)","songKor":"How can I love the heartbreak_ you're the one I love","songEng":"How can I love the heartbreak_ you're the one I love"},
    {"id":18,"singerKor":"AKMU (악동뮤지션)","singerEng":"AKMU (악동뮤지션)","songKor":"Re Bye","songEng":"Re Bye"},
    {"id":19,"singerKor":"AKMU_ 아이유(IU)","singerEng":"AKMU_ 아이유(IU)","songKor":"NAKKA (with IU)","songEng":"NAKKA (with IU)"},
    {"id":20,"singerKor":"An Nyeong","singerEng":"An Nyeong","songKor":"haeyo (2022)","songEng":"haeyo (2022)"},
    {"id":21,"singerKor":"Anandelight_ unofficialboyy_ BE′O_ Geegooin_ Mudd the student_ MINO","singerEng":"Anandelight_ unofficialboyy_ BE′O_ Geegooin_ Mudd the student_ MINO","songKor":"BREATHE","songEng":"Breathe"},
    {"id":22,"singerKor":"AOA","singerEng":"AOA","songKor":"단발머리 (Short Hair)","songEng":"Short Hair"},
    {"id":23,"singerKor":"AOA","singerEng":"AOA","songKor":"빙글뱅글 (Bingle Bangle)","songEng":"Bingle Bangle"},
    {"id":24,"singerKor":"AOA","singerEng":"AOA","songKor":"사뿐사뿐","songEng":"Bboom Bboom"},
    {"id":25,"singerKor":"AOA","singerEng":"AOA","songKor":"심쿵해 (Heart Attack)","songEng":"Heart Attack"},
    {"id":26,"singerKor":"AOA","singerEng":"AOA","songKor":"짧은 치마 (Miniskirt)","songEng":"Miniskirt"},
    {"id":27,"singerKor":"Apink (에이핑크)","singerEng":"Apink (에이핑크)","songKor":"Dilemma","songEng":"Dilemma"},
    {"id":28,"singerKor":"Apink (에이핑크)","singerEng":"Apink (에이핑크)","songKor":"FIVE","songEng":"FIVE"},
    {"id":29,"singerKor":"Apink (에이핑크)","singerEng":"Apink (에이핑크)","songKor":"LUV","songEng":"LUV"},
    {"id":30,"singerKor":"Apink (에이핑크)","singerEng":"Apink (에이핑크)","songKor":"Mr. Chu (On Stage)","songEng":"Mr Chu (On Stage)"},
    {"id":31,"singerKor":"Apink (에이핑크)","singerEng":"Apink (에이핑크)","songKor":"NoNoNo","songEng":"Nonono"},
    {"id":32,"singerKor":"Apink (에이핑크)","singerEng":"Apink (에이핑크)","songKor":"Remember","songEng":"Remember"},
    {"id":33,"singerKor":"Apink (에이핑크)","singerEng":"Apink (에이핑크)","songKor":"덤더럼(Dumhdurum)","songEng":"Dumhdurum"},
    {"id":34,"singerKor":"ASH ISLAND","singerEng":"ASH ISLAND","songKor":"멜로디","songEng":"Melody."},
    {"id":35,"singerKor":"B1A4","singerEng":"B1A4","songKor":"Lonely (없구나)","songEng":"Lonely (없구나)"},
    {"id":36,"singerKor":"B1A4","singerEng":"B1A4","songKor":"Solo Day","songEng":"Solo Day"},
    {"id":37,"singerKor":"Baby V.O.X","singerEng":"Baby VOX","songKor":"Why","songEng":"Why"},
    {"id":38,"singerKor":"Baek A 백아","singerEng":"Baek A","songKor":"Amor","songEng":"Amor"},
    {"id":39,"singerKor":"Basick_ Leellamarz_ TOIL","singerEng":"Basick_ Leellamarz_ TOIL","songKor":"Meeting is easy_ parting is hard (Feat. Leellamarz) (Prod. TOIL)","songEng":"Meeting is easy parting is hard (Feat. Leellamarz) (Prod. TOIL)"},
    {"id":40,"singerKor":"BE'O (비오)","singerEng":"BE'O (비오)","songKor":"Counting Stars (Feat. Beenzino)","songEng":"Counting Stars (Feat. Beenzino)"},
    {"id":41,"singerKor":"BE'O (비오)","singerEng":"BE'O (비오)","songKor":"MBTI (Feat. 쿠기 & 로꼬) (Prod. GRAY)","songEng":"MBTI (Feat. Kookie & Loco) (Prod. GRAY)"},
    {"id":42,"singerKor":"BE'O (비오)","singerEng":"BE'O (비오)","songKor":"Counting Stars (Feat. Beenzino)","songEng":"Counting Stars (Feat Beenzino)"},
    {"id":43,"singerKor":"BE'O (비오)","singerEng":"BE'O (비오)","songKor":"LOVE me","songEng":"Love Me."},
    {"id":44,"singerKor":"BE'O (비오)","singerEng":"BE'O (비오)","songKor":"네가 없는 밤 (Feat. ASH ISLAND) (Prod. by GRAY)","songEng":"Night Without You (Feat. ASH ISLAND) (Prod. by GRAY)"},
    {"id":45,"singerKor":"BE'O (비오)","singerEng":"BE'O (비오)","songKor":"문득","songEng":"Suddenly"},
    {"id":46,"singerKor":"BE'O (비오)","singerEng":"BE'O (비오)","songKor":"자격지심 (Feat. ZICO)","songEng":"inferiority complex (Feat. ZICO)"},
    {"id":47,"singerKor":"BE'O (비오)_ Beenzino","singerEng":"Beenzino","songKor":"Counting Stars (Feat. Beenzino)","songEng":"Counting Stars (Feat Beenzino)"},
    {"id":48,"singerKor":"BE'O_ Beenzino","singerEng":"BE'O_ Beenzino","songKor":"Counting Stars (Feat. Beenzino)","songEng":"Counting Stars (Feat. Beenzino)"},
    {"id":49,"singerKor":"BE'O_ ZICO","singerEng":"BE'O_ ZICO","songKor":"Complex (Feat. ZICO)","songEng":"Complex (Feat ZICO)"},
    {"id":50,"singerKor":"Beenzino","singerEng":"Beenzino","songKor":"Boogie On & On","songEng":"Boogie On & On"},
    {"id":51,"singerKor":"Beenzino","singerEng":"Beenzino","songKor":"Dali_ Van_ Picasso","songEng":"Dali Van Picasso"},
    {"id":52,"singerKor":"Benson Boone","singerEng":"Benson Boone","songKor":"GHOST TOWN","songEng":"Ghost Town"},
    {"id":53,"singerKor":"BewhY (비와이)","singerEng":"BewhY","songKor":"Day Day (Feat. 박재범) (Prod. by GRAY)","songEng":"Day Day (Feat. Jay Park) (Prod. by GRAY)"},
    {"id":54,"singerKor":"BewhY (비와이)","singerEng":"BewhY","songKor":"Forever (Prod. By GRAY)","songEng":"Forever (Prod. By GRAY)"},
    {"id":55,"singerKor":"BewhY (비와이)","singerEng":"BewhY","songKor":"The Time Goes On","songEng":"The Time Goes On."},
    {"id":56,"singerKor":"Beyond","singerEng":"Beyond","songKor":"얼마나 (Feat. 버블시스터즈)","songEng":"How Much (Feat. Bubble Sisters)"},
    {"id":57,"singerKor":"BE′O (비오)","singerEng":"BE′O (비오)","songKor":"리무진 (Feat. MINO)","songEng":"Limousine (Feat. MINO)"},
    {"id":58,"singerKor":"BE′O","singerEng":"BE′O (비오)","songKor":"Suddenly","songEng":"Suddenly"},
    {"id":59,"singerKor":"BE′O_ ASH ISLAND","singerEng":"BE′O (비오)_ Ash Island","songKor":"네가 없는 밤 (Feat. ASH ISLAND) (Prod. GRAY)","songEng":"Night Without You (Feat. ASH ISLAND) (Prod. GRAY)"},
    {"id":60,"singerKor":"BE′O_ Mino_ Gray","singerEng":"BE′O_ Mino_ Gray","songKor":"Limousine (Feat. MINO) (Prod. GRAY)","songEng":"Limousine (Feat MINO) (Prod GRAY)"},
    {"id":61,"singerKor":"BIG Naughty (서동현)","singerEng":"BIG Naughty (서동현)","songKor":"낭만교향곡 (Feat. CHANGMO & 박재범)","songEng":"Romantic Symphony (Feat. CHANGMO & Jay Park)."},
    {"id":62,"singerKor":"BIG Naughty (서동현)","singerEng":"BIG Naughty (서동현)","songKor":"정이라고 하자 (Feat. 10CM)","songEng":"Let's call it Affection (Feat. 10CM)"},
    {"id":63,"singerKor":"BIG Naughty_ 10cm","singerEng":"BIG Naughty_ 10cm","songKor":"Beyond Love (Feat. 10CM)","songEng":"Beyond Love (Feat 10CM)"},
    {"id":64,"singerKor":"BIG Naughty_ CHANGMO_ Jay Park","singerEng":"BIG Naughty_ CHANGMO_ Jay Park","songKor":"Romance Symphony (Feat. CHANGMO_ Jay Park)","songEng":"Romance Symphony (Feat CHANGMO Jay Park)"},
    {"id":65,"singerKor":"BIG Naughty_ Kim Min Seok","singerEng":"BIG Naughty_ Kim Min Seok","songKor":"Fxxxnds (Feat. Kim Min Seok of MeloMance)","songEng":"Fxxxnds (Feat. Kim Min Seok of MeloMance)"},
    {"id":66,"singerKor":"BIG Naughty_ Lee Suhyun","singerEng":"BIG Naughty Lee Suhyun","songKor":"Hopeless Romantic (Feat. LEE SUHYUN)","songEng":"Hopeless Romantic (Feat. LEE SUHYUN)"},
    {"id":67,"singerKor":"Big4","singerEng":"Big4","songKor":"Untouchable","songEng":"Untouchable"},
    {"id":68,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"BAE BAE","songEng":"BAE BAE."},
    {"id":69,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"How Gee","songEng":"How Gee"},
    {"id":70,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"IF YOU","songEng":"IF YOU"},
    {"id":71,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"Lady","songEng":"Lady"},
    {"id":72,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"LAST DANCE","songEng":"LAST DANCE"},
    {"id":73,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"LOSER","songEng":"LOSER"},
    {"id":74,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"Oh My Friend","songEng":"Oh My Friend."},
    {"id":75,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"Still Life","songEng":"Still Life"},
    {"id":76,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"WE LIKE 2 PARTY","songEng":"WE LIKE 2 PARTY"},
    {"id":77,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"꽃 길","songEng":"Flower Road"},
    {"id":78,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"마지막 인사","songEng":"Last Goodbye"},
    {"id":79,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"맨정신","songEng":"Sober"},
    {"id":80,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"뱅뱅뱅 (BANG BANG BANG)","songEng":"Bang Bang Bang"},
    {"id":81,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"봄여름가을겨울 (Still Life)","songEng":"Still Life"},
    {"id":82,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"우리 사랑하지 말아요","songEng":"Let's Not Fall in Love"},
    {"id":83,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"쩔어 (GD & T.O.P)","songEng":"Zutter"},
    {"id":84,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"착한사람","songEng":"Kind Person."},
    {"id":85,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"천국","songEng":"Heaven."},
    {"id":86,"singerKor":"BIGBANG (빅뱅)","singerEng":"BIGBANG (빅뱅)","songKor":"하루하루","songEng":"Day by Day."},
    {"id":87,"singerKor":"Black Pearl (블랙펄)","singerEng":"Black Pearl (블랙펄)","songKor":"결국... 너잖아","songEng":"At Last... It's You"},
    {"id":88,"singerKor":"BLACKPINK","singerEng":"BLACKPINK","songKor":"How You Like That","songEng":"How You Like That"},
    {"id":89,"singerKor":"BLACKPINK","singerEng":"BLACKPINK","songKor":"Kill This Love","songEng":"Kill This Love"},
    {"id":90,"singerKor":"BLACKPINK","singerEng":"BLACKPINK","songKor":"Lovesick Girls","songEng":"Lovesick Girls"},
    {"id":91,"singerKor":"BLACKPINK","singerEng":"BLACKPINK","songKor":"Pink Venom","songEng":"Pink Venom"},
    {"id":92,"singerKor":"BLACKPINK","singerEng":"BLACKPINK","songKor":"Ready For Love","songEng":"Ready For Love"},
    {"id":93,"singerKor":"BLACKPINK","singerEng":"BLACKPINK","songKor":"Shut Down","songEng":"Shut Down"},
    {"id":94,"singerKor":"BLACKPINK","singerEng":"BLACKPINK","songKor":"Yeah Yeah Yeah","songEng":"Yeah Yeah Yeah"},
    {"id":95,"singerKor":"BLACKPINK","singerEng":"BLACKPINK","songKor":"뚜두뚜두 (DDU-DU DDU-DU)","songEng":"DDU-DU DDU-DU"},
    {"id":96,"singerKor":"BLACKPINK","singerEng":"BLACKPINK","songKor":"마지막처럼","songEng":"As if it's the last one"},
    {"id":97,"singerKor":"BLACKPINK","singerEng":"BLACKPINK","songKor":"불장난","songEng":"fire playing"},
    {"id":98,"singerKor":"BLACKPINK","singerEng":"BLACKPINK","songKor":"휘파람","songEng":"Whistle"},
    {"id":99,"singerKor":"Block B","singerEng":"Block B","songKor":"난리나","songEng":"Nanlina"},
    {"id":100,"singerKor":"BMK","singerEng":"BMK","songKor":"꽃피는 봄이 오면","songEng":"When Spring Comes_ the Flowers Will Bloom."},
    {"id":101,"singerKor":"BMK","singerEng":"BMK","songKor":"하루살이","songEng":"Day by Day."},
    {"id":102,"singerKor":"BoA","singerEng":"BoA","songKor":"ID; Peace B","songEng":"ID Peace B"},
    {"id":103,"singerKor":"BoA","singerEng":"BoA","songKor":"Valenti","songEng":"Valenti"},
    {"id":104,"singerKor":"Bobby","singerEng":"Bobby","songKor":"연결고리 #힙합","songEng":"Connection #HipHop"},
    {"id":105,"singerKor":"Bobby Kim","singerEng":"Bobby Kim","songKor":"고래의 꿈 (Falling In Love Again) (Feat. 김영근 [Bobby's Father])","songEng":"Falling In Love Again (Feat. Kim Young-geun [Bobby's Father])"},
    {"id":106,"singerKor":"BOL4","singerEng":"BOL4","songKor":"Bom","songEng":"Bom"},
    {"id":107,"singerKor":"BOL4","singerEng":"BOL4","songKor":"Love story","songEng":"Love story"},
    {"id":108,"singerKor":"BOL4","singerEng":"BOL4","songKor":"Seoul","songEng":"Seoul"},
    {"id":109,"singerKor":"Boramiyu_ SUNNYSIDE MJ","singerEng":"Boramiyu SUNNYSIDE MJ","songKor":"Ring Back Tone","songEng":"Ring Back Tone"},
    {"id":110,"singerKor":"Brave Girls","singerEng":"Brave Girls","songKor":"Rollin'","songEng":"Rollin'"},
    {"id":111,"singerKor":"Bro","singerEng":"BTS","songKor":"그런 남자","songEng":"That Man."},
    {"id":112,"singerKor":"Brown Eyed Girls","singerEng":"Brown Eyed Girls","songKor":"Abracadabra","songEng":"Abracadabra"},
    {"id":113,"singerKor":"Brown Eyed Girls","singerEng":"Brown Eyed Girls","songKor":"클렌징크림","songEng":"Cleansing Cream"},
    {"id":114,"singerKor":"Brown Eyes","singerEng":"Brown Eyes","songKor":"가지마 가지마","songEng":"Don't Leave Don't Leave"},
    {"id":115,"singerKor":"BrownEyedGirls (브라운아이드걸스)","singerEng":"BrownEyedGirls","songKor":"You","songEng":"You"},
    {"id":116,"singerKor":"BSS","singerEng":"BSS","songKor":"LUNCH","songEng":"LUNCH"},
    {"id":117,"singerKor":"BSS_ Lee Young Ji","singerEng":"BSS_ Lee Young Ji","songKor":"Fighting","songEng":"Fighting"},
    {"id":118,"singerKor":"BSS_ Peder Elias","singerEng":"BSS_ Peder Elias","songKor":"오후 7:00","songEng":"오후 7:00"},
    {"id":119,"singerKor":"BTS","singerEng":"BTS","songKor":"Born Singer","songEng":"Born Singer"},
    {"id":120,"singerKor":"BTS","singerEng":"BTS","songKor":"Butter","songEng":"Butter"},
    {"id":121,"singerKor":"BTS","singerEng":"BTS","songKor":"Dynamite","songEng":"Dynamite"},
    {"id":122,"singerKor":"BTS","singerEng":"BTS","songKor":"For Youth","songEng":"For Youth"},
    {"id":123,"singerKor":"BTS","singerEng":"BTS","songKor":"Permission to Dance","songEng":"Permission to Dance"},
    {"id":124,"singerKor":"BTS","singerEng":"BTS","songKor":"Run BTS","songEng":"Run BTS"},
    {"id":125,"singerKor":"BTS","singerEng":"BTS","songKor":"Spring Day","songEng":"Spring Day"},
    {"id":126,"singerKor":"BTS","singerEng":"BTS","songKor":"Yet To Come","songEng":"Yet To Come"},
    {"id":127,"singerKor":"BTS_ Halsey","singerEng":"BTS_ Halsey","songKor":"Boy With Luv (feat. Halsey)","songEng":"Boy With Luv (feat. Halsey)"},
    {"id":128,"singerKor":"Busker Busker","singerEng":"Busker Busker","songKor":"Cherry Blossom Ending","songEng":"Cherry Blossom Ending"},
    {"id":129,"singerKor":"CB Mass","singerEng":"CB Mass","songKor":"동네 한 바퀴","songEng":"Neighborhood Ride"},
    {"id":130,"singerKor":"CB MASS(씨비 매스)","singerEng":"CB MASS(씨비 매스)","songKor":"휘파람","songEng":"Whistle"},
    {"id":131,"singerKor":"CHEEZE(치즈)","singerEng":"CHEEZE(치즈)","songKor":"이렇게 좋아해 본 적이 없어요","songEng":"I've Never Loved Someone Like This Before"},
    {"id":132,"singerKor":"Choi Yu Ree","singerEng":"Choi Yu Ree","songKor":"You may live in happiness (2022)","songEng":"You may live in happiness (2022)"},
    {"id":133,"singerKor":"Click-B","singerEng":"Click B","songKor":"Cowboy","songEng":"Cowboy"},
    {"id":134,"singerKor":"CNBLUE","singerEng":"CNBLUE","songKor":"Hey You","songEng":"Hey You"},
    {"id":135,"singerKor":"Coldplay & 방탄소년단","singerEng":"Coldplay_ BTS","songKor":"My Universe","songEng":"My Universe"},
    {"id":136,"singerKor":"Coldplay_ 방탄소년단 (BTS)","singerEng":"Coldplay_ BTS","songKor":"My Universe","songEng":"My Universe"},
    {"id":137,"singerKor":"Coldplay_ 방탄소년단","singerEng":"Coldplay_ BTS","songKor":"My Universe","songEng":"My Universe"},
    {"id":138,"singerKor":"Cool(쿨)","singerEng":"Cool","songKor":"Jumpo Mambo","songEng":"Jump Mambo"},
    {"id":139,"singerKor":"Cool(쿨)","singerEng":"Cool","songKor":"Misery","songEng":"Misery"},
    {"id":140,"singerKor":"Cool(쿨)","singerEng":"Cool","songKor":"Today","songEng":"Today"},
    {"id":141,"singerKor":"Cool(쿨)","singerEng":"Cool","songKor":"결혼을 할거라면","songEng":"If You're Going to Get Married"},
    {"id":142,"singerKor":"Cool(쿨)","singerEng":"Cool","songKor":"맥주와 땅콩","songEng":"Beer and Peanuts"},
    {"id":143,"singerKor":"Cool(쿨)","singerEng":"Cool","songKor":"백설공주","songEng":"Snow White."},
    {"id":144,"singerKor":"Cool(쿨)","singerEng":"Cool","songKor":"사랑을 원해","songEng":"Love Wanted."},
    {"id":145,"singerKor":"Cool(쿨)","singerEng":"Cool","songKor":"아가씨와 건달들","songEng":"The song name is Lady and the Gangsters."},
    {"id":146,"singerKor":"Cool(쿨)","singerEng":"Cool","songKor":"아로하","songEng":"Aloha"},
    {"id":147,"singerKor":"Cool(쿨)","singerEng":"Cool","songKor":"애상","songEng":"Aesang"},
    {"id":148,"singerKor":"Cool(쿨)","singerEng":"Cool","songKor":"이 여름 Summer","songEng":"This Summer"},
    {"id":149,"singerKor":"Cool(쿨)","singerEng":"Cool","songKor":"진실","songEng":"Truth"},
    {"id":150,"singerKor":"Cool(쿨)","singerEng":"Cool","songKor":"해석남녀","songEng":"Interpretation Couple (해석남녀)"},
    {"id":151,"singerKor":"Crown J","singerEng":"Crown J","songKor":"Fly Boy","songEng":"Fly Boy"},
    {"id":152,"singerKor":"Crush","singerEng":"Crush","songKor":"Beautiful","songEng":"Beautiful"},
    {"id":153,"singerKor":"Crush","singerEng":"Crush","songKor":"Oasis (Feat. ZICO)","songEng":"Oasis (Feat. ZICO)"},
    {"id":154,"singerKor":"Crush","singerEng":"Crush","songKor":"Rush Hour (Feat. J-hope of BTS)","songEng":"Rush Hour (Feat. J-hope of BTS)"},
    {"id":155,"singerKor":"Crush","singerEng":"Crush","songKor":"둘만의 세상으로 가","songEng":"Go to Our Own World Together"},
    {"id":156,"singerKor":"Crush","singerEng":"Crush","songKor":"어떻게 지내","songEng":"How Have You Been"},
    {"id":157,"singerKor":"Crush","singerEng":"Crush","songKor":"우아해 (woo ah)","songEng":"Woo Ah"},
    {"id":158,"singerKor":"Crush","singerEng":"Crush","songKor":"잊어버리지마 (Feat. 태연)","songEng":"Do not forget (Feat. Taeyeon)"},
    {"id":159,"singerKor":"Crush_ 로꼬","singerEng":"Crush Loco","songKor":"아마도 그건","songEng":"Maybe that's"},
    {"id":160,"singerKor":"D-Hack_ Pateko","singerEng":"Sorry_ as an AI language model_ I cannot determine the name of the Korean artist you mentioned as D-Hack_ Pateko as it does not match any known Korean names. Can you please provide more information or correct the spelling of the artist's name?","songKor":"OHAYO MY NIGHT","songEng":"OHAYO MY NIGHT"},
    {"id":161,"singerKor":"D.O","singerEng":"D.O","songKor":"삐에로 (Feat. JED Of D.Bace)","songEng":"Pierrot"},
    {"id":162,"singerKor":"Davichi","singerEng":"Davichi","songKor":"Fanfare","songEng":"Fanfare"},
    {"id":163,"singerKor":"DEAN","singerEng":"DEAN","songKor":"D (Half Moon) (Feat. 개코)","songEng":"D (Half Moon) (Feat. Gaeko)"},
    {"id":164,"singerKor":"DEAN","singerEng":"DEAN","songKor":"instagram","songEng":"instagram"},
    {"id":165,"singerKor":"DJ DOC","singerEng":"DJ DOC","songKor":"Run To You","songEng":"Run To You"},
    {"id":166,"singerKor":"DJ DOC","singerEng":"DJ DOC","songKor":"Street life","songEng":"Street life"},
    {"id":167,"singerKor":"DJ DOC","singerEng":"DJ DOC","songKor":"나 이런사람이야","songEng":"I'm this kind of person."},
    {"id":168,"singerKor":"DJ DOC","singerEng":"DJ DOC","songKor":"머피의 법칙.MP3","songEng":"Murphy's Law.MP3"},
    {"id":169,"singerKor":"DJ DOC","singerEng":"DJ DOC","songKor":"부치지 못한 편지","songEng":"Unsent Letter"},
    {"id":170,"singerKor":"DK","singerEng":"DK","songKor":"Heart","songEng":"Heart."},
    {"id":171,"singerKor":"Drunken Tiger","singerEng":"The name is Drunken Tiger.","songKor":"Liquor shots (술병에 숟가락)","songEng":"Liquor shots (술병에 숟가락)"},
    {"id":172,"singerKor":"Drunken Tiger","singerEng":"Drunken Tiger","songKor":"위대한 탄생 (Fetticcini)","songEng":"Great Birth (Fetticcini)"},
    {"id":173,"singerKor":"Dynamic Duo","singerEng":"Dynamic Duo","songKor":"거기서거기 (Without You)","songEng":"Without You"},
    {"id":174,"singerKor":"Ed Sheeran","singerEng":"Ed Sheeran","songKor":"Bad Habits","songEng":"Bad Habits"},
    {"id":175,"singerKor":"Epik High","singerEng":"Epik High","songKor":"1분 1초","songEng":"One Minute One Second"},
    {"id":176,"singerKor":"Epik High","singerEng":"Epik High","songKor":"우산 (Feat. 윤하)","songEng":"Umbrella (Feat. Younha)"},
    {"id":177,"singerKor":"Epik High(에픽하이)","singerEng":"Epik High","songKor":"술이 달다 (feat. Crush)","songEng":"Slightly Tipsy (feat. Crush)"},
    {"id":178,"singerKor":"Ex","singerEng":"Ex","songKor":"잘 부탁드립니다","songEng":"I look forward to your kind cooperation."},
    {"id":179,"singerKor":"EXID","singerEng":"EXID","songKor":"아예 (Ah Yeah)","songEng":"Ah Yeah"},
    {"id":180,"singerKor":"EXID","singerEng":"EXID","songKor":"위아래","songEng":"Up and down"},
    {"id":181,"singerKor":"EXO","singerEng":"EXO","songKor":"CALL ME BABY","songEng":"CALL ME BABY"},
    {"id":182,"singerKor":"EXO","singerEng":"EXO","songKor":"Ko Ko Bop","songEng":"Ko Ko Bop"},
    {"id":183,"singerKor":"EXO","singerEng":"EXO","songKor":"LOVE ME RIGHT","songEng":"LOVE ME RIGHT"},
    {"id":184,"singerKor":"EXO","singerEng":"EXO","songKor":"Love Shot","songEng":"Love Shot"},
    {"id":185,"singerKor":"EXO","singerEng":"EXO","songKor":"Monster","songEng":"Monster"},
    {"id":186,"singerKor":"EXO","singerEng":"EXO","songKor":"Tempo","songEng":"Tempo"},
    {"id":187,"singerKor":"EXO","singerEng":"EXO","songKor":"Universe","songEng":"Universe"},
    {"id":188,"singerKor":"EXO","singerEng":"EXO","songKor":"늑대와 미녀 (Wolf)","songEng":"Wolf"},
    {"id":189,"singerKor":"EXO","singerEng":"EXO","songKor":"으르렁 (Growl)","songEng":"Growl"},
    {"id":190,"singerKor":"EXO","singerEng":"EXO","songKor":"첫 눈","songEng":"First Snow"},
    {"id":191,"singerKor":"EXO-CBX (첸백시)","singerEng":"EXO-CBX (첸백시)","songKor":"花요일 (Blooming Day)","songEng":"Blooming Day"},
    {"id":192,"singerKor":"EXO-K","singerEng":"EXO-K","songKor":"중독 (Overdose)","songEng":"Overdose"},
    {"id":193,"singerKor":"f(x)","singerEng":"f(x)","songKor":"4 Walls","songEng":"Four Walls"},
    {"id":194,"singerKor":"f(x)","singerEng":"f(x)","songKor":"Electric Shock","songEng":"Electric Shock"},
    {"id":195,"singerKor":"f(x)","singerEng":"f(x)","songKor":"Hot Summer","songEng":"Hot Summer"},
    {"id":196,"singerKor":"f(x)","singerEng":"f(x)","songKor":"NU 예삐오 (NU ABO)","songEng":"NU ABO"},
    {"id":197,"singerKor":"f(x)","singerEng":"f(x)","songKor":"라차타 (LA chA TA)","songEng":"Lachata"},
    {"id":198,"singerKor":"f(x)","singerEng":"f(x)","songKor":"첫 사랑니 (Rum Pum Pum Pum)","songEng":"First Love Tooth (Rum Pum Pum Pum)"},
    {"id":199,"singerKor":"f(x)","singerEng":"f(x)","songKor":"피노키오 (Danger)","songEng":"Pinocchio (Danger)"},
    {"id":200,"singerKor":"F.T Island","singerEng":"F.T Island","songKor":"나쁜 여자야","songEng":"Bad Girl Ya"},
    {"id":201,"singerKor":"F.T Island","singerEng":"F.T Island","songKor":"너 올 때까지","songEng":"Until You Come by Wheesung."},
    {"id":202,"singerKor":"F.T Island","singerEng":"FT Island","songKor":"바래","songEng":"I Want"},
    {"id":203,"singerKor":"Flower (플라워)","singerEng":"Flower (플라워)","songKor":"여기까진가요","songEng":"That's it"},
    {"id":204,"singerKor":"Fly to the Sky","singerEng":"Fly To the Sky","songKor":"Missing You","songEng":"Missing You"},
    {"id":205,"singerKor":"Fly To The Sky","singerEng":"Fly To The Sky","songKor":"Sea of love","songEng":"Sea of love"},
    {"id":206,"singerKor":"Fly To The Sky","singerEng":"Fly To The Sky","songKor":"구속","songEng":"tie down"},
    {"id":207,"singerKor":"Fly To The Sky","singerEng":"Fly To The Sky","songKor":"약속 (The Promise)","songEng":"The Promise"},
    {"id":208,"singerKor":"Fly To The Sky_ 앤드","singerEng":"Sorry_ as an AI language model_ I do not have personal prefeces or any words of my own. However_ the name of the Korean artist in English is Fly To The Sky_ 앤드.","songKor":"취중진담 (Feat. AND)","songEng":"Drunken Truth (Feat. AND)"},
    {"id":209,"singerKor":"FT Island","singerEng":"FT Island","songKor":"Hello Hello","songEng":"Hello Hello"},
    {"id":210,"singerKor":"FT Island","singerEng":"FT Island","songKor":"사랑 사랑 사랑","songEng":"Love Love Love"},
    {"id":211,"singerKor":"FT Island","singerEng":"FT Island","songKor":"지독하게","songEng":"Fierce."},
    {"id":212,"singerKor":"FT Island","singerEng":"FT Island","songKor":"천둥","songEng":"Thunder"},
    {"id":213,"singerKor":"FT Island","singerEng":"FT Island","songKor":"사랑앓이","songEng":"Love Sick"},
    {"id":214,"singerKor":"G-Dragon","singerEng":"G-Dragon","songKor":"Heartbreaker","songEng":"Heartbreaker"},
    {"id":215,"singerKor":"G-Dragon","singerEng":"G-Dragon","songKor":"무제(無題) (Untitled_ 2014)","songEng":"Untitled 2014"},
    {"id":216,"singerKor":"G-Dragon","singerEng":"G-Dragon","songKor":"소년이여","songEng":"Boy."},
    {"id":217,"singerKor":"G.NA","singerEng":"G.NA","songKor":"Black & White","songEng":"Black & White"},
    {"id":218,"singerKor":"G.NA","singerEng":"GNA","songKor":"Top Girl","songEng":"Top Girl"},
    {"id":219,"singerKor":"Gaeko_ Ourealgoat_ Since_ Ahn byeong woong_ TABBER_ Gwangil Jo","singerEng":"Gaeko_ Ourealgoat_ Since_ Ahn byeong woong_ TABBER_ Gwangil Jo","songKor":"Wake Up (Prod. 코드 쿤스트)","songEng":"Wake Up"},
    {"id":220,"singerKor":"Gary","singerEng":"Gary","songKor":"조금 이따 샤워해 (Feat. Crush)","songEng":"Take a shower later (Feat. Crush)"},
    {"id":221,"singerKor":"Gavy N.J.","singerEng":"Gavy NJ","songKor":"연애소설","songEng":"Love Story"},
    {"id":222,"singerKor":"Gavy NJ","singerEng":"Gavy NJ","songKor":"그녀가 울고있네요","songEng":"She's Crying by Lim Jae Bum"},
    {"id":223,"singerKor":"Gavy NJ","singerEng":"Gavy NJ","songKor":"그래도 살아가겟지","songEng":"I Will Live"},
    {"id":224,"singerKor":"Gavy NJ","singerEng":"Gavy NJ","songKor":"눈사람","songEng":"Snowman"},
    {"id":225,"singerKor":"gavy nj project group (H7)","singerEng":"Gavy NJ Project Group (H7)","songKor":"Love All","songEng":"Love All"},
    {"id":226,"singerKor":"GAYLE","singerEng":"GAYLE","songKor":"abcdefu","songEng":"abcdefu"},
    {"id":227,"singerKor":"GD X TAEYANG","singerEng":"GD X TAEYANG","songKor":"GOOD BOY","songEng":"Good Boy"},
    {"id":228,"singerKor":"GD_T.O.P","singerEng":"GD_T.O.P","songKor":"HIGH HIGH","songEng":"High High"},
    {"id":229,"singerKor":"GD_T.O.P","singerEng":"GD_T.O.P","songKor":"OH YEAH (Feat. 박봄)","songEng":"OH YEAH"},
    {"id":230,"singerKor":"GG","singerEng":"Girls Generation","songKor":"바람났어 (Feat. 박봄)","songEng":"Windblew (Feat. Park Bom)"},
    {"id":231,"singerKor":"Girls' Generation","singerEng":"Girls' Generation","songKor":"FOREVER 1","songEng":"FOREVER 1"},
    {"id":232,"singerKor":"gitae","singerEng":"Gitae","songKor":"Stuck in me","songEng":"Stuck in Me"},
    {"id":233,"singerKor":"GOD","singerEng":"GOD","songKor":"2♡","songEng":"2♡"},
    {"id":234,"singerKor":"GOD","singerEng":"GOD","songKor":"Friday Night","songEng":"Friday Night"},
    {"id":235,"singerKor":"GOD","singerEng":"G.O.D","songKor":"거짓말","songEng":"Lies"},
    {"id":236,"singerKor":"GOD","singerEng":"GOD","songKor":"길","songEng":"Road"},
    {"id":237,"singerKor":"GOD","singerEng":"Kim Tae-woo","songKor":"니가 있어야 할 곳","songEng":"You have to be there."},
    {"id":238,"singerKor":"GOD","singerEng":"GOD","songKor":"니가 필요해","songEng":"I Need You"},
    {"id":239,"singerKor":"GOD","singerEng":"GOD","songKor":"미운오리새끼","songEng":"Ugly Duckling."},
    {"id":240,"singerKor":"GOD","singerEng":"G-Dragon","songKor":"보통날","songEng":"Ordinary Day"},
    {"id":241,"singerKor":"GOD","singerEng":"G-Dragon","songKor":"약속 (Narr. 강경헌)","songEng":"Promise (Narr. Kang Kyeongheon)"},
    {"id":242,"singerKor":"GOD","singerEng":"G-Dragon","songKor":"편지","songEng":"Letter."},
    {"id":243,"singerKor":"GOD","singerEng":"GOD","songKor":"하늘색 약속","songEng":"Sky Blue Promise"},
    {"id":244,"singerKor":"GOT the beat","singerEng":"GOT the beat","songKor":"Step Back","songEng":"Step Back"},
    {"id":245,"singerKor":"Groovyroom_ lIlBOI_ Blas?_ Roh Yun Ha_ Polodared_ Chillin Homie_ Fleeky Bang","singerEng":"Groovyroom_ lIlBOI_ Blas?_ Roh Yun Ha_ Polodared_ Chillin Homie_ Fleeky Bang","songKor":"We Higher (Prod. GroovyRoom)","songEng":"We Higher (Prod GroovyRoom)"},
    {"id":246,"singerKor":"GUMMY","singerEng":"GUMMY","songKor":"그대 돌아오면","songEng":"When You Come Back To Me Again"},
    {"id":247,"singerKor":"GyeongseoYeji_ Jeon Gunho","singerEng":"GyeongseoYeji_ Jeon Gunho","songKor":"If you lovingly call my name","songEng":"If you lovingly call my name"},
    {"id":248,"singerKor":"GyeongseoYeji_ Jeon Gunho","singerEng":"GyeongseoYeji_ Jeon Gunho","songKor":"The street where our winter is","songEng":"The street where our winter is"},
    {"id":249,"singerKor":"H","singerEng":"BTS","songKor":"너를 원해","songEng":"I Want You"},
    {"id":250,"singerKor":"H.O.T.","singerEng":"Tony An","songKor":"Outside Castle (The Castle Outsiders)","songEng":"Outside Castle (The Castle Outsiders)"},
    {"id":251,"singerKor":"H1-KEY","singerEng":"H1-KEY","songKor":"Rose Blossom","songEng":"Rose Blossom"},
    {"id":252,"singerKor":"Ha Yea Song","singerEng":"Ha Yea Song","songKor":"Since I knew you","songEng":"Since I knew you"},
    {"id":253,"singerKor":"Han Dong Geun","singerEng":"Han Dong Geun","songKor":"dawn call from you","songEng":"dawn call from you"},
    {"id":254,"singerKor":"Han Dong Geun","singerEng":"Han Dong Geun","songKor":"It's love","songEng":"It's love"},
    {"id":255,"singerKor":"Heize","singerEng":"Heize","songKor":"HAPPEN","songEng":"HAPPEN"},
    {"id":256,"singerKor":"HI SUHYUN","singerEng":"HISUHYUN","songKor":"나는 달라 (Feat. BOBBY)","songEng":"I Am Diffet (Feat. BOBBY)"},
    {"id":257,"singerKor":"HIGH4 & 아이유","singerEng":"HIGH4 & IU","songKor":"봄 사랑 벚꽃 말고","songEng":"Spring Love_ Cherry Blossoms_ Not War."},
    {"id":258,"singerKor":"HIGH4_ IU","singerEng":"HIGH4_ IU","songKor":"Not Spring_ Love_ or Cherry Blossoms","songEng":"Love Cherry Motion"},
    {"id":259,"singerKor":"HIGH4_ 아이유","singerEng":"HIGH4 IU","songKor":"봄 사랑 벚꽃 말고","songEng":"Spring Love_ Cherry Blossoms_ Not to Say"},
    {"id":260,"singerKor":"Homies_ Uneducated Kid_ Paul Blanco","singerEng":"Homies_ Uneducated Kid_ Paul Blanco","songKor":"Si Remix (Feat. UNEDUCATED KID_ Paul Blanco)","songEng":"Si Remix (Feat UNEDUCATED KID Paul Blanco)"},
    {"id":261,"singerKor":"Hue","singerEng":"Hue","songKor":"기억이 눈물이","songEng":"Memory Sorrow"},
    {"id":262,"singerKor":"Huh Gak","singerEng":"Huh Gak","songKor":"Endless","songEng":"Sorry_ I cannot proceed with this task as there are multiple Korean songs with the title Endless and I am unable to determine which one you are referring to. Please provide more context or information about the song so I can assist you better."},
    {"id":263,"singerKor":"HYNN (박혜원)","singerEng":"HYNN","songKor":"시든 꽃에 물을 주듯","songEng":"Like a Flower_ Thirsty."},
    {"id":264,"singerKor":"HYNN(박혜원)","singerEng":"HYNN","songKor":"시든 꽃에 물을 주듯","songEng":"Watering a Wilted Flower"},
    {"id":265,"singerKor":"iKON","singerEng":"Bobby","songKor":"리듬 타 (RHYTHM TA)","songEng":"RHYTHM TA"},
    {"id":266,"singerKor":"iKON","singerEng":"Bobby","songKor":"사랑을 했다 (LOVE SCENARIO)","songEng":"LOVE SCENARIO"},
    {"id":267,"singerKor":"iKON","singerEng":"iKON","songKor":"취향저격","songEng":"Love Scenario"},
    {"id":268,"singerKor":"Im Chang-jung","singerEng":"Im Chang-jung","songKor":"I hate trot","songEng":"I hate trot"},
    {"id":269,"singerKor":"ITZY (있지)","singerEng":"ITZY","songKor":"ICY","songEng":"ICY."},
    {"id":270,"singerKor":"ITZY (있지)","singerEng":"ITZY (있지)","songKor":"SNEAKERS","songEng":"SNEAKERS"},
    {"id":271,"singerKor":"ITZY (있지)","singerEng":"ITZY (있지)","songKor":"WANNABE","songEng":"WANNABE"},
    {"id":272,"singerKor":"ITZY (있지)","singerEng":"ITZY (있지)","songKor":"달라달라 (DALLA DALLA)","songEng":"DALLA DALLA"},
    {"id":273,"singerKor":"ITZY (있지)","singerEng":"ITZY (있지)","songKor":"마.피.아. In the morning","songEng":"Mafia In the Morning."},
    {"id":274,"singerKor":"IU","singerEng":"IU","songKor":"Celebrity","songEng":"Celebrity"},
    {"id":275,"singerKor":"IU","singerEng":"IU","songKor":"Drama","songEng":"Drama"},
    {"id":276,"singerKor":"IU","singerEng":"IU","songKor":"Hold my hand","songEng":"Hold my hand"},
    {"id":277,"singerKor":"IU","singerEng":"IU","songKor":"LILAC","songEng":"LILAC"},
    {"id":278,"singerKor":"IU","singerEng":"IU","songKor":"strawberry moon","songEng":"Strawberry Moon"},
    {"id":279,"singerKor":"IVE (아이브)","singerEng":"IVE","songKor":"ELEVEN","songEng":"ELEVEN"},
    {"id":280,"singerKor":"IVE (아이브)","singerEng":"IVE","songKor":"After LIKE","songEng":"After LIKE"},
    {"id":281,"singerKor":"IVE (아이브)","singerEng":"IVE","songKor":"ELEVEN","songEng":"Eleven."},
    {"id":282,"singerKor":"IVE (아이브)","singerEng":"IVE","songKor":"LOVE DIVE","songEng":"LOVE DIVE"},
    {"id":283,"singerKor":"IVE (아이브)","singerEng":"IVE","songKor":"Kitsch","songEng":"Kitsch"},
    {"id":284,"singerKor":"IZONE (아이즈원)","singerEng":"IZONE (아이즈원)","songKor":"FIESTA","songEng":"FIESTA"},
    {"id":285,"singerKor":"J","singerEng":"BTS","songKor":"Time Out","songEng":"Time Out"},
    {"id":286,"singerKor":"J","singerEng":"Jungkook","songKor":"가장 슬픈 말","songEng":"Most Sad Words"},
    {"id":287,"singerKor":"J","singerEng":"Jennie","songKor":"빛","songEng":"Light"},
    {"id":288,"singerKor":"J","singerEng":"BTS","songKor":"어제처럼","songEng":"Yesterday Like."},
    {"id":289,"singerKor":"j-hope_ J. Cole","singerEng":"J-hope_ J Cole","songKor":"on the street (with J. Cole)","songEng":"On the street (with J Cole)"},
    {"id":290,"singerKor":"Jannabi","singerEng":"Jannabi","songKor":"A thought on an autumn night","songEng":"A thought on an autumn night"},
    {"id":291,"singerKor":"Jaurim","singerEng":"Jaurim","songKor":"스물다섯_ 스물하나","songEng":"Twenty Five_ Twenty One"},
    {"id":292,"singerKor":"Jawsh 685_ Jason Derulo_ 방탄소년단","singerEng":"Jawsh 685_ Jason Derulo_ BTS","songKor":"Savage Love (Laxed - Si Beat) (BTS Remix)","songEng":"Savage Love (Laxed - Si Beat) (BTS Remix)"},
    {"id":293,"singerKor":"Jay Park_ IU","singerEng":"Jay Park_ IU","songKor":"GANADARA (Feat. IU)","songEng":"GANADARA (Feat. IU)"},
    {"id":294,"singerKor":"JEONG HYO BEAN","singerEng":"JEONG HYO BEAN","songKor":"I`m not alone","songEng":"I`m not alone"},
    {"id":295,"singerKor":"Jessi","singerEng":"Jessi","songKor":"ZOOM","songEng":"Zoom"},
    {"id":296,"singerKor":"JI JIN SEOK","singerEng":"JI JIN SEOK","songKor":"BYE_ MY LOVE","songEng":"BYE_ MY LOVE"},
    {"id":297,"singerKor":"Jimin","singerEng":"Jimin","songKor":"Face-off","songEng":"Face-off"},
    {"id":298,"singerKor":"Jimin","singerEng":"Jimin","songKor":"Like Crazy","songEng":"Like Crazy"},
    {"id":299,"singerKor":"Jimin","singerEng":"Jimin","songKor":"Set Me Free Pt.2","songEng":"Set Me Free Pt.2"},
    {"id":300,"singerKor":"Jimin_ HA SUNG WOON","singerEng":"Jimin_ HA SUNG WOON","songKor":"With you","songEng":"With you"},
    {"id":301,"singerKor":"JIN","singerEng":"Jin","songKor":"The Astronaut","songEng":"The Astronaut"},
    {"id":302,"singerKor":"Jinusean","singerEng":"Jinusean","songKor":"A-Yo!","songEng":"A-Yo!"},
    {"id":303,"singerKor":"JISOO","singerEng":"JISOO","songKor":"All Eyes On Me","songEng":"All Eyes On Me"},
    {"id":304,"singerKor":"JISOO","singerEng":"JISOO","songKor":"FLOWER","songEng":"FLOWER"},
    {"id":305,"singerKor":"JK 김동욱","singerEng":"JK Kim Dong Wook","songKor":"그녈위해","songEng":"For You"},
    {"id":306,"singerKor":"JK 김동욱","singerEng":"JK Kim Dong Wook","songKor":"돌아와 제발","songEng":"Come Back Please"},
    {"id":307,"singerKor":"JK 김동욱","singerEng":"JK Kim Dong-wook","songKor":"미련한 사랑","songEng":"Obsessive Love"},
    {"id":308,"singerKor":"Joosiq","singerEng":"Joosiq","songKor":"Baby I Need You","songEng":"Baby I Need You"},
    {"id":309,"singerKor":"Joosiq","singerEng":"Joosiq","songKor":"Think About You","songEng":"Think About You"},
    {"id":310,"singerKor":"JTL","singerEng":"JTL","songKor":"Without Your Love","songEng":"Without Your Love"},
    {"id":311,"singerKor":"Juho","singerEng":"Juho","songKor":"Goodbye","songEng":"Goodbye"},
    {"id":312,"singerKor":"Juho","singerEng":"Juho","songKor":"without me","songEng":"without me"},
    {"id":313,"singerKor":"Juho_ Banhana","singerEng":"I'm sorry_ but Juho_ Banhana does not seem to be the complete name of any known Korean artist. Can you please provide more information or clarify the name?","songKor":"the day (feat. Banhana)","songEng":"the day (feat. Banhana)"},
    {"id":314,"singerKor":"JuJu Secret","singerEng":"JuJu Secret","songKor":"Lonely Night","songEng":"Lonely Night"},
    {"id":315,"singerKor":"Justhis_ DON MALIK_ HUH_ Khan_ Mckdaddy_ Los","singerEng":"Justhis_ DON MALIK_ HUH_ Khan_ Mckdaddy_ Los","songKor":"MY WAY (Prod. R.Tee)","songEng":"MY WAY (Prod. R.Tee)"},
    {"id":316,"singerKor":"Jvcki Wai_영비_Osshun Gum_한요한","singerEng":"Jvcki Wai_ Yeongbi_ Osshun Gum_ Hanyohan.","songKor":"띵","songEng":"Tthing"},
    {"id":317,"singerKor":"K.will","singerEng":"K.will","songKor":"03 왼쪽가슴","songEng":"Left Chest (or Left Heart)"},
    {"id":318,"singerKor":"K.Will","singerEng":"K.will","songKor":"눈물이 뚝뚝","songEng":"Tears Runny."},
    {"id":319,"singerKor":"K.will","singerEng":"K.will","songKor":"니가 필요해 (I Need You)","songEng":"I Need You"},
    {"id":320,"singerKor":"K2","singerEng":"K2","songKor":"슬프도록 아름다운","songEng":"Sad Beautiful."},
    {"id":321,"singerKor":"K2(김성면)","singerEng":"K2(Kim Sung Myung)","songKor":"그녀의 연인에게 #Story 1","songEng":"To My Lover by Itzy"},
    {"id":322,"singerKor":"KANG MIN KYUNG_ CHOI JUNG HOON of JANNABI","singerEng":"Kang Min Kyung_ Choi Jung Hoon","songKor":"Because we loved","songEng":"Because we loved"},
    {"id":323,"singerKor":"Kara","singerEng":"Kara","songKor":"Wanna","songEng":"Wanna"},
    {"id":324,"singerKor":"KARA","singerEng":"KARA","songKor":"WHEN I MOVE","songEng":"When I Move"},
    {"id":325,"singerKor":"Kassy","singerEng":"Kassy","songKor":"Always love you","songEng":"Always You"},
    {"id":326,"singerKor":"Kassy","singerEng":"Kassy","songKor":"Don't wanna leave tonight","songEng":"Don't wanna leave tonight"},
    {"id":327,"singerKor":"KCM","singerEng":"KCM","songKor":"Smile Again","songEng":"Smile Again"},
    {"id":328,"singerKor":"KCM","singerEng":"KCM","songKor":"너에게 전하는 아홉 가지 바램","songEng":"Nine Wishes (or Nine Things I Wish for You)"},
    {"id":329,"singerKor":"KCM","singerEng":"KCM","songKor":"사랑이 올까봐(Feat.화요비)","songEng":"I'm afraid love will come"},
    {"id":330,"singerKor":"KCM","singerEng":"KCM","songKor":"안녕","songEng":"Goodbye"},
    {"id":331,"singerKor":"KCM","singerEng":"KCM","songKor":"은영이에게 Part.2","songEng":"Eunha's Song Part.2"},
    {"id":332,"singerKor":"KCM","singerEng":"KCM","songKor":"은영이에게","songEng":"Eunhaengi-ege"},
    {"id":333,"singerKor":"KCM","singerEng":"KCM","songKor":"클래식","songEng":"Classic"},
    {"id":334,"singerKor":"KCM","singerEng":"KCM","songKor":"태양의 눈물","songEng":"Tears of the Sun"},
    {"id":335,"singerKor":"KCM","singerEng":"KCM","songKor":"흑백사진","songEng":"Black and White Photo"},
    {"id":336,"singerKor":"Kelly Clarkson","singerEng":"Kelly Clarkson","songKor":"Stronger (What Doesn't Kill You)","songEng":"Stronger (What Doesn't Kill You)"},
    {"id":337,"singerKor":"Kep1er (케플러)","singerEng":"Kep1er (케플러)","songKor":"Up!","songEng":"Up"},
    {"id":338,"singerKor":"KHAN","singerEng":"KHAN","songKor":"나침반 (Feat. UNEDUCATED KID_ 수퍼비 (SUPERBEE)) (Prod. R.Tee)","songEng":"Compass (Feat. UNEDUCATED KID_ SUPERBEE) (Prod. R.Tee)"},
    {"id":339,"singerKor":"Kim Min Seok","singerEng":"Kim Min Seok","songKor":"Always with you","songEng":"Always with you"},
    {"id":340,"singerKor":"Kim Min Seok","singerEng":"Kim Min Seok","songKor":"DrunKen Confession","songEng":"DrunKen Confession"},
    {"id":341,"singerKor":"Kim Na Young","singerEng":"Kim Na Young","songKor":"I Can’t","songEng":"I Can't"},
    {"id":342,"singerKor":"Kim Seungmin","singerEng":"Kim Seungmin","songKor":"My pleasure Is That You Ride The Bentley","songEng":"No song exists with that specific title."},
    {"id":343,"singerKor":"Kim Yeon Ji","singerEng":"Kim Yeon Ji","songKor":"Stay there","songEng":"Stay there"},
    {"id":344,"singerKor":"Kwon Jin Ah","singerEng":"Kwon Jin Ah","songKor":"Stupid Love","songEng":"Stupid Love"},
    {"id":345,"singerKor":"KyoungSeo","singerEng":"KyoungSeo","songKor":"CHECKLIST","songEng":"Checklist."},
    {"id":346,"singerKor":"KyoungSeo","singerEng":"KyoungSeo","songKor":"Dear my X","songEng":"Dear my Ex"},
    {"id":347,"singerKor":"KyoungSeo","singerEng":"KyoungSeo","songKor":"Shiny Star(2020)","songEng":"Shiny Star"},
    {"id":348,"singerKor":"Lee Hong Ki (FTISLAND)","singerEng":"Lee Hong Ki (FTISLAND)","songKor":"Amor Fati","songEng":"Amor Fati"},
    {"id":349,"singerKor":"Lee Mujin","singerEng":"Lee Mujin","songKor":"Traffic light","songEng":"Traffic light"},
    {"id":350,"singerKor":"Lee Mujin_ Heize","singerEng":"Lee Mujin_ Heize","songKor":"When it snows(Feat.Heize)","songEng":"When It Snows (Feat. Heize)"},
    {"id":351,"singerKor":"Lee Seok Hoon","singerEng":"Lee Seok Hoon","songKor":"Dear. My Fool (WEBTOON 'Discovery of Love' X Lee Seok Hoon)","songEng":"Dear My Fool"},
    {"id":352,"singerKor":"LEE YEJOON","singerEng":"LEE YEJOON","songKor":"Everyday waiting you","songEng":"Everyday Waiting"},
    {"id":353,"singerKor":"Lee Young Hyun","singerEng":"Lee Young Hyun","songKor":"So you","songEng":"So you"},
    {"id":354,"singerKor":"LESSERAFIM (르세라핌)","singerEng":"Lesserafim","songKor":"ANTIFRAGILE","songEng":"ANTIFRAGILE"},
    {"id":355,"singerKor":"LESSERAFIM (르세라핌)","singerEng":"Lesserafim","songKor":"FEARLESS","songEng":"FEARLESS"},
    {"id":356,"singerKor":"Lim Changjung","singerEng":"Lim Changjung","songKor":"Only You","songEng":"Only You"},
    {"id":357,"singerKor":"Lim Jae Hyun","singerEng":"Lim Jae Hyun","songKor":"Drunken confession at night (Prod. 2soo)","songEng":"Drunken confession at night (Prod. 2soo)"},
    {"id":358,"singerKor":"Lim Young Woong","singerEng":"Lim Young Woong","songKor":"A bientot","songEng":"A bientot"},
    {"id":359,"singerKor":"Lim Young Woong","singerEng":"Lim Young Woong","songKor":"A Psalm of Life","songEng":"A Psalm of Life"},
    {"id":360,"singerKor":"Lim Young Woong","singerEng":"Lim Young Woong","songKor":"Father","songEng":"Father"},
    {"id":361,"singerKor":"Lim Young Woong","singerEng":"Lim Young Woong","songKor":"I Love You","songEng":"I Love You"},
    {"id":362,"singerKor":"Lim Young Woong","singerEng":"Lim Young Woong","songKor":"If We Ever Meet Again","songEng":"If We Ever Meet Again"},
    {"id":363,"singerKor":"Lim Young Woong","singerEng":"Lim Young Woong","songKor":"London Boy","songEng":"London Boy"},
    {"id":364,"singerKor":"Lim Young Woong","singerEng":"Lim Young Woong","songKor":"Love Always Run Away","songEng":"Love Always Run Away"},
    {"id":365,"singerKor":"Lim Young Woong","singerEng":"Lim Young Woong","songKor":"Love Letter","songEng":"Love Letter"},
    {"id":366,"singerKor":"Lim Young Woong","singerEng":"Lim Young Woong","songKor":"Love Station","songEng":"Love Station"},
    {"id":367,"singerKor":"Lim Young Woong","singerEng":"Lim Young Woong","songKor":"Lovely Touch","songEng":"Lovely Touch"},
    {"id":368,"singerKor":"Lim Young Woong","singerEng":"Lim Young Woong","songKor":"Our Blues_ Our Life","songEng":"Our Blues_ Our Life"},
    {"id":369,"singerKor":"Lim Young Woong","singerEng":"Lim Young Woong","songKor":"Polaroid","songEng":"Polaroid"},
    {"id":370,"singerKor":"Lim Young Woong","singerEng":"Lim Young Woong","songKor":"Rainbow","songEng":"Rainbow"},
    {"id":371,"singerKor":"Lim Young Woong","singerEng":"Lim Young Woong","songKor":"Trust in me","songEng":"Trust in me"},
    {"id":372,"singerKor":"Loco_ Yuju","singerEng":"Loco Yuju","songKor":"Spring Is Gone by chance","songEng":"Spring Is Gone by chance"},
    {"id":373,"singerKor":"Lyn","singerEng":"Lyn","songKor":"곰인형","songEng":"Teddy Bear."},
    {"id":374,"singerKor":"Lyn","singerEng":"Lyn","songKor":"보통여자","songEng":"Ordinary Woman"},
    {"id":375,"singerKor":"M TO M","singerEng":"MTOM","songKor":"세글자","songEng":"Three Words"},
    {"id":376,"singerKor":"M.C the MAX","singerEng":"MC the MAX","songKor":"눈물","songEng":"Tears."},
    {"id":377,"singerKor":"M.C the MAX","singerEng":"MC the MAX","songKor":"눈물은 모르게","songEng":"I don't know how to cry"},
    {"id":378,"singerKor":"M.C THE MAX","singerEng":"M C THE MAX","songKor":"볼때기 사랑","songEng":"When I Look at You Love"},
    {"id":379,"singerKor":"M.C the MAX","singerEng":"MC the MAX","songKor":"사랑을 찾아서","songEng":"Finding Love"},
    {"id":380,"singerKor":"M.O.M","singerEng":"MOM","songKor":"Do you want to hear","songEng":"Do you want to hear"},
    {"id":381,"singerKor":"Maktub","singerEng":"Maktub","songKor":"The Eternal Moment","songEng":"The Eternal Moment"},
    {"id":382,"singerKor":"Mc Sniper","singerEng":"Mc Sniper","songKor":"봄이여 오라 (Feat. 유리)","songEng":"Spring is Coming (Feat. Yuri)"},
    {"id":383,"singerKor":"Mckdaddy_ DON MALIK_ Justhis_ Los_ HUH_ Khan","singerEng":"Mckdaddy_ DON MALIK_ Justhis_ Los_ HUH_ Khan","songKor":"MY WAY","songEng":"My Way"},
    {"id":384,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"180도 (Hip Turn Club Mix)","songEng":"180 Degree."},
    {"id":385,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"Bubble Love","songEng":"Bubble Love"},
    {"id":386,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"I Love U Oh Thank U (Feat. 김태우 Of god)","songEng":"I Love U Oh Thank U (Feat. Kim Tae Woo of god)"},
    {"id":387,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"I Love U Oh Thank U","songEng":"I Love U Oh Thank U"},
    {"id":388,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"Indian Boy (Feat. 장근이_ B.I)","songEng":"Indian Boy (Feat. 장근이_ B.I)"},
    {"id":389,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"Simple Love (Feat. 나비)","songEng":"Simple Love (Feat. 나비)"},
    {"id":390,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"So Fresh (Feat. 김태우)","songEng":"So Fresh (Feat. Kim Tae Woo)"},
    {"id":391,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"그래도 남자니까 (Acoustic Ver.)","songEng":"Because I'm a Guy (Acoustic Ver)"},
    {"id":392,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"너에게 쓰는 편지 part 2(Feat. LISA)","songEng":"Letter To You Part 2 (Feat. LISA)"},
    {"id":393,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"미치겠어 (Feat. M.A.C)","songEng":"I'm gonna crazy"},
    {"id":394,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"사랑 범벅 (Feat. 챈슬러 Of The Channels)","songEng":"Love Drunk (Feat. Chancellor Of The Channels)"},
    {"id":395,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"서커스 (Feat. 임유경 - 달래 음악단 _ $howgun)","songEng":"Circus (Feat. Lim Yoo Kyung - Dallae Music Band_ $howgun)"},
    {"id":396,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"서커스 (Feat. 임유경(달래음악단)_  $howgun)","songEng":"Circus (Feat. Im Yoo Kyung (Dalrae Music Band)_ $howgun)"},
    {"id":397,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"아이스크림","songEng":"Ice Cream"},
    {"id":398,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"인기 (Feat. 송가인_ 챈슬러)","songEng":"Popularity (Feat. Song Ga In_ Chancellor)"},
    {"id":399,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"죽도록 사랑해 (Feat. 박정현)","songEng":"I love you to death"},
    {"id":400,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"죽을 만큼 아파서 (Feat. JAMIE (제이미))","songEng":"Die from heart break (Feat. JAMIE)"},
    {"id":401,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"죽을 만큼 아파서 (Feat. 멜로우)","songEng":"Die for you (Feat. Mellow)"},
    {"id":402,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"천하무적","songEng":"invincible"},
    {"id":403,"singerKor":"MC몽","singerEng":"MC Mong","songKor":"홈런","songEng":"Home Run."},
    {"id":404,"singerKor":"Medd the Student","singerEng":"Medd the Student","songKor":"불협화음 (Feat. AKMU) (Prod. GRAY)","songEng":"Bulhyeonghwaeum (Feat. AKMU) (Prod. GRAY)"},
    {"id":405,"singerKor":"MeloMance","singerEng":"MeloMance","songKor":"Go Back","songEng":"Go Back"},
    {"id":406,"singerKor":"MeloMance","singerEng":"MeloMance","songKor":"Invitation","songEng":"Invitation"},
    {"id":407,"singerKor":"MeloMance","singerEng":"Melomance","songKor":"Love_ Maybe","songEng":"Love_ Maybe"},
    {"id":408,"singerKor":"MINO(송민호)","singerEng":"MINO (송민호)","songKor":"탕!♡","songEng":"Tang Heart♡"},
    {"id":409,"singerKor":"miss A","singerEng":"miss A","songKor":"Bad Girl Good Girl","songEng":"Bad Girl Good Girl"},
    {"id":410,"singerKor":"miss A","singerEng":"Miss A","songKor":"Breathe","songEng":"Breathe"},
    {"id":411,"singerKor":"miss A","singerEng":"Miss A","songKor":"Good-bye Baby","songEng":"Good-bye Baby"},
    {"id":412,"singerKor":"miss A","singerEng":"miss A","songKor":"Touch","songEng":"Touch"},
    {"id":413,"singerKor":"miss A","singerEng":"miss A","songKor":"다른 남자 말고 너 (Only You)","songEng":"Only You"},
    {"id":414,"singerKor":"Monday Kiz (먼데이 키즈)","singerEng":"Monday Kiz","songKor":"투명한 눈물","songEng":"Transpat Tears."},
    {"id":415,"singerKor":"Monday Kiz","singerEng":"Monday Kiz","songKor":"Lonely way back home","songEng":"Lonely way back home"},
    {"id":416,"singerKor":"Monday Kiz","singerEng":"Monday Kiz","songKor":"Turn back one's love","songEng":"Turn back one's love"},
    {"id":417,"singerKor":"MSG WANNABE (M.O.M)","singerEng":"MSG WANNABE (M.O.M)","songKor":"Confessing my Love","songEng":"Confessing my Love"},
    {"id":418,"singerKor":"MSG WANNABE","singerEng":"MSG WANNABE","songKor":"Foolish Love","songEng":"Foolish Love"},
    {"id":419,"singerKor":"MSG워너비 (M.O.M)","singerEng":"MSG워너비 (M.O.M)","songKor":"듣고 싶을까","songEng":"Do You Want to Listen?"},
    {"id":420,"singerKor":"MSG워너비 (M.O.M)","singerEng":"MSG워너비 (M.O.M)","songKor":"바라만 본다","songEng":"I Only See You"},
    {"id":421,"singerKor":"MSG워너비 (정상동기)","singerEng":"MSG Wannabe (Jeong Sang-dong-gi)","songKor":"나를 아는 사람","songEng":"Someone who knows me"},
    {"id":422,"singerKor":"MSG워너비 TOP 8 (별루지 & 김정수 & 강창모 & 정기석 & 이동휘 & 이상이 & 박재정 & 원슈타인)","singerEng":"MSG Wannabe (별루지 & 김정수 & 강창모 & 정기석 & 이동휘 & 이상이 & 박재정 & 원슈타인)","songKor":"상상더하기","songEng":"Imagining"},
    {"id":423,"singerKor":"MSG워너비(M.O.M)","singerEng":"MSG워너비(M.O.M)","songKor":"듣고 싶을까","songEng":"Would You Like to Listen"},
    {"id":424,"singerKor":"Mudd the student_ AKMU","singerEng":"Mudd the student_ AKMU","songKor":"불협화음 (Feat. AKMU) (Prod. GRAY)","songEng":"BulhyeopHwaEum (Feat. AKMU) (Prod. GRAY)"},
    {"id":425,"singerKor":"Namolla Family Feat. 태인","singerEng":"Namolla Family Feat 태인","songKor":"너만 볼래","songEng":"I Only Want to See You"},
    {"id":426,"singerKor":"NAYEON","singerEng":"NAYEON","songKor":"POP!","songEng":"POP!"},
    {"id":427,"singerKor":"NCT 127","singerEng":"NCT 127","songKor":"Ay-Yo","songEng":"AYYO"},
    {"id":428,"singerKor":"NCT 127","singerEng":"NCT 127","songKor":"DJ","songEng":"DJ"},
    {"id":429,"singerKor":"NCT 127","singerEng":"NCT 127","songKor":"질주 (2 Baddies)","songEng":"Speeding (2 Baddies)"},
    {"id":430,"singerKor":"NCT DREAM","singerEng":"NCT DREAM","songKor":"Beatbox","songEng":"Beatbox."},
    {"id":431,"singerKor":"NCT DREAM","singerEng":"NCT DREAM","songKor":"Candy","songEng":"Candy"},
    {"id":432,"singerKor":"NCT DREAM","singerEng":"NCT DREAM","songKor":"Graduation","songEng":"Graduation"},
    {"id":433,"singerKor":"NCT DREAM","singerEng":"NCT DREAM","songKor":"버퍼링 (Glitch Mode)","songEng":"Buffering Glitch Mode"},
    {"id":434,"singerKor":"NCT DREAM","singerEng":"NCT DREAM","songKor":"버퍼링 Glitch Mode","songEng":"Buffering Glitch Mode"},
    {"id":435,"singerKor":"NCT DREAM","singerEng":"NCT DREAM","songKor":"북극성 (Never Goodbye)","songEng":"Never Goodbye"},
    {"id":436,"singerKor":"NewJeans","singerEng":"NewJeans","songKor":"Attention","songEng":"Attention"},
    {"id":437,"singerKor":"NewJeans","singerEng":"NewJeans","songKor":"Cookie","songEng":"Cookie"},
    {"id":438,"singerKor":"NewJeans","singerEng":"NewJeans","songKor":"Ditto","songEng":"Ditto"},
    {"id":439,"singerKor":"NewJeans","singerEng":"NewJeans","songKor":"Hype Boy","songEng":"Hype Boy"},
    {"id":440,"singerKor":"NewJeans","singerEng":"NewJeans","songKor":"OMG","songEng":"OMG"},
    {"id":441,"singerKor":"NMIXX","singerEng":"NMIXX","songKor":"DICE","songEng":"DICE"},
    {"id":442,"singerKor":"NMIXX","singerEng":"NMIXX","songKor":"Love Me Like This","songEng":"Love Me Like This"},
    {"id":443,"singerKor":"NMIXX","singerEng":"NMIXX","songKor":"O.O","songEng":"O.O"},
    {"id":444,"singerKor":"NMIXX","singerEng":"NMIXX","songKor":"Young_ Dumb_ Stupid","songEng":"Young_ Dumb_ Stupid"},
    {"id":445,"singerKor":"NRG","singerEng":"NRG","songKor":"Hit Song","songEng":"Hit Song"},
    {"id":446,"singerKor":"NRG","singerEng":"NRG","songKor":"대한건아 만세","songEng":"Long live the Republic of Korea."},
    {"id":447,"singerKor":"NSW yoon","singerEng":"NSW Yoon","songKor":"으리으리 (Feat. 호미들)","songEng":"Eurri Eurri (Feat. HOMEDAL)"},
    {"id":448,"singerKor":"OH MY GIRL","singerEng":"OH MY GIRL","songKor":"Dun Dun Dance","songEng":"Dun Dun Dance"},
    {"id":449,"singerKor":"OneRepublic","singerEng":"OneRepublic","songKor":"I Ain't Worried","songEng":"I Ain't Worried"},
    {"id":450,"singerKor":"OneStar","singerEng":"OneStar","songKor":"Get Ready To Leave","songEng":"Get Ready To Leave"},
    {"id":451,"singerKor":"Paul Kim","singerEng":"Paul Kim","songKor":"Like Yesterday","songEng":"Like Yesterday."},
    {"id":452,"singerKor":"Paul Kim","singerEng":"Paul Kim","songKor":"모든 날_ 모든 순간 Every day_ Every Moment","songEng":"Everyday Every Moment"},
    {"id":453,"singerKor":"pH-1_ Kid Milli_ 루피","singerEng":"pH-1_ Kid Milli_ Rooftop","songKor":"Good Day (Feat. 팔로알토) (Prod. 코드 쿤스트)","songEng":"Good Day (Feat. Paloalto) (Prod. Code Kunst)"},
    {"id":454,"singerKor":"PK 헤만","singerEng":"PK Heman","songKor":"Evergreen (feat. 이지혜)","songEng":"Evergreen (feat. Lee Ji-hye)"},
    {"id":455,"singerKor":"PRODUCE 101","singerEng":"I.O.I","songKor":"PICK ME","songEng":"Pick Me"},
    {"id":456,"singerKor":"Psy","singerEng":"Psy","songKor":"Celeb","songEng":"Celeb"},
    {"id":457,"singerKor":"Psy","singerEng":"Psy","songKor":"That That (prod. & feat. SUGA of BTS)","songEng":"That That (prod. & feat. SUGA of BTS)"},
    {"id":458,"singerKor":"psy","singerEng":"Psy","songKor":"환희","songEng":"Joyful Happiness"},
    {"id":459,"singerKor":"Q.O.Q","singerEng":"QOQ","songKor":"떠나가라","songEng":"Leaving"},
    {"id":460,"singerKor":"Red Velvet (레드벨벳)","singerEng":"Red Velvet (레드벨벳)","songKor":"Bad Boy","songEng":"Bad Boy"},
    {"id":461,"singerKor":"Red Velvet (레드벨벳)","singerEng":"Red Velvet (레드벨벳)","songKor":"Birthday","songEng":"Birthday"},
    {"id":462,"singerKor":"Red Velvet (레드벨벳)","singerEng":"Red Velvet (레드벨벳)","songKor":"Dumb Dumb","songEng":"Dumb Dumb."},
    {"id":463,"singerKor":"Red Velvet (레드벨벳)","singerEng":"Red Velvet (레드벨벳)","songKor":"Feel My Rhythm","songEng":"Feel My Rhythm"},
    {"id":464,"singerKor":"Red Velvet (레드벨벳)","singerEng":"Red Velvet (레드벨벳)","songKor":"Ice Cream Cake","songEng":"Ice Cream Cake"},
    {"id":465,"singerKor":"Red Velvet (레드벨벳)","singerEng":"Red Velvet (레드벨벳)","songKor":"Power Up","songEng":"Power Up"},
    {"id":466,"singerKor":"Red Velvet (레드벨벳)","singerEng":"Red Velvet (레드벨벳)","songKor":"Psycho","songEng":"Psycho."},
    {"id":467,"singerKor":"Red Velvet (레드벨벳)","singerEng":"Red Velvet (레드벨벳)","songKor":"Queendom","songEng":"Queendom"},
    {"id":468,"singerKor":"Red Velvet (레드벨벳)","singerEng":"Red Velvet (레드벨벳)","songKor":"Rookie","songEng":"Rookie"},
    {"id":469,"singerKor":"Red Velvet (레드벨벳)","singerEng":"Red Velvet (레드벨벳)","songKor":"러시안 룰렛 (Russian Roulette)","songEng":"Russian Roulette"},
    {"id":470,"singerKor":"Red Velvet (레드벨벳)","singerEng":"Red Velvet (레드벨벳)","songKor":"빨간 맛 (Red Flavor)","songEng":"Red Flavor"},
    {"id":471,"singerKor":"Red Velvet (레드벨벳)","singerEng":"Red Velvet (레드벨벳)","songKor":"빨간 맛 (Red Flavor)","songEng":"Red Flavor"},
    {"id":472,"singerKor":"Red Velvet (레드벨벳)","singerEng":"Red Velvet (레드벨벳)","songKor":"피카부 (Peek-A-Boo)","songEng":"Peek-A-Boo"},
    {"id":473,"singerKor":"S#arp","singerEng":"S#arp","songKor":"눈물","songEng":"Tears"},
    {"id":474,"singerKor":"S.E.S","singerEng":"SES","songKor":"감싸 안으며","songEng":"Embrace by Kim Yeon-woo."},
    {"id":475,"singerKor":"S.E.S.","singerEng":"SES","songKor":"U(New Version)","songEng":"U (New Version)"},
    {"id":476,"singerKor":"S.E.S.","singerEng":"SES","songKor":"꿈을 모아서","songEng":"Gather My Dreams"},
    {"id":477,"singerKor":"S.M. THE BALLAD","singerEng":"SM the Ballad","songKor":"숨소리(Breath) (Sung by 태연 of 소녀시대 & 종현 of 샤이니)","songEng":"Breath"},
    {"id":478,"singerKor":"Sam Smith_ Kim Petras","singerEng":"Sam Smith_ Kim Petras","songKor":"Unholy","songEng":"Unholy"},
    {"id":479,"singerKor":"San E","singerEng":"San E","songKor":"Body Language (Feat. 범키)","songEng":"Body Language (Feat. 범키)"},
    {"id":480,"singerKor":"San E","singerEng":"San E","songKor":"Me You (Feat. 백예린 Of 15&)","songEng":"Me You (Feat. 백예린 Of 15&)"},
    {"id":481,"singerKor":"San E","singerEng":"San E","songKor":"Me You (Feat. ???? Of 15&)","songEng":"Me You (Feat Baek Yerin Of 15&)"},
    {"id":482,"singerKor":"San E","singerEng":"San E","songKor":"나 왜이래 (Feat. 강민희 Of 미스에스)","songEng":"Why Am I Like This (Feat. Kang Minhee Of Miss $)"},
    {"id":483,"singerKor":"San E_ 레이나","singerEng":"San E_ 레이나","songKor":"한여름밤의 꿀","songEng":"Midsummer Night's Sweetness"},
    {"id":484,"singerKor":"See Ya","singerEng":"Seeya","songKor":"Hot Girl","songEng":"Hot Girl"},
    {"id":485,"singerKor":"Seeya  ＆ 브라운아이드 걸스","singerEng":"Seeya & Brown Eyed Girls","songKor":"The Day","songEng":"The Day"},
    {"id":486,"singerKor":"SeeYa","singerEng":"Seeya","songKor":"슬픈 발걸음 (구두 II)","songEng":"Sad Footsteps (Shoes II)"},
    {"id":487,"singerKor":"SES","singerEng":"SES","songKor":"Dreams Come True","songEng":"Dreams Come True"},
    {"id":488,"singerKor":"SEVENTEEN","singerEng":"SEVENTEEN","songKor":"Darl+ing","songEng":"Darl+ing"},
    {"id":489,"singerKor":"SEVENTEEN","singerEng":"SEVENTEEN","songKor":"HOT","songEng":"HOT"},
    {"id":490,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"Ordinary People","songEng":"Ordinary People"},
    {"id":491,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"Timeless","songEng":"Timeless."},
    {"id":492,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"가","songEng":"Go"},
    {"id":493,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"가슴 뛰도록","songEng":"Heartbeat"},
    {"id":494,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"가시리","songEng":"Gashiri"},
    {"id":495,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"꿈의 대화 (R&B Soul Ver)","songEng":"Dream Conversation R&B Soul Ver"},
    {"id":496,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"내 마음의 보석상자","songEng":"My Heart's Treasure Box"},
    {"id":497,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"내사람  Partner For Life","songEng":"Partner For Life"},
    {"id":498,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"내사람","songEng":"My Person"},
    {"id":499,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"내사랑 울보","songEng":"My Love Crybaby"},
    {"id":500,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"느림보","songEng":"Slowpoke"},
    {"id":501,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"라라라","songEng":"Lalala."},
    {"id":502,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"바람과 함께 사라지다","songEng":"Gone with the Wind"},
    {"id":503,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"사랑가","songEng":"Love Song"},
    {"id":504,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"사랑하길 정말 잘했어요","songEng":"I Am So In Love."},
    {"id":505,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"사랑하자","songEng":"Let's Love."},
    {"id":506,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"사랑해 (Feat. 유리상자 박승화)","songEng":"Love (Feat. Park Seung-hwa of Glass Box)"},
    {"id":507,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"살다가","songEng":"Live"},
    {"id":508,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"아리랑","songEng":"Arirang"},
    {"id":509,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"죄와 벌","songEng":"Punishment and Crime."},
    {"id":510,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"죽을 만큼 사랑했어요","songEng":"I Loved You to Death"},
    {"id":511,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"처음처럼","songEng":"Like The First Time"},
    {"id":512,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"폭풍","songEng":"Storm"},
    {"id":513,"singerKor":"SG워너비","singerEng":"SG Wannabe","songKor":"한 여름날의 꿈 (Duet. 옥주현)","songEng":"Dream of a Midsummer Day (Duet. Ok Ju Hyun)"},
    {"id":514,"singerKor":"SG워너비&브아걸","singerEng":"SG Wanna Be and Brown Eyed Girls","songKor":"Must Have Love","songEng":"Must Have Love"},
    {"id":515,"singerKor":"sharp","singerEng":"Taemin","songKor":"Sweety","songEng":"Sweety"},
    {"id":516,"singerKor":"Sharp","singerEng":"Sharp","songKor":"백일기도","songEng":"Hundred Day Prayer"},
    {"id":517,"singerKor":"Shin Ye-Young","singerEng":"Shin Ye-Young","songKor":"Monologue","songEng":"Monologue"},
    {"id":518,"singerKor":"SHINee","singerEng":"SHINee","songKor":"Sherlock.셜록 (Clue+Note)","songEng":"Sherlock Clue Note"},
    {"id":519,"singerKor":"SHINee","singerEng":"SHINee","songKor":"산소 같은 너 (Love Like Oxygen)","songEng":"Love Like Oxygen"},
    {"id":520,"singerKor":"Sia","singerEng":"BTS","songKor":"Snowman","songEng":"Snowman"},
    {"id":521,"singerKor":"Simon Dominic","singerEng":"Simon Dominic","songKor":"사이먼 도미닉","songEng":"Simon Dominic"},
    {"id":522,"singerKor":"Sin Ye Young","singerEng":"Sin Ye Young","songKor":"Last Love","songEng":"Last Love"},
    {"id":523,"singerKor":"SM Town","singerEng":"NCT 127","songKor":"Summer Vacation","songEng":"Summer Vacation"},
    {"id":524,"singerKor":"sokodomo","singerEng":"Sokodomo","songKor":"회전목마 (Feat. Zion.T & 원슈타인) (Prod. by Slom)","songEng":"Carousel (Feat. Zion.T & Wonstein) (Prod. by Slom)"},
    {"id":525,"singerKor":"sokodomo","singerEng":"Sokodomo","songKor":"회전목마 (Feat. Zion.T_ Wonstein)","songEng":"Rotary Phone (Feat. Zion.T_ Wonstein)"},
    {"id":526,"singerKor":"sokodomo","singerEng":"Sokodomo","songKor":"회전목마 (Feat. Zion.T_ 원슈타인) (Prod. Slom)","songEng":"Merry-Go-Round (Feat. Zion.T_ Wonstein) (Prod. Slom)"},
    {"id":527,"singerKor":"sokodomo_ Zion.T_ Wonstein","singerEng":"Sokodomo_ ZionT_ Wonstein","songKor":"MERRYGOROUND (Feat. Zion.T_ Wonstein) (Prod. Slom)","songEng":"Merrygoround"},
    {"id":528,"singerKor":"sokodomo_ Zion.T_ Wonstein","singerEng":"sokodomo_ ZionT_ Wonstein","songKor":"회전목마","songEng":"Merry-Go-Round"},
    {"id":529,"singerKor":"Song Haye","singerEng":"Song Haye","songKor":"Seeing you on a tough day","songEng":"I'm Sorry."},
    {"id":530,"singerKor":"Sorry_ Sorry","singerEng":"Super Junior","songKor":"Sorry_ Sorry","songEng":"Sorry Sorry"},
    {"id":531,"singerKor":"Soul Star","singerEng":"Baek Ji-young","songKor":"우리가 이별할 때 (feat. 백지영)","songEng":"When We Disappear (feat. Baek Ji Young)"},
    {"id":532,"singerKor":"SS501","singerEng":"SS501","songKor":"Love Like This (네게로)","songEng":"Love Like This (네게로)"},
    {"id":533,"singerKor":"Standing Egg (스탠딩 에그)","singerEng":"Standing Egg (스탠딩 에그)","songKor":"오래된 노래","songEng":"Old Song"},
    {"id":534,"singerKor":"STAYC (스테이씨)","singerEng":"STAYC (스테이씨)","songKor":"ASAP","songEng":"ASAP"},
    {"id":535,"singerKor":"STAYC (스테이씨)","singerEng":"STAYC (스테이씨)","songKor":"BEAUTIFUL MONSTER","songEng":"Beautiful Monster."},
    {"id":536,"singerKor":"STAYC (스테이씨)","singerEng":"STAYC (스테이씨)","songKor":"RUN2U","songEng":"Run2U"},
    {"id":537,"singerKor":"STAYC","singerEng":"STAYC","songKor":"Poppy (Korean Ver.)","songEng":"Poppy"},
    {"id":538,"singerKor":"StayC","singerEng":"StayC","songKor":"RUN2U","songEng":"RUN2U"},
    {"id":539,"singerKor":"STAYC","singerEng":"STAYC","songKor":"Teddy Bear","songEng":"Teddy Bear."},
    {"id":540,"singerKor":"Sung Si Kyung","singerEng":"Sung Si Kyung","songKor":"Every moment of you 너의 모든 순간","songEng":"Every moment of you"},
    {"id":541,"singerKor":"Sung Si Kyung","singerEng":"Sung Si Kyung","songKor":"HeeJae","songEng":"HeeJae"},
    {"id":542,"singerKor":"T (윤미래)","singerEng":"Yoonmirae","songKor":"To My Love","songEng":"To My Love"},
    {"id":543,"singerKor":"T","singerEng":"BTS","songKor":"Memories (Smiling Tears)","songEng":"Memories Smiling Tears"},
    {"id":544,"singerKor":"T","singerEng":"BTS","songKor":"시간이 흐른뒤","songEng":"After Time Passes"},
    {"id":545,"singerKor":"t 윤미래","singerEng":"Yoonmirae","songKor":"Get It In (Feat. Tiger JK_ 정인) (Korean Ver.)","songEng":"Get It In (Feat. Tiger JK_ 정인) (Korean Ver)"},
    {"id":546,"singerKor":"t 윤미래","singerEng":"Yoonmirae","songKor":"너를 사랑해","songEng":"I Love You"},
    {"id":547,"singerKor":"T(티)","singerEng":"T (티)","songKor":"떠나지마...","songEng":"Do not leave"},
    {"id":548,"singerKor":"TAEYANG_ Jimin","singerEng":"TAEYANG Jimin","songKor":"VIBE","songEng":"VIBE"},
    {"id":549,"singerKor":"TAEYEON","singerEng":"Taeyeon","songKor":"INVU","songEng":"INVU"},
    {"id":550,"singerKor":"TAEYEON","singerEng":"TAEYEON","songKor":"Nights Into Days (Prod. by Naul)","songEng":"Nights Into Days"},
    {"id":551,"singerKor":"TAEYEON","singerEng":"TAEYEON","songKor":"Weekend","songEng":"Weekend"},
    {"id":552,"singerKor":"Tei","singerEng":"Tei","songKor":"Monologue","songEng":"Monologue"},
    {"id":553,"singerKor":"Tei","singerEng":"Tei","songKor":"사랑은...향기를 남기고","songEng":"Love Leaves a Scent... Without the period_ the answer is Love Leaves a Scent"},
    {"id":554,"singerKor":"The Nuts (더 넛츠)","singerEng":"The Nuts (더 넛츠)","songKor":"내 사람입니다","songEng":"My Person."},
    {"id":555,"singerKor":"The Nuts (더 넛츠)","singerEng":"The Nuts (더 넛츠)","songKor":"잔소리","songEng":"Nagging"},
    {"id":556,"singerKor":"Tiara","singerEng":"Tiara","songKor":"거짓말 (Part. 2)","songEng":"Lies (Part 2)"},
    {"id":557,"singerKor":"Tim","singerEng":"Tim","songKor":"03 고마웠다고..","songEng":"Thank You..."},
    {"id":558,"singerKor":"TOIL & Gist","singerEng":"TOIL & Gist","songKor":"처음 마주쳤을 때처럼","songEng":"Just Like the First Day We Met"},
    {"id":559,"singerKor":"TOMORROW X TOGETHER","singerEng":"TOMORROW X TOGETHER","songKor":"Devil by the Window","songEng":"Devil by the Window"},
    {"id":560,"singerKor":"TOMORROW X TOGETHER","singerEng":"TOMORROW X TOGETHER","songKor":"Farewell_ Neverland","songEng":"Farewell Neverland"},
    {"id":561,"singerKor":"TOMORROW X TOGETHER","singerEng":"Tomorrow X Together","songKor":"Sugar Rush Ride","songEng":"Sugar Rush Ride"},
    {"id":562,"singerKor":"TOMORROW X TOGETHER","singerEng":"TOMORROW X TOGETHER","songKor":"Tinnitus (Wanna be a rock)","songEng":"Tinnitus (Wanna be a rock)"},
    {"id":563,"singerKor":"TOMORROW X TOGETHER_ Coi Leray","singerEng":"TOMORROW X TOGETHER_ Coi Leray","songKor":"Happy Fools (feat. Coi Leray)","songEng":"Happy Fools (feat Coi Leray)"},
    {"id":564,"singerKor":"TopHyun","singerEng":"Tophyun","songKor":"Horangsuwolga","songEng":"Horangsuwolga"},
    {"id":565,"singerKor":"TopHyun","singerEng":"Tophyun","songKor":"Tell me you love me","songEng":"Tell me you love me"},
    {"id":566,"singerKor":"Trouble Maker","singerEng":"HyunaJangHyunseung","songKor":"Trouble Maker","songEng":"Trouble Maker"},
    {"id":567,"singerKor":"TWICE (트와이스)","singerEng":"TWICE (트와이스)","songKor":"Alcohol-Free","songEng":"Alcohol Free"},
    {"id":568,"singerKor":"TWICE (트와이스)","singerEng":"TWICE (트와이스)","songKor":"CHEER UP","songEng":"Cheer Up"},
    {"id":569,"singerKor":"TWICE (트와이스)","singerEng":"TWICE (트와이스)","songKor":"Dance The Night Away","songEng":"Dance The Night Away"},
    {"id":570,"singerKor":"TWICE (트와이스)","singerEng":"TWICE (트와이스)","songKor":"Heart Shaker","songEng":"Heart Shaker"},
    {"id":571,"singerKor":"TWICE (트와이스)","singerEng":"TWICE (트와이스)","songKor":"KNOCK KNOCK","songEng":"Knock Knock"},
    {"id":572,"singerKor":"TWICE (트와이스)","singerEng":"TWICE (트와이스)","songKor":"LIKEY","songEng":"Likey"},
    {"id":573,"singerKor":"TWICE (트와이스)","singerEng":"TWICE (트와이스)","songKor":"MORE & MORE","songEng":"MORE & MORE"},
    {"id":574,"singerKor":"TWICE (트와이스)","singerEng":"TWICE (트와이스)","songKor":"OOH-AHH하게","songEng":"OOH-AHH by TWICE."},
    {"id":575,"singerKor":"TWICE (트와이스)","singerEng":"TWICE (트와이스)","songKor":"SIGNAL","songEng":"Signal"},
    {"id":576,"singerKor":"TWICE (트와이스)","singerEng":"TWICE (트와이스)","songKor":"Talk that Talk","songEng":"Talk that Talk"},
    {"id":577,"singerKor":"TWICE (트와이스)","singerEng":"TWICE (트와이스)","songKor":"TT","songEng":"TT"},
    {"id":578,"singerKor":"TWICE (트와이스)","singerEng":"TWICE (트와이스)","songKor":"What is Love","songEng":"What is Love"},
    {"id":579,"singerKor":"TWICE (트와이스)","singerEng":"TWICE (트와이스)","songKor":"YES or YES","songEng":"Yes or Yes"},
    {"id":580,"singerKor":"TWICE","singerEng":"TWICE (트와이스)","songKor":"SET ME FREE","songEng":"Set Me Free"},
    {"id":581,"singerKor":"TWICE","singerEng":"TWICE (트와이스)","songKor":"Talk that Talk","songEng":"Talk that Talk"},
    {"id":582,"singerKor":"TWICE(트와이스)","singerEng":"TWICE","songKor":"FANCY","songEng":"Fancy"},
    {"id":583,"singerKor":"V","singerEng":"BTS","songKor":"Christmas Tree","songEng":"Christmas Tree"},
    {"id":584,"singerKor":"V.O.S","singerEng":"VOS","songKor":"Like Crazy","songEng":"Like Crazy"},
    {"id":585,"singerKor":"V.O.S","singerEng":"VOS","songKor":"미친 것처럼","songEng":"Crazy Gone Matchin'"},
    {"id":586,"singerKor":"V.O.S","singerEng":"VOS","songKor":"큰일이다","songEng":"Big Deal"},
    {"id":587,"singerKor":"V.O.S.","singerEng":"VOS","songKor":"Beautiful Life","songEng":"Beautiful Life"},
    {"id":588,"singerKor":"Vibe","singerEng":"Vibe","songKor":"가을 타나 봐","songEng":"Autumn Leaves are Falling."},
    {"id":589,"singerKor":"Wanna One (워너원)","singerEng":"Wanna One (워너원)","songKor":"Beautiful","songEng":"Beautiful"},
    {"id":590,"singerKor":"Wanna One (워너원)","singerEng":"Wanna One (워너원)","songKor":"BOOMERANG (부메랑)","songEng":"Boomerang"},
    {"id":591,"singerKor":"Wanna One (워너원)","singerEng":"Wanna One (워너원)","songKor":"약속해요 (I.P.U.)","songEng":"I Promise You (I.P.U)"},
    {"id":592,"singerKor":"Wanna One (워너원)","singerEng":"Wanna One (워너원)","songKor":"에너제틱 (Energetic)","songEng":"Energetic"},
    {"id":593,"singerKor":"Wanna One (워너원)","singerEng":"Wanna One (워너원)","songKor":"켜줘 (Light)","songEng":"Light"},
    {"id":594,"singerKor":"Whee In","singerEng":"Whee In","songKor":"You_ you (Nth Romance X Whee In)","songEng":"You_ you (Nth Romance X Whee In)"},
    {"id":595,"singerKor":"WINNER","singerEng":"WINNER","songKor":"AH YEAH (아예)","songEng":"Ah Yeah"},
    {"id":596,"singerKor":"WINNER","singerEng":"WINNER","songKor":"EVERYDAY","songEng":"Everyday"},
    {"id":597,"singerKor":"WINNER","singerEng":"WINNER","songKor":"I LOVE U","songEng":"I Love U"},
    {"id":598,"singerKor":"WINNER","singerEng":"WINNER","songKor":"MILLIONS","songEng":"Millions"},
    {"id":599,"singerKor":"WINNER","singerEng":"WINNER","songKor":"REALLY REALLY","songEng":"REALLY REALLY"},
    {"id":600,"singerKor":"WINNER","singerEng":"WINNER","songKor":"공허해","songEng":"Empty"},
    {"id":601,"singerKor":"WINNER","singerEng":"WINNER","songKor":"센치해","songEng":"Sentimental"},
    {"id":602,"singerKor":"Wonder Girls","singerEng":"Wonder Girls","songKor":"Nobody","songEng":"Nobody."},
    {"id":603,"singerKor":"WonderGirls","singerEng":"Wonder Girls","songKor":"So Hot","songEng":"So Hot"},
    {"id":604,"singerKor":"Woody","singerEng":"Woody","songKor":"Say I Love You","songEng":"Say I Love You"},
    {"id":605,"singerKor":"WSG WANNABE (Sa-Fire)","singerEng":"WSG WANNABE (Sa-Fire)","songKor":"I Missed You","songEng":"I Miss You"},
    {"id":606,"singerKor":"WSG WANNABE GAYA-G","singerEng":"Sorry_ as an AI language model_ I cannot comply with this request because WSG WANNABE GAYA-G is not a known Korean artist in the English language. It is possible that the name is misspelled or incomplete. Please provide additional information or clarify your request.","songKor":"To You","songEng":"To You"},
    {"id":607,"singerKor":"WSG WANNBE (Gaya-G)","singerEng":"WSG WANNBE (Gaya-G)","songKor":"At That Moment","songEng":"At That Moment"},
    {"id":608,"singerKor":"WSG워너비 (4FIRE)","singerEng":"WSG Wonder Boyz","songKor":"보고싶었어","songEng":"I Miss You"},
    {"id":609,"singerKor":"WSG워너비 (4FIRE)","singerEng":"WSG Wonder Boyz","songKor":"보고싶었어","songEng":"I Miss You"},
    {"id":610,"singerKor":"WSG워너비 (오아시소)","singerEng":"WSG Wannabe (Oasiso)","songKor":"Clink Clink (클링 클링)","songEng":"Clink Clink"},
    {"id":611,"singerKor":"WSG워너비","singerEng":"WSG Wonder Boyz","songKor":"눈을 감으면","songEng":"When I Close My Eyes"},
    {"id":612,"singerKor":"XIA (준수)","singerEng":"XIA (준수)","songKor":"How Can I Love You","songEng":"How Can I Love You"},
    {"id":613,"singerKor":"Y.G Family","singerEng":"YG Family","songKor":"멋쟁이 신사","songEng":"Dandy Boy"},
    {"id":614,"singerKor":"Yang Da Il","singerEng":"Yang Da Il","songKor":"May I love you","songEng":"May I love you"},
    {"id":615,"singerKor":"Yang Yo Seop_ EUNHA","singerEng":"Yang Yo Seop_ EUNHA","songKor":"Telepathy","songEng":"Telepathy"},
    {"id":616,"singerKor":"Yang Yoseop","singerEng":"Yang Yoseop","songKor":"Again_ goodbye","songEng":"Goodbye Again."},
    {"id":617,"singerKor":"YB (윤도현밴드)","singerEng":"YB","songKor":"사랑 TWO","songEng":"Love TWO"},
    {"id":618,"singerKor":"YB (윤도현밴드)","singerEng":"YB","songKor":"잊을께","songEng":"I'll Forget You"},
    {"id":619,"singerKor":"YB","singerEng":"YB","songKor":"너를 보내고 Ⅱ","songEng":"Goodbye (Part 2)"},
    {"id":620,"singerKor":"YB","singerEng":"YB","songKor":"사랑 Two","songEng":"Love Two"},
    {"id":621,"singerKor":"YB","singerEng":"YB","songKor":"잊을께","songEng":"I'll Never Forget You"},
    {"id":622,"singerKor":"YENA (최예나)","singerEng":"YENA (Choi Yena)","songKor":"SMILEY (Feat. BIBI)","songEng":"Smiley"},
    {"id":623,"singerKor":"YENA (최예나)_ BIBI","singerEng":"YENA (최예나)_ BIBI","songKor":"SMILEY (Feat. BIBI)","songEng":"Smiley"},
    {"id":624,"singerKor":"Yena_ BIBI","singerEng":"Yena_ BIBI","songKor":"SMILEY(Feat. BIBI)","songEng":"Smiley"},
    {"id":625,"singerKor":"Yoon Mi Rae_ BIBI","singerEng":"Yoon Mi Rae_ BIBI","songKor":"LAW (Prod. by Czaer)","songEng":"LAW (Prod. by Czaer)"},
    {"id":626,"singerKor":"Younha","singerEng":"Younha","songKor":"Event Horizon","songEng":"Event Horizon."},
    {"id":627,"singerKor":"Younha","singerEng":"Younha","songKor":"Oort Cloud","songEng":"Oort Cloud"},
    {"id":628,"singerKor":"ZiA","singerEng":"ZiA","songKor":"사랑해 미안해","songEng":"Love Sorry"},
    {"id":629,"singerKor":"ZiA","singerEng":"ZiA","songKor":"터질 것 같아","songEng":"It's Gonna Be Hot."},
    {"id":630,"singerKor":"ZICO_ Homies","singerEng":"ZICO_ Homies","songKor":"New thing (Prod. by ZICO) (Feat. Homies)","songEng":"New thing"},
    {"id":631,"singerKor":"Zion.T","singerEng":"Zion.T","songKor":"Babay (feat. Gaeko)","songEng":"Babay (feat Gaeko)"},
    {"id":632,"singerKor":"Zion.T","singerEng":"Zion.T","songKor":"No Make Up","songEng":"No Make Up"},
    {"id":633,"singerKor":"Zion.T_ Crush","singerEng":"ZionT_ Crush","songKor":"그냥 (Just)","songEng":"Just"},
    {"id":634,"singerKor":"Zior Park","singerEng":"Zior Park","songKor":"CHRISTIAN","songEng":"CHRISTIAN"},
    {"id":635,"singerKor":"Z?A","singerEng":"ZIA","songKor":"Tired of falling in love","songEng":"Tired of Falling in Love"},
    {"id":636,"singerKor":"가비 엔제이(Gavy N.J.)","singerEng":"Gavy N.J","songKor":"Lie","songEng":"Lie"},
    {"id":637,"singerKor":"가비엔제이","singerEng":"Gavy N.J","songKor":"Happiness","songEng":"Happiness"},
    {"id":638,"singerKor":"가비엔제이","singerEng":"Gavy N.J","songKor":"해바라기 (Feat. 써니사이드 MJ)","songEng":"Sunflower (Feat. SunnySide MJ)"},
    {"id":639,"singerKor":"가인","singerEng":"Gain","songKor":"Apple (Feat. 박재범)","songEng":"Apple (Feat. Jay Park)"},
    {"id":640,"singerKor":"가인","singerEng":"Gain","songKor":"우리 사랑하게 됐어요.","songEng":"We've Fallen in Love"},
    {"id":641,"singerKor":"가인","singerEng":"Gain","songKor":"피어나","songEng":"Spring Day"},
    {"id":642,"singerKor":"가호 (Gaho)","singerEng":"Gaho","songKor":"시작","songEng":"Start"},
    {"id":643,"singerKor":"강민경 (다비치)_ 잔나비 최정훈","singerEng":"Kang Min Kyung (Davichi)_ Jan Zannabi Choe Jung Hoon","songKor":"우린 그렇게 사랑해서","songEng":"Our Love Like This"},
    {"id":644,"singerKor":"강민경_ 잔나비 최정훈","singerEng":"Kang Min-kyung_ Jannabi Choi Jung-hoon","songKor":"우린 그렇게 사랑해서","songEng":"We Loved That Way"},
    {"id":645,"singerKor":"강산에","singerEng":"Kang San Ae.","songKor":"넌 할 수 있어","songEng":"You Can Do It"},
    {"id":646,"singerKor":"강성훈","singerEng":"Kang Sung-hoon","songKor":"보이지 않는 인사","songEng":"Invisible Greetings"},
    {"id":647,"singerKor":"강성훈","singerEng":"Kang Sung-hoon","songKor":"아껴둔 이야기_(radio edit ver.)","songEng":"Saved Stories (radio edit ver.)"},
    {"id":648,"singerKor":"강성훈","singerEng":"Kang Sung-hoon","songKor":"축복","songEng":"Blessing"},
    {"id":649,"singerKor":"강승모","singerEng":"Kang Seungmo","songKor":"눈물의 재회","songEng":"Reunion of Tears"},
    {"id":650,"singerKor":"강승윤","singerEng":"Kang Seungyoon","songKor":"본능적으로 (Feat. Swings)","songEng":"Instinctively"},
    {"id":651,"singerKor":"강타","singerEng":"Kangta","songKor":"북극성","songEng":"Aurora."},
    {"id":652,"singerKor":"강타","singerEng":"Kangta","songKor":"사랑은 기억보다","songEng":"Love is More Than a Memory"},
    {"id":653,"singerKor":"강현준_김호진","singerEng":"Kang Hyun Jun_ Kim Ho Jin","songKor":"눈","songEng":"Snow"},
    {"id":654,"singerKor":"개리","singerEng":"Gary","songKor":"또 하루 (Feat. 개코)","songEng":"Ddo Haru (Feat. Gaeko)"},
    {"id":655,"singerKor":"개리","singerEng":"Gary","songKor":"바람이나 좀 쐐 (Feat. MIWOO)","songEng":"Wind Stop Blowing (Feat. MIWOO)"},
    {"id":656,"singerKor":"개코","singerEng":"Gaeko","songKor":"화장 지웠어 (Feat. Zion.T_ HATFELT) (No Make Up)","songEng":"No Make Up"},
    {"id":657,"singerKor":"개코_ Ourealgoat (아우릴고트)_ SINCE_ 안병웅_ Tabber_ 조광일","singerEng":"Gaeko_ Ourealgoat_ SINCE_ Ahn Byungwoong_ Tabber_ Jo Kwangil.","songKor":"Wake Up (Prod. by CODE KUNST)","songEng":"Wake Up"},
    {"id":658,"singerKor":"거미","singerEng":"Gummy","songKor":"You Are My Everything","songEng":"You Are My Everything"},
    {"id":659,"singerKor":"거미","singerEng":"Gummy","songKor":"구르미 그린 달빛","songEng":"Love in the Moonlight"},
    {"id":660,"singerKor":"거미","singerEng":"Gummy","songKor":"기억상실","songEng":"Memory loss"},
    {"id":661,"singerKor":"거미","singerEng":"Gummy","songKor":"기억해줘요 내 모든 날과 그때를","songEng":"Remember every moment of my days and that time"},
    {"id":662,"singerKor":"거미","singerEng":"Gummy","songKor":"남자라서","songEng":"Being a Man"},
    {"id":663,"singerKor":"거미","singerEng":"Gummy","songKor":"미안해요 (Feat. T.O.P)","songEng":"I'm sorry."},
    {"id":664,"singerKor":"거미","singerEng":"Gummy","songKor":"아니","songEng":"Ani."},
    {"id":665,"singerKor":"거미","singerEng":"Gummy","songKor":"죽어도 사랑해","songEng":"Even if I Die_ I Can't Let You Go"},
    {"id":666,"singerKor":"거미","singerEng":"Gummy","songKor":"친구라도 될 걸 그랬어","songEng":"We Don't Talk Anymore"},
    {"id":667,"singerKor":"거미","singerEng":"Gummy","songKor":"통증","songEng":"Torment"},
    {"id":668,"singerKor":"거미","singerEng":"Gummy","songKor":"혼자만 하는 사랑","songEng":"Alone Love."},
    {"id":669,"singerKor":"거북이","singerEng":"Geobuki","songKor":"비행기","songEng":"Airplane"},
    {"id":670,"singerKor":"거북이","singerEng":"Geobugi","songKor":"빙고","songEng":"Bingo"},
    {"id":671,"singerKor":"걸스데이","singerEng":"Girl's Day","songKor":"Darling","songEng":"Darling"},
    {"id":672,"singerKor":"걸스데이","singerEng":"Girl's Day","songKor":"Something","songEng":"Something."},
    {"id":673,"singerKor":"걸스데이","singerEng":"Girl's Day","songKor":"기대해","songEng":"Anticipation."},
    {"id":674,"singerKor":"걸스데이","singerEng":"Girl's Day","songKor":"링마벨 (Ring My Bell)","songEng":"Ring My Bell"},
    {"id":675,"singerKor":"걸스데이","singerEng":"Girl's Day","songKor":"여자 대통령","songEng":"Woman President"},
    {"id":676,"singerKor":"걸스데이(Girl`s Day)","singerEng":"Girlsday","songKor":"반짝반짝","songEng":"Twinkle Twinkle"},
    {"id":677,"singerKor":"걸프렌즈(Girlfriends)","singerEng":"Girlfriends","songKor":"Maybe I Love You (Feat.빅톤)","songEng":"Maybe I Love You (Feat. VICTON)"},
    {"id":678,"singerKor":"견우","singerEng":"Gyeonwoo","songKor":"내 눈물이 하는말 (0359)","songEng":"My Tears Are Saying (0359)"},
    {"id":679,"singerKor":"경서","singerEng":"Kyungso.","songKor":"나의 X에게","songEng":"My Dear by AKMU"},
    {"id":680,"singerKor":"경서","singerEng":"Kyungso.","songKor":"밤하늘의 별을(2020)","songEng":"Starry Night (2020)"},
    {"id":681,"singerKor":"경서","singerEng":"Kyungso.","songKor":"고백연습","songEng":"Confession Practice"},
    {"id":682,"singerKor":"경서","singerEng":"Kyungso.","songKor":"나의 X에게","songEng":"To My Ex"},
    {"id":683,"singerKor":"경서","singerEng":"Kyungso.","songKor":"밤하늘의 별을 (2020)","songEng":"Night Sky Stars"},
    {"id":684,"singerKor":"경서예지 & 전건호","singerEng":"Gyeongseo Yeji & Jeon Geonho","songKor":"다정히 내 이름을 부르면","songEng":"Call out My Name Tenderly"},
    {"id":685,"singerKor":"경서예지","singerEng":"Kyung Soo-yeon","songKor":"사실 나는 (Feat.전건호)","songEng":"Actually_ I'm (Feat. Jun Gunho)"},
    {"id":686,"singerKor":"경서예지_ 전건호","singerEng":"Kyungsoo Yeji_ Jeon Geonho","songKor":"그 겨울이 잠든 거리에서","songEng":"From the street where winter sleeps."},
    {"id":687,"singerKor":"경서예지_ 전건호","singerEng":"Kyungso Yaeji_ Jeon Geonho","songKor":"다정히 내 이름을 부르면","songEng":"Gently Call My Name"},
    {"id":688,"singerKor":"계은숙","singerEng":"Gye Eun-Suk","songKor":"기다리는 여심","songEng":"Waiting Heart"},
    {"id":689,"singerKor":"공일오비(015B)","singerEng":"015B","songKor":"슬픈 인연","songEng":"Sad Fate"},
    {"id":690,"singerKor":"구창모","singerEng":"Goo Changmo","songKor":"아픈만큼 성숙해지고","songEng":"As painful as it matures"},
    {"id":691,"singerKor":"구창모","singerEng":"Goo Changmo","songKor":"희나리","songEng":"Heenari"},
    {"id":692,"singerKor":"국민의 아들","singerEng":"Kang Daniel","songKor":"NEVER","songEng":"Never by Loona."},
    {"id":693,"singerKor":"규현 (KyuHyun)","singerEng":"KyuHyun","songKor":"광화문에서 (At Gwanghwamun)","songEng":"At Gwanghwamun"},
    {"id":694,"singerKor":"그루비룸_ 릴보이_ Blase_ 노윤하_ Polodared_ Chillin Homie_ Fleeky Bang","singerEng":"GroovyRoom_ Lil Boi_ Blase_ No Yunha_ Polodared_ Chillin Homie_ Fleeky Bang.","songKor":"위하여 (We Higher) (Prod. GroovyRoom)","songEng":"We Higher"},
    {"id":695,"singerKor":"그루비룸_ 릴보이_ Blase_ 노윤하_ Polodared_ Chillin Homie_ Fleeky Bang","singerEng":"GroovyRoom_ Lil Boi_ Blase_ Noh Yun-ha_ Polodared_ Chillin Homie_ Fleeky Bang.","songKor":"위하여 (We Higher) (Prod. GroovyRoom)","songEng":"We Higher"},
    {"id":696,"singerKor":"그룹S","singerEng":"Group S","songKor":"I Swear","songEng":"I Swear"},
    {"id":697,"singerKor":"기리보이 (Giriboy)","singerEng":"Giriboy","songKor":"눈이 오던 날 (Feat. SOLE)","songEng":"Snowfalling (Feat. SOLE)"},
    {"id":698,"singerKor":"기리보이_ Kid Milli_ NOEL_ 스윙스","singerEng":"Giriboy_ Kid Milli_ NOEL_ Swings","songKor":"flex (Prod. By 기리보이)","songEng":"Flex"},
    {"id":699,"singerKor":"긱스 (Geeks)","singerEng":"Geeks","songKor":"Officially Missing You","songEng":"Officially Missing You"},
    {"id":700,"singerKor":"긱스","singerEng":"Giks","songKor":"Officially Missing You","songEng":"Officially Missing You"},
    {"id":701,"singerKor":"긱스","singerEng":"Gikseu","songKor":"Wash Away (Feat. 에일리)","songEng":"Wash Away (Feat. Ailee)"},
    {"id":702,"singerKor":"긱스_ 소유 (씨스타)","singerEng":"Gikseu_ Soyu (Sistar)","songKor":"Officially Missing You_ Too","songEng":"Officially Missing You Too"},
    {"id":703,"singerKor":"길구봉구","singerEng":"Gilgu Bonggu","songKor":"모든 계절은 너였다","songEng":"Every Season Was You"},
    {"id":704,"singerKor":"길구봉구","singerEng":"Gilgu Bonggu","songKor":"바람이 불었으면 좋겠어","songEng":"I Wish the Wind Would Blow"},
    {"id":705,"singerKor":"김 성호","singerEng":"Kim Seongho","songKor":"김성호의 회상","songEng":"Kim Sung Ho's Memories"},
    {"id":706,"singerKor":"김 태우","singerEng":"Kim Tae-woo","songKor":"사랑비","songEng":"Love Rain"},
    {"id":707,"singerKor":"김건모","singerEng":"Kim Gun Mo","songKor":"03_미안해요","songEng":"Sorry."},
    {"id":708,"singerKor":"김건모","singerEng":"Kim Gun Mo","songKor":"Double","songEng":"Double"},
    {"id":709,"singerKor":"김건모","singerEng":"Kim Gun-mo","songKor":"미안해요","songEng":"Sorry"},
    {"id":710,"singerKor":"김건모","singerEng":"Kim Gun Mo","songKor":"뻐꾸기 둥지 위로 날아간 새","songEng":"Fly Away Little Bird"},
    {"id":711,"singerKor":"김건모","singerEng":"Kim Gun-mo","songKor":"잔소리","songEng":"Nagging"},
    {"id":712,"singerKor":"김건모","singerEng":"Kim Gun Mo","songKor":"잘못된 만남","songEng":"Wrong Meeting"},
    {"id":713,"singerKor":"김건모","singerEng":"Kim Gun Mo","songKor":"제비","songEng":"Swallow"},
    {"id":714,"singerKor":"김건모","singerEng":"Kim Gun Mo","songKor":"청첩장","songEng":"Wedding Invitation"},
    {"id":715,"singerKor":"김건모","singerEng":"Kim Gun Mo","songKor":"핑계","songEng":"Excuse."},
    {"id":716,"singerKor":"김경록","singerEng":"Kim Kyung-rok","songKor":"J Of Jewelry)","songEng":"One More Time"},
    {"id":717,"singerKor":"김경호","singerEng":"Kim Kyung-ho","songKor":"나를 슬프게 하는 사람들","songEng":"People Who Make Me Sad"},
    {"id":718,"singerKor":"김경호","singerEng":"Kim Kyung-ho","songKor":"아버지","songEng":"Father"},
    {"id":719,"singerKor":"김경호","singerEng":"Kim Kyung-ho","songKor":"와인","songEng":"Wine"},
    {"id":720,"singerKor":"김경호","singerEng":"Kim Kyung-ho","songKor":"희생","songEng":"Sacrifice"},
    {"id":721,"singerKor":"김광석","singerEng":"Kim Gwangseok","songKor":"거리에서","songEng":"Street."},
    {"id":722,"singerKor":"김광석","singerEng":"Kim Gwang-seok","songKor":"나의 노래","songEng":"My Song."},
    {"id":723,"singerKor":"김광석","singerEng":"Kim Gwang-seok","songKor":"이등병의 편지","songEng":"Letter from a Private"},
    {"id":724,"singerKor":"김광석","singerEng":"Kim Gwang-seok","songKor":"흐린 가을 하늘에 편지를 써","songEng":"Writing a Letter to the Cloudy Autumn Sky"},
    {"id":725,"singerKor":"김광진","singerEng":"Kim Kwang-jin","songKor":"동경소녀","songEng":"Tokyo Girl"},
    {"id":726,"singerKor":"김나영","singerEng":"Kim Nayoung","songKor":"솔직하게 말해서 나","songEng":"Honestly by Eric Nam."},
    {"id":727,"singerKor":"김나영","singerEng":"Kim Nayoung","songKor":"어땠을까","songEng":"How Would It Have Been"},
    {"id":728,"singerKor":"김나영","singerEng":"Kim Nayeong","songKor":"이별후회","songEng":"Regret of Goodbye"},
    {"id":729,"singerKor":"김대명","singerEng":"Kim Dae-myeong","songKor":"가을 우체국 앞에서","songEng":"Autumn Post Office"},
    {"id":730,"singerKor":"김동률","singerEng":"Kim Dongryul","songKor":"그게 나야","songEng":"That's Me"},
    {"id":731,"singerKor":"김동률","singerEng":"Kim Dongryul","songKor":"다시 사랑한다 말할까","songEng":"Shall we love again"},
    {"id":732,"singerKor":"김동률","singerEng":"Kim Dongryul","songKor":"다시 시작해보자","songEng":"Let's start again"},
    {"id":733,"singerKor":"김동률","singerEng":"Kim Dongryul","songKor":"아이처럼","songEng":"Like a Child"},
    {"id":734,"singerKor":"김동률","singerEng":"Kim Dongryul","songKor":"이제서야","songEng":"Now_ We"},
    {"id":735,"singerKor":"김동완","singerEng":"Kim Dongwan","songKor":"손수건","songEng":"Handkerchief"},
    {"id":736,"singerKor":"김만수","singerEng":"Kim Mansoo","songKor":"그 사람","songEng":"That Person"},
    {"id":737,"singerKor":"김민석 (멜로망스)","singerEng":"Kim Min-seok (Melomance)","songKor":"취중고백","songEng":"Drunk Confession"},
    {"id":738,"singerKor":"김민석","singerEng":"Kim Minseok","songKor":"취중고백","songEng":"Confession Under the Influence"},
    {"id":739,"singerKor":"김민우","singerEng":"Kim Min Woo","songKor":"사랑일뿐야","songEng":"Love Is Only You."},
    {"id":740,"singerKor":"김민종","singerEng":"Kim Minjong","songKor":"Endless Love","songEng":"Endless Love"},
    {"id":741,"singerKor":"김민종","singerEng":"Kim Minjong","songKor":"You`re My Life","songEng":"You're My Life"},
    {"id":742,"singerKor":"김민종","singerEng":"Kim Minjong","songKor":"또 다른 만남을 위해","songEng":"For Another Meeting."},
    {"id":743,"singerKor":"김민종","singerEng":"Kim Minjong","songKor":"아름다운 아픔","songEng":"Beautiful Pain."},
    {"id":744,"singerKor":"김민종","singerEng":"Kim Min-jong","songKor":"착한 사랑","songEng":"Good Love"},
    {"id":745,"singerKor":"김범룡","singerEng":"Kim Beom-ryong","songKor":"바람 바람 바람","songEng":"Wind Wind Wind"},
    {"id":746,"singerKor":"김범수","singerEng":"Kim Bumsoo","songKor":"Memory","songEng":"Memory"},
    {"id":747,"singerKor":"김범수","singerEng":"Kim Bumsoo","songKor":"가슴에 지는 태양","songEng":"Sunset Glow"},
    {"id":748,"singerKor":"김범수","singerEng":"Kim Bumsoo","songKor":"끝사랑","songEng":"Last Love"},
    {"id":749,"singerKor":"김범수","singerEng":"Kim Bum-soo","songKor":"바보같은 내게","songEng":"Foolish Me"},
    {"id":750,"singerKor":"김범수","singerEng":"Kim Bum-soo","songKor":"보고싶다","songEng":"Missing You"},
    {"id":751,"singerKor":"김범수","singerEng":"Kim Bumsoo","songKor":"사랑의 시작은 고백에서부터","songEng":"Love Begins From Confession"},
    {"id":752,"singerKor":"김범수","singerEng":"Kim Bumsoo","songKor":"슬픔활용법","songEng":"How to Use Sadness"},
    {"id":753,"singerKor":"김범수","singerEng":"Kim Bumsoo.","songKor":"제발 (2000)","songEng":"Please don't go."},
    {"id":754,"singerKor":"김범수","singerEng":"Kim Bum Soo","songKor":"하루 (Original Version)","songEng":"Day."},
    {"id":755,"singerKor":"김보경","singerEng":"Kim Bokyung","songKor":"하루하루","songEng":"Day by Day"},
    {"id":756,"singerKor":"김수철","singerEng":"Kim Soo-chul","songKor":"내일","songEng":"Tomorrow"},
    {"id":757,"singerKor":"김수철","singerEng":"Kim Soo-cheol","songKor":"못다핀 꽃 한 송이","songEng":"One unfilled flower."},
    {"id":758,"singerKor":"김수희","singerEng":"Kim Su-hee","songKor":"애모","songEng":"Amore"},
    {"id":759,"singerKor":"김수희","singerEng":"Kim Su-hee","songKor":"잃어버린 정","songEng":"Lost Memory"},
    {"id":760,"singerKor":"김승민","singerEng":"Kim Seung-min","songKor":"내 기쁨은 너가 벤틀리를 끄는 거야","songEng":"My Joy is Turning Off the Bentley by Kim Yeon-woo"},
    {"id":761,"singerKor":"김승민","singerEng":"Kim Seungmin","songKor":"내?기쁨은?너가?벤틀리를?끄는?거야?(My?pleasure?Is?That?You?Ride?The?Bentley)","songEng":"My Pleasure Is That You Ride The Bentley"},
    {"id":762,"singerKor":"김아중","singerEng":"Kim Ah-jung","songKor":"Maria","songEng":"Maria"},
    {"id":763,"singerKor":"김아중","singerEng":"Kim Ah-joong","songKor":"별 (Original Dialog ver.)","songEng":"Star (Original Dialog ver.)"},
    {"id":764,"singerKor":"김연숙","singerEng":"Kim Yeon-sook","songKor":"그날","songEng":"That Day"},
    {"id":765,"singerKor":"김연숙","singerEng":"Kim Yeonsook","songKor":"나보다 더 나를 사랑하는 님이시여","songEng":"Lord_ You Love Me More Than I Deserve"},
    {"id":766,"singerKor":"김연우","singerEng":"Kim Yeon-woo","songKor":"연인","songEng":"Lover."},
    {"id":767,"singerKor":"김연지","singerEng":"Kim Yeonji","songKor":"가슴으로 운다","songEng":"Cry with My Heart"},
    {"id":768,"singerKor":"김예림 (투개월)","singerEng":"Kim Yerim (Tuca & Bertie)","songKor":"All Right","songEng":"All Right"},
    {"id":769,"singerKor":"김완선","singerEng":"Kim Wansun","songKor":"삐에로는 우릴 보고 웃지","songEng":"Pporappippam"},
    {"id":770,"singerKor":"김완선","singerEng":"Kim Wansun","songKor":"지난 이야기","songEng":"Past Story"},
    {"id":771,"singerKor":"김용준","singerEng":"Kim Yong-joon","songKor":"커플 (Feat. 황정음)","songEng":"Couple (Feat. Hwang Jung-eum)"},
    {"id":772,"singerKor":"김원준","singerEng":"Kim Wonjun","songKor":"부탁","songEng":"Request"},
    {"id":773,"singerKor":"김원중","singerEng":"Kim Won Jung","songKor":"바위섬","songEng":"Rock Island"},
    {"id":774,"singerKor":"김윤아","singerEng":"Kim Yuna","songKor":"夜想曲","songEng":"Nocturne"},
    {"id":775,"singerKor":"김윤아","singerEng":"Kim Yoona","songKor":"봄날은 간다","songEng":"Spring Day"},
    {"id":776,"singerKor":"김윤아","singerEng":"Kim Yuna","songKor":"야상곡","songEng":"Nocturne"},
    {"id":777,"singerKor":"김장훈","singerEng":"Jang-hoon Gim","songKor":"02 미안해","songEng":"Sorry"},
    {"id":778,"singerKor":"김장훈","singerEng":"Kim Jang-hoon","songKor":"난 남자다","songEng":"I'm a Man"},
    {"id":779,"singerKor":"김장훈","singerEng":"Kim Jang-hoon","songKor":"혼잣말","songEng":"Alone"},
    {"id":780,"singerKor":"김재환","singerEng":"Kim Jaehwan","songKor":"어떤 날엔","songEng":"On Certain Days"},
    {"id":781,"singerKor":"김정민","singerEng":"Kim Jung-min","songKor":"정상에서","songEng":"On the Top"},
    {"id":782,"singerKor":"김정수와 급행열차","singerEng":"Kim Jung-Soo and the Express Train","songKor":"내 마음 당신곁으로","songEng":"My Heart Toward You"},
    {"id":783,"singerKor":"김조한","singerEng":"Kim Johhan","songKor":"오늘까지만","songEng":"Only Today"},
    {"id":784,"singerKor":"김종국","singerEng":"Kim Jong Kook","songKor":"Feeling","songEng":"Feeling"},
    {"id":785,"singerKor":"김종국","singerEng":"Kim Jong Kook","songKor":"그녀의 남자에게","songEng":"Her Man"},
    {"id":786,"singerKor":"김종국","singerEng":"Kim Jong Kook","songKor":"제자리 걸음","songEng":"Walking in Place"},
    {"id":787,"singerKor":"김종국","singerEng":"Kim Jong-kook","songKor":"남자니까","songEng":"Because I'm a Man"},
    {"id":788,"singerKor":"김종국","singerEng":"Kim Jong Kook","songKor":"별_ 바람_ 햇살 그리고 사랑","songEng":"Star_ Wind_ Sunlight and Love"},
    {"id":789,"singerKor":"김종국","singerEng":"Kim Jong Kook","songKor":"사랑스러워","songEng":"Lovely"},
    {"id":790,"singerKor":"김종국","singerEng":"Kim Jong Kook","songKor":"사랑한다는 말","songEng":"Words of Love"},
    {"id":791,"singerKor":"김종국","singerEng":"Kim Jong Kook","songKor":"어제보다 오늘 더","songEng":"Yesterday Instead of Today More"},
    {"id":792,"singerKor":"김종국","singerEng":"Kim Jong Kook","songKor":"편지","songEng":"Letter"},
    {"id":793,"singerKor":"김종국","singerEng":"Kim Jong Kook","songKor":"한 남자","songEng":"One Man"},
    {"id":794,"singerKor":"김종국","singerEng":"Kim Jong Kook","songKor":"행복병 (Feat. Shorry J (MM))","songEng":"Happy Virus Feat. Shorry J (MM)"},
    {"id":795,"singerKor":"김종국 ＆ SG 워너비","singerEng":"Kim Jong Kook & SG Wannabe","songKor":"바람만 바람만","songEng":"Wish Wish"},
    {"id":796,"singerKor":"김종서","singerEng":"Kim Jongseo","songKor":"Starry Night","songEng":"Starry Night"},
    {"id":797,"singerKor":"김종서","singerEng":"Kim Jong Seo","songKor":"대답없는 너","songEng":"Answerless You"},
    {"id":798,"singerKor":"김종서","singerEng":"Kim Jongseo","songKor":"아름다운 구속","songEng":"Beautiful Restraint"},
    {"id":799,"singerKor":"김종욱","singerEng":"Kim Jong-uk","songKor":"그대만이 (MBC `우리 결혼했어요` 알렉스&신애 테마곡)","songEng":"Only You"},
    {"id":800,"singerKor":"김진표","singerEng":"Kim Jin Pyo","songKor":"시간을 찾아서 (Feat. 이적)","songEng":"Find the Time (Feat. Lee Juck)"},
    {"id":801,"singerKor":"김태리_남주혁_보나(우주소녀)_최현욱_이주명","singerEng":"Kim Tae-ri_ Nam Joo-hyuk_ Bona (Cosmic Girls)_ Choi Hyun-wook_ Lee Joo-myung","songKor":"With","songEng":"With You"},
    {"id":802,"singerKor":"김태영","singerEng":"Kim Tae-young","songKor":"오랜 방황의 끝","songEng":"End of a Long Wandering."},
    {"id":803,"singerKor":"김태우","singerEng":"Kim Tae-woo","songKor":"사랑비","songEng":"Love Rain"},
    {"id":804,"singerKor":"김태정","singerEng":"Kim Tae-jung","songKor":"기도하는 마음","songEng":"Heart of Prayer"},
    {"id":805,"singerKor":"김필","singerEng":"Kim Pil","songKor":"그때 그 아인","songEng":"At That Time_ I Was..."},
    {"id":806,"singerKor":"김필","singerEng":"Kim Pil","songKor":"청춘 (Feat. 김창완)","songEng":"Youth (Feat. Kim Chang Wan)"},
    {"id":807,"singerKor":"김하온 (HAON)","singerEng":"Haon","songKor":"붕붕 (Feat. Sik-K) (Prod. GroovyRoom)","songEng":"Boong Boong (Feat. Sik-K) (Prod. GroovyRoom)"},
    {"id":808,"singerKor":"김하온 (HAON)_ 이병재 (Vinxen)","singerEng":"Kim Ha-on_ Lee Byung-jae.","songKor":"바코드 (Prod. GroovyRoom)","songEng":"Barcode"},
    {"id":809,"singerKor":"김학래","singerEng":"Kim Hak-rae","songKor":"사랑하면 안되나","songEng":"Cannot love you."},
    {"id":810,"singerKor":"김현성","singerEng":"Kim Hyun-sung","songKor":"Heaven","songEng":"Heaven."},
    {"id":811,"singerKor":"김현성","singerEng":"Kim Hyun Sung","songKor":"이해할께","songEng":"I'll Try."},
    {"id":812,"singerKor":"김현식","singerEng":"Kim Hyun-sik","songKor":"내사랑 내겥에","songEng":"My Love Beside Me"},
    {"id":813,"singerKor":"김현식","singerEng":"Kim Hyun-sik","songKor":"비처럼 음악처럼","songEng":"Rain Like Music."},
    {"id":814,"singerKor":"김현식_권인하_강인원","singerEng":"Kim Hyun-sik_ Kwon In-ha_ Kang In-won.","songKor":"비오는날의 수채화","songEng":"Rainy Day Watercolor."},
    {"id":815,"singerKor":"김현정","singerEng":"Kim Hyun Jung","songKor":"거짓말처럼","songEng":"Lies like a Lie."},
    {"id":816,"singerKor":"김현정","singerEng":"Kim Hyun Jung","songKor":"그녀와의 이별","songEng":"Goodbye with Her"},
    {"id":817,"singerKor":"김현정","singerEng":"Kim Hyun Jung","songKor":"끝이라면","songEng":"If it is the end."},
    {"id":818,"singerKor":"김현정","singerEng":"Kim Hyun Jung","songKor":"떠난너","songEng":"Leaving You"},
    {"id":819,"singerKor":"김현정","singerEng":"Kim Hyun Jung","songKor":"멍","songEng":"Mung"},
    {"id":820,"singerKor":"김현철","singerEng":"Kim Hyun Chul","songKor":"Loving You","songEng":"Loving You"},
    {"id":821,"singerKor":"김현철","singerEng":"Kim Hyun-chul","songKor":"그대니까요 (Duet With 차은주)","songEng":"Because It's You (Duet with Cha Eun-ju)"},
    {"id":822,"singerKor":"김현철","singerEng":"Kim Hyun-chul","songKor":"달의 몰락","songEng":"Moonfall"},
    {"id":823,"singerKor":"김현철_이소라","singerEng":"Kim Hyun-chul_ Lee So-ra","songKor":"그대안의 블루(Duet With 이소라)","songEng":"In Your Eyes Blue (Duet With Lee So-ra)"},
    {"id":824,"singerKor":"김형중","singerEng":"Kim Hyungjoong","songKor":"그녀가 웃잖아","songEng":"She Doesn't Know How to Love by Standing Egg"},
    {"id":825,"singerKor":"나르샤","singerEng":"Narsha","songKor":"삐리빠빠","songEng":"Ppibbipppi."},
    {"id":826,"singerKor":"나몰라패밀리","singerEng":"Namolla Family","songKor":"사랑이 그렇게 쉬워 (Feat. 태인)","songEng":"Love Is So Easy (Feat. Tae In)"},
    {"id":827,"singerKor":"나비","singerEng":"Navi","songKor":"잘 된 일이야","songEng":"Good Thing."},
    {"id":828,"singerKor":"나얼","singerEng":"Naul","songKor":"같은 시간 속의 너","songEng":"At the same time_ you."},
    {"id":829,"singerKor":"나얼","singerEng":"Naul","songKor":"기억의 빈자리","songEng":"Empty Memories"},
    {"id":830,"singerKor":"나얼","singerEng":"Naul","songKor":"바람기억","songEng":"Wind Memory"},
    {"id":831,"singerKor":"나얼","singerEng":"Naul","songKor":"언젠가는","songEng":"Someday"},
    {"id":832,"singerKor":"나연 (TWICE)","singerEng":"Nayeon (TWICE)","songKor":"POP!","songEng":"POP!"},
    {"id":833,"singerKor":"나윤권","singerEng":"Nayoonkwoon","songKor":"기대","songEng":"Expectation"},
    {"id":834,"singerKor":"남궁옥분","singerEng":"Nam Gung-okbun","songKor":"꿈을 먹는 젊은이","songEng":"Dreaming Youth"},
    {"id":835,"singerKor":"내추럴","singerEng":"Naeun","songKor":"그대만의 나이길 (Feat. 김연우)","songEng":"Only You (Feat. Kim Yeon Woo)"},
    {"id":836,"singerKor":"넉살_ 한해_ 라이노_ 조우찬","singerEng":"Nucksal_ Hanhae_ Rhino_ Jo Woochan","songKor":"N분의1 (Feat. 다이나믹듀오)","songEng":"Ndivisible1 (Feat. Dynamic Duo)"},
    {"id":837,"singerKor":"넥스트","singerEng":"Next","songKor":"Growing Up","songEng":"Growing Up"},
    {"id":838,"singerKor":"넥스트","singerEng":"Next","songKor":"도시인","songEng":"Urban Person"},
    {"id":839,"singerKor":"넬","singerEng":"Nell","songKor":"Stay","songEng":"Stay"},
    {"id":840,"singerKor":"넬","singerEng":"Nell","songKor":"기억을 걷는 시간","songEng":"Walking in the Memory."},
    {"id":841,"singerKor":"노래를 찾는 사람들","singerEng":"Sorry_ as an AI language model_ I am unable to identify the Korean artist in the given sentence as it is a statement_ not a name. Please provide me with the correct name of the artist.","songKor":"사계","songEng":"Four Seasons"},
    {"id":842,"singerKor":"노블레스 (Noblesse)","singerEng":"Noblesse","songKor":"후회는 없어 (Feat. 베이지)","songEng":"No Regrets (Feat. Beige)"},
    {"id":843,"singerKor":"노을","singerEng":"Noel","songKor":"그대가 있기에","songEng":"Because of You"},
    {"id":844,"singerKor":"노을","singerEng":"Noel","songKor":"그리워 그리워","songEng":"Longing Longing."},
    {"id":845,"singerKor":"노을","singerEng":"Noel","songKor":"너는 어땠을까","songEng":"What If."},
    {"id":846,"singerKor":"노을","singerEng":"Noeul","songKor":"늦은 밤 너의 집 앞 골목길에서","songEng":"Late Night by TAEYEON"},
    {"id":847,"singerKor":"노을","singerEng":"Noel","songKor":"떠나간다","songEng":"Leaving."},
    {"id":848,"singerKor":"노을","singerEng":"Noeul","songKor":"만약에 말야 (전우성 Solo)","songEng":"If You"},
    {"id":849,"singerKor":"노을","singerEng":"Noel","songKor":"보이지 않는 것들","songEng":"Invisible Things"},
    {"id":850,"singerKor":"노을","singerEng":"Noel","songKor":"인연","songEng":"Fate"},
    {"id":851,"singerKor":"노을","singerEng":"Noel","songKor":"잊을 수 있을까","songEng":"Can't Forget You"},
    {"id":852,"singerKor":"노을","singerEng":"Noel","songKor":"전부 너였다","songEng":"All of You Were Mine"},
    {"id":853,"singerKor":"노을","singerEng":"Noel","songKor":"하지 못한 말","songEng":"Unspoken Words"},
    {"id":854,"singerKor":"노을","singerEng":"Noel","songKor":"함께","songEng":"Together"},
    {"id":855,"singerKor":"노이즈","singerEng":"Noise","songKor":"상상속의 너","songEng":"Imagination by Shawn Mendes"},
    {"id":856,"singerKor":"녹색지대","singerEng":"Green Zone","songKor":"괜찮아","songEng":"It's Alright"},
    {"id":857,"singerKor":"녹색지대","singerEng":"Noksaek Jidae","songKor":"준비없","songEng":"Unplanned."},
    {"id":858,"singerKor":"높은음자리","singerEng":"Noh Ji Hoon","songKor":"나 그리고 별","songEng":"I and the Star."},
    {"id":859,"singerKor":"닐로 (Nilo)","singerEng":"Nilo","songKor":"넋두리","songEng":"Mind Eraser"},
    {"id":860,"singerKor":"닐로 (Nilo)","singerEng":"Nilo","songKor":"지나오다","songEng":"Passing By"},
    {"id":861,"singerKor":"닐로","singerEng":"Nilro","songKor":"지나오다","songEng":"Passing By"},
    {"id":862,"singerKor":"다비치","singerEng":"Davichi","songKor":"매일 크리스마스 (Everyday Christmas)","songEng":"Everyday Christmas"},
    {"id":863,"singerKor":"다비치 (Davici)","singerEng":"Davichi","songKor":"사랑과 전쟁 (Narr. 하하)","songEng":"Love and War (Narr. Haha)"},
    {"id":864,"singerKor":"다비치","singerEng":"Davichi","songKor":"8282","songEng":"8282"},
    {"id":865,"singerKor":"다비치","singerEng":"Davichi","songKor":"My Man","songEng":"My Man."},
    {"id":866,"singerKor":"다비치","singerEng":"Davichi","songKor":"거북이","songEng":"Turtle"},
    {"id":867,"singerKor":"다비치","singerEng":"Davichi","songKor":"괜찮아 사랑이야","songEng":"It's Okay_ That's Love"},
    {"id":868,"singerKor":"다비치","singerEng":"Davichi","songKor":"그냥 안아달란 말야","songEng":"Just Hold Me Tight"},
    {"id":869,"singerKor":"다비치","singerEng":"Davichi","songKor":"나의 오랜 연인에게","songEng":"My Old Story"},
    {"id":870,"singerKor":"다비치","singerEng":"Davichi","songKor":"내 옆에 그대인 걸","songEng":"You're next to me."},
    {"id":871,"singerKor":"다비치","singerEng":"Davichi","songKor":"녹는 중 (feat. 버벌진트)","songEng":"Melting (feat. Verbal Jint)"},
    {"id":872,"singerKor":"다비치","singerEng":"Davichi","songKor":"두사랑 (Feat. 매드클라운)","songEng":"Two Love (Feat. Mad Clown)"},
    {"id":873,"singerKor":"다비치","singerEng":"Davichi","songKor":"둘이서 한잔해","songEng":"Two People Drinking Together"},
    {"id":874,"singerKor":"다비치","singerEng":"Davichi","songKor":"또 운다 또","songEng":"Again and Again"},
    {"id":875,"singerKor":"다비치","singerEng":"Davichi","songKor":"사랑과 전쟁 (Feat. 하하)","songEng":"Love and War (Feat. Haha)"},
    {"id":876,"singerKor":"다비치","singerEng":"Davichi","songKor":"생각날거야","songEng":"Remember Me"},
    {"id":877,"singerKor":"다비치","singerEng":"Davichi","songKor":"슬픈 다짐 (Remix)","songEng":"Sad Promise Remix"},
    {"id":878,"singerKor":"다비치","singerEng":"Davichi","songKor":"시간아 멈춰라","songEng":"Timeless"},
    {"id":879,"singerKor":"다비치","singerEng":"Davichi","songKor":"안녕이라고 말하지마","songEng":"Do not say any other word than the song name."},
    {"id":880,"singerKor":"다비치","singerEng":"Davichi","songKor":"오늘따라 보고싶어서 그래","songEng":"Today I Miss You"},
    {"id":881,"singerKor":"다비치","singerEng":"Davichi","songKor":"이 사랑","songEng":"This Love"},
    {"id":882,"singerKor":"다비치","singerEng":"Davichi","songKor":"팡파레","songEng":"PangPare"},
    {"id":883,"singerKor":"다비치","singerEng":"Davichi","songKor":"편지","songEng":"Letter."},
    {"id":884,"singerKor":"다비치","singerEng":"Davichi","songKor":"행복해서 미안해","songEng":"Sorry I'm Happy"},
    {"id":885,"singerKor":"다비치","singerEng":"Davichi","songKor":"헤어졌다 만났다","songEng":"We Broke Up_ We Met Again"},
    {"id":886,"singerKor":"다비치_ 티아라","singerEng":"Davichi_ T-ara","songKor":"우리 사랑했잖아","songEng":"We Were in Love"},
    {"id":887,"singerKor":"다이나믹 듀오","singerEng":"Dynamic Duo","songKor":"BAAAM (Feat. Muzie Of UV)","songEng":"BAAM (Feat. Muzie Of UV)"},
    {"id":888,"singerKor":"다이나믹 듀오","singerEng":"Dynamic Duo","songKor":"죽일 놈 (Guilty)","songEng":"Guilty"},
    {"id":889,"singerKor":"다이나믹 듀오(Dynamic Duo)","singerEng":"Dynamic Duo","songKor":"Ring My Bell (feat. 나얼 Of Brown Eyed Soul)","songEng":"Ring My Bell (feat. 나얼 Of Brown Eyed Soul)"},
    {"id":890,"singerKor":"다이나믹 듀오_ 첸 (CHEN)","singerEng":"Dynamic Duo_ Chen (CHEN)","songKor":"기다렸다 가","songEng":"Wait a Minute"},
    {"id":891,"singerKor":"달샤벳(Dal★shabet)","singerEng":"Dal Shabet","songKor":"Supa Dupa Diva","songEng":"Supa Dupa Diva"},
    {"id":892,"singerKor":"대성","singerEng":"Daesung","songKor":"날 봐_ 귀순","songEng":"Look at Me_ Gwi Soon"},
    {"id":893,"singerKor":"댄싱게놈 (유재석_ JYP)","singerEng":"Dancing Genome (Yoo Jae-suk_ JYP)","songKor":"I'm So Sexy","songEng":"I'm So Sexy"},
    {"id":894,"singerKor":"더 네임","singerEng":"The Name","songKor":"사랑은","songEng":"Love Is"},
    {"id":895,"singerKor":"더 크로스","singerEng":"The Cross","songKor":"떠나가요_ 떠나지마요","songEng":"Don't Leave Me_ Don't Go."},
    {"id":896,"singerKor":"던말릭","singerEng":"Dunmalik","songKor":"눈 (EYE) (Feat. BIG Naughty_ 저스디스 (JUSTHIS)) (Prod. R.Tee)","songEng":"Eye (Feat. BIG Naughty_ JUSTHIS) (Prod. R.Tee)"},
    {"id":897,"singerKor":"던말릭","singerEng":"Don Malik","songKor":"빡 (Feat. 팔로알토 (Paloalto)_ 저스디스 (JUSTHIS)) (Prod. R.Tee)","songEng":"Bbak (Feat. Paloalto_ JUSTHIS) (Prod. R.Tee)"},
    {"id":898,"singerKor":"던말릭","singerEng":"Don Malik","songKor":"눈 (EYE) (Feat. BIG Naughty_ 저스디스 (JUSTHIS)) (Prod. R.Tee)","songEng":"EYE (Feat. BIG Naughty_ JUSTHIS) (Prod. R.Tee)"},
    {"id":899,"singerKor":"데프콘","singerEng":"Defconn.","songKor":"길","songEng":"Road."},
    {"id":900,"singerKor":"델리스파이스","singerEng":"Delispice","songKor":"고백","songEng":"Confession"},
    {"id":901,"singerKor":"동그라미","singerEng":"Donggurami","songKor":"같이 있게 해주세요","songEng":"Please Don't Go_ My Girl"},
    {"id":902,"singerKor":"동방신기","singerEng":"TVXQ","songKor":"Hi Ya Ya 여름날","songEng":"Summer Day."},
    {"id":903,"singerKor":"동방신기","singerEng":"TVXQ","songKor":"HUG (포옹)","songEng":"HUG"},
    {"id":904,"singerKor":"동방신기","singerEng":"TVXQ","songKor":"Rising Sun (순수)","songEng":"Rising Sun"},
    {"id":905,"singerKor":"동방신기","singerEng":"TVXQ","songKor":"The Way U Are(Extended Ver.)","songEng":"The Way U Are (Extended Ver)"},
    {"id":906,"singerKor":"동방신기","singerEng":"TVXQ","songKor":"믿어요","songEng":"Believe"},
    {"id":907,"singerKor":"동방신기","singerEng":"TVXQ","songKor":"왜 (Keep Your Head Down)","songEng":"Keep Your Head Down"},
    {"id":908,"singerKor":"동방신기","singerEng":"TVXQ","songKor":"주문 (MIROTIC)","songEng":"Mirotic"},
    {"id":909,"singerKor":"동방신기(東方神起)","singerEng":"TVXQ","songKor":"My Little Princess (있잖아요)","songEng":"My Little Princess (있잖아요)"},
    {"id":910,"singerKor":"드렁큰 타이거","singerEng":"드렁큰 타이거","songKor":"남자기 때문에","songEng":"Because of the Man"},
    {"id":911,"singerKor":"드렁큰 타이거","singerEng":"Tiger JK","songKor":"Good Life","songEng":"Good Life"},
    {"id":912,"singerKor":"디셈버","singerEng":"December","songKor":"She`s Gone","songEng":"She's Gone"},
    {"id":913,"singerKor":"디핵_ PATEKO","singerEng":"디핵 PATEKO","songKor":"OHAYO MY NIGHT","songEng":"Ohayo My Night"},
    {"id":914,"singerKor":"디핵_ PATEKO","singerEng":"Dihack_ Pateko","songKor":"OHAYO MY NIGHT","songEng":"Ohayo My Night"},
    {"id":915,"singerKor":"디핵_ PATEKO","singerEng":"디핵 PATEKO","songKor":"OHAYO MY NIGHT","songEng":"Ohayo My Night"},
    {"id":916,"singerKor":"뜨거운 감자","singerEng":"Hot Potato","songKor":"고백","songEng":"Confession"},
    {"id":917,"singerKor":"라붐 (LABOUM)","singerEng":"LABOUM","songKor":"상상더하기","songEng":"Imagination"},
    {"id":918,"singerKor":"란","singerEng":"Ran","songKor":"우리처음 (1대 란 (예인) & 2대","songEng":"Our First (1st Gen (Yein) & 2nd Gen)"},
    {"id":919,"singerKor":"러브홀릭","singerEng":"Loveholic","songKor":"Loveholic","songEng":"Loveholic"},
    {"id":920,"singerKor":"러브홀릭","singerEng":"Loveholic","songKor":"Sky","songEng":"Sky"},
    {"id":921,"singerKor":"러브홀릭","singerEng":"Loveholic","songKor":"놀러와","songEng":"Come Over."},
    {"id":922,"singerKor":"러블리즈","singerEng":"Lovelyz","songKor":"Ah-Choo","songEng":"Ah-Choo"},
    {"id":923,"singerKor":"러블리즈","singerEng":"Lovelyz","songKor":"안녕 (Hi~)","songEng":"Hi~"},
    {"id":924,"singerKor":"럼블피쉬","singerEng":"Rumble Fish","songKor":"I Go","songEng":"I Go"},
    {"id":925,"singerKor":"럼블피쉬","singerEng":"Rumble Fish","songKor":"비와 당신 (영화 '라디오 스타 '삽입곡)","songEng":"Rain and You (Insertion Song of the Film 'Radio Star')"},
    {"id":926,"singerKor":"럼블피쉬","singerEng":"Rumblefish","songKor":"예감 좋은 날","songEng":"Good Day"},
    {"id":927,"singerKor":"럼블피쉬","singerEng":"Rumblefish","songKor":"으라차차","songEng":"Urikiri"},
    {"id":928,"singerKor":"럼블피쉬","singerEng":"Rumble Fish","songKor":"한사람을 위한 마음","songEng":"For You in Full Blossom"},
    {"id":929,"singerKor":"레이디스 코드","singerEng":"Ladies Code","songKor":"예뻐 예뻐","songEng":"Beautiful Beautiful"},
    {"id":930,"singerKor":"레인보우","singerEng":"Rainbow","songKor":"A","songEng":"A"},
    {"id":931,"singerKor":"레인보우(Rainbow)","singerEng":"Rainbow","songKor":"To Me (내게로..)","songEng":"To Me (내게로)"},
    {"id":932,"singerKor":"렉시","singerEng":"Lexy","songKor":"눈물 씻고 화장하고","songEng":"Wash Your Tears and Put on Makeup"},
    {"id":933,"singerKor":"렉시","singerEng":"Lexi","songKor":"애송이","songEng":"Aesongi"},
    {"id":934,"singerKor":"렉시","singerEng":"Lexi","songKor":"하늘위로 (Feat. 김지은)","songEng":"Up Above the Sky (Feat. Kim Ji-eun)"},
    {"id":935,"singerKor":"로꼬 & 화사 (Hwa Sa)","singerEng":"Loco & Hwasa","songKor":"Somebody!","songEng":"Somebody"},
    {"id":936,"singerKor":"로꼬 & 화사 (마마무)","singerEng":"Loco & Hwasa (Mamamoo)","songKor":"주지마","songEng":"Do Not Offer"},
    {"id":937,"singerKor":"로꼬 (LOCO)","singerEng":"LOCO","songKor":"감아 (Hold Me Tight) (Feat. Crush)","songEng":"Hold Me Tight (Feat. Crush)"},
    {"id":938,"singerKor":"로꼬","singerEng":"Loco","songKor":"시간이 들겠지 (Feat. Colde)","songEng":"Time Goes By (Feat. Colde)"},
    {"id":939,"singerKor":"로꼬_ 유주 (여자친구)","singerEng":"Loco_ Yuju (GFRIEND)","songKor":"우연히 봄","songEng":"Coincidence Spring"},
    {"id":940,"singerKor":"로이킴 & 정준영","singerEng":"Roy Kim & Jung Joon Young","songKor":"먼지가 되어","songEng":"Dust To Dust"},
    {"id":941,"singerKor":"로이킴","singerEng":"Roy Kim","songKor":"Love Love Love","songEng":"Love Love Love"},
    {"id":942,"singerKor":"로이킴","singerEng":"Roy Kim","songKor":"그때 헤어지면 돼","songEng":"You Just Need to Walk Away"},
    {"id":943,"singerKor":"로이킴","singerEng":"Roy Kim","songKor":"봄봄봄","songEng":"Spring Spring Spring"},
    {"id":944,"singerKor":"로이킴","singerEng":"Roy Kim","songKor":"우리 그만하자 (The Hardest Part)","songEng":"The Hardest Part"},
    {"id":945,"singerKor":"로이킴","singerEng":"Roy Kim","songKor":"휘파람","songEng":"Whistle"},
    {"id":946,"singerKor":"로제 (ROSE)","singerEng":"Ros?","songKor":"On The Ground","songEng":"On The Ground"},
    {"id":947,"singerKor":"롤러코스터(Roller Coaster)","singerEng":"Roller Coaster","songKor":"04. Last scene","songEng":"Last Scene"},
    {"id":948,"singerKor":"롤러코스터(Roller Coaster)","singerEng":"Roller Coaster","songKor":"203 힘을내요 미스터 김","songEng":"Be Strong Mr. Kim"},
    {"id":949,"singerKor":"롤러코스터(Roller Coaster)","singerEng":"Roller Coaster","songKor":"무지개","songEng":"Rainbow"},
    {"id":950,"singerKor":"룰라","singerEng":"Roola","songKor":"풍변기곡(風變旗曲)","songEng":"Wind Variation Flag Song"},
    {"id":951,"singerKor":"류","singerEng":"Ryu","songKor":"처음부터 지금까지","songEng":"From Beginning Until Now"},
    {"id":952,"singerKor":"리사","singerEng":"Lisa","songKor":"다 괜찮아요","songEng":"Everything's Alright"},
    {"id":953,"singerKor":"리사","singerEng":"Lisa","songKor":"헤어져야 사랑을 알죠","songEng":"We must break up to find love"},
    {"id":954,"singerKor":"리쌍","singerEng":"Leessang","songKor":"Ballerino (with ALI)","songEng":"Ballerino"},
    {"id":955,"singerKor":"리쌍","singerEng":"Leessang","songKor":"Fly High (Feat. 정인_ 하림(河琳))","songEng":"Fly High (Feat. 정인_ 하림(河琳))"},
    {"id":956,"singerKor":"리쌍","singerEng":"Leessang","songKor":"Grand Final (Feat. 정인_ MC 날유)","songEng":"Grand Final (Feat. Jung In_ MC Nal Yoo)"},
    {"id":957,"singerKor":"리쌍","singerEng":"Leessang","songKor":"TV를 껐네... (Feat. 권정열 Of 10cm_ t윤미래)","songEng":"Shut Down (Feat. Kwon Jung Yeol Of 10cm_ tYoonmirae)"},
    {"id":958,"singerKor":"리쌍","singerEng":"Leessang","songKor":"사랑은 (SONG.정인) (HIDDEN TRACK)","songEng":"Love Affair (SONG. Jung In) (HIDDEN TRACK)"},
    {"id":959,"singerKor":"리쌍","singerEng":"Leessang","songKor":"회상 (Feat. 백지영)","songEng":"Recollection (Feat. Baek Ji-young)"},
    {"id":960,"singerKor":"리쌍_ 유진 (더 씨야)","singerEng":"Leessang_ Yoojin (The SeeYa)","songKor":"눈물","songEng":"Tears"},
    {"id":961,"singerKor":"리쌍_ 정인","singerEng":"Leessang_ Jung In","songKor":"헤어지지 못하는 여자_ 떠나가지 못하는 남자","songEng":"Couple"},
    {"id":962,"singerKor":"리치","singerEng":"Rich","songKor":"사랑해 이 말밖엔","songEng":"I Love You This Much."},
    {"id":963,"singerKor":"리치","singerEng":"Rich","songKor":"오늘부터","songEng":"From Today"},
    {"id":964,"singerKor":"린","singerEng":"Lynn","songKor":"My Destiny","songEng":"My Destiny"},
    {"id":965,"singerKor":"린","singerEng":"Rin","songKor":"사랑... 다 거짓말","songEng":"Love... Lies."},
    {"id":966,"singerKor":"린","singerEng":"Lynn","songKor":"오늘 밤 (Feat. 배치기)","songEng":"Tonight by Baechigi"},
    {"id":967,"singerKor":"린","singerEng":"Lyn","songKor":"이별살이","songEng":"Goodbye to Romance"},
    {"id":968,"singerKor":"린(LYn)","singerEng":"LYn","songKor":"날 위한 이별","songEng":"Goodbye for Me."},
    {"id":969,"singerKor":"린(LYn)","singerEng":"LYn","songKor":"사랑했잖아","songEng":"Love Scenario"},
    {"id":970,"singerKor":"린애","singerEng":"LinAe","songKor":"이별후애(愛)","songEng":"Afterlove (Love)"},
    {"id":971,"singerKor":"릴보이 (lIlBOI)","singerEng":"LilBoi","songKor":"CREDIT (Feat. 염따 & 기리보이 & Zion.T)","songEng":"Credit"},
    {"id":972,"singerKor":"마골피","singerEng":"Maggie","songKor":"비행소녀","songEng":"Flying Girl"},
    {"id":973,"singerKor":"마로니에","singerEng":"Maronie","songKor":"칵테일 사랑","songEng":"Cocktail Love"},
    {"id":974,"singerKor":"마마무 (Mamamoo)","singerEng":"MAMAMOO","songKor":"고고베베 (gogobebe)","songEng":"Gogobebe."},
    {"id":975,"singerKor":"마마무 (Mamamoo)","singerEng":"MAMAMOO","songKor":"딩가딩가 (Dingga)","songEng":"Dingga"},
    {"id":976,"singerKor":"마마무","singerEng":"MAMAMOO","songKor":"Decalcomanie (데칼코마니)","songEng":"Decalcomanie"},
    {"id":977,"singerKor":"마마무","singerEng":"MAMAMOO","songKor":"I Miss You","songEng":"I Miss You"},
    {"id":978,"singerKor":"마마무","singerEng":"MAMAMOO","songKor":"넌 is 뭔들","songEng":"You Are So Diffet"},
    {"id":979,"singerKor":"마마무","singerEng":"MAMAMOO","songKor":"음오아예 (Um Oh Ah Yeh)","songEng":"Um Oh Ah Yeh"},
    {"id":980,"singerKor":"마마무(Mamamoo)","singerEng":"MAMAMOO","songKor":"HIP","songEng":"Hip"},
    {"id":981,"singerKor":"마야","singerEng":"Maya","songKor":"나를 외치다","songEng":"Shout out to Me."},
    {"id":982,"singerKor":"마야","singerEng":"Maya","songKor":"진달래꽃","songEng":"Azalea."},
    {"id":983,"singerKor":"마이티 마우스","singerEng":"Mighty Mouse","songKor":"나쁜놈 (Feat. 소야)","songEng":"Bad Guy (Feat. Soya)"},
    {"id":984,"singerKor":"마이티 마우스","singerEng":"Mighty Mouse","songKor":"랄랄라 (Feat. 소야)","songEng":"Lalala (Feat. Soya)"},
    {"id":985,"singerKor":"마이티 마우스","singerEng":"Mighty Mouse","songKor":"에너지 (Feat. 선예)","songEng":"Energy (Feat. Sunye)"},
    {"id":986,"singerKor":"마이티 마우스","singerEng":"Mighty Mouse","songKor":"연애특강 (Feat.한예슬)","songEng":"Dating Class (Feat. Han Ye-seul)"},
    {"id":987,"singerKor":"마이티 마우스","singerEng":"Mighty Mouse","songKor":"톡톡 (Tok Tok) (Feat. SOYA)","songEng":"Tok Tok (Feat. SOYA)"},
    {"id":988,"singerKor":"마이티 마우스(Mighty Mouth)","singerEng":"Mighty Mouth","songKor":"05 사랑해 (Feat. Yoon Eun Hye)","songEng":"Love (Feat. Yoon Eun Hye)"},
    {"id":989,"singerKor":"마크툽 (MAKTUB)","singerEng":"Maktub","songKor":"마음이 말하는 행복 (Happiness) (Feat.이라온)","songEng":"Happiness (Feat.이라온)"},
    {"id":990,"singerKor":"마크툽 (MAKTUB)","singerEng":"MAKTUB","songKor":"오늘도 빛나는 너에게 (To You My Light) (Feat.이라온)","songEng":"To You My Light (Feat.이라온)"},
    {"id":991,"singerKor":"마크툽 (MAKTUB)","singerEng":"MAKTUB","songKor":"찰나가 영원이 될 때 (The Eternal Moment)","songEng":"The Eternal Moment"},
    {"id":992,"singerKor":"마크툽 (MAKTUB)_ Lee Raon","singerEng":"MAKTUB_ Lee Raon","songKor":"오늘도 빛나는 너에게 (To You My Light (feat. Lee Raon))","songEng":"To You My Light (feat. Lee Raon)"},
    {"id":993,"singerKor":"마크툽 (MAKTUB)_ 구윤회","singerEng":"MAKTUB_ GUYOUNGHWAE","songKor":"Marry Me","songEng":"Marry Me"},
    {"id":994,"singerKor":"마크툽","singerEng":"MarkTuan","songKor":"찰나가 영원이 될 때 (The Eternal Moment)","songEng":"The Eternal Moment"},
    {"id":995,"singerKor":"매드클라운","singerEng":"Mad Clown","songKor":"견딜만해 (Feat. 효린 Of 씨스타)","songEng":"Endure (Feat. Hyolyn Of Sistar)"},
    {"id":996,"singerKor":"매드클라운","singerEng":"Mad Clown","songKor":"우리집을 못 찾겠군요 (Feat. 볼빨간사춘기)","songEng":"I'm Sorry I Couldn't Find Our House (Feat. Bolbbalgan4)"},
    {"id":997,"singerKor":"매드클라운_ 김나영","singerEng":"Mad Clown_ Kim Na-Young","songKor":"다시 너를","songEng":"Again You"},
    {"id":998,"singerKor":"먼데이 키즈 (Monday Kiz)","singerEng":"Monday Kiz","songKor":"가을 안부","songEng":"Autumn Farewell."},
    {"id":999,"singerKor":"먼데이 키즈 (Monday Kiz)","singerEng":"Monday Kiz","songKor":"너 아니면 안돼","songEng":"You're the One I Need"},
    {"id":1000,"singerKor":"먼데이 키즈 (Monday Kiz)","singerEng":"Monday Kiz","songKor":"흰눈","songEng":"White Snow"},
    {"id":1001,"singerKor":"먼데이 키즈","singerEng":"Monday Kiz","songKor":"Never Say Goodbye","songEng":"Never Say Goodbye"},
    {"id":1002,"singerKor":"먼데이 키즈","singerEng":"Monday Kiz","songKor":"가슴으로 외쳐 (feat. Rhymer)","songEng":"Shout with your heart (feat. Rhymer)"},
    {"id":1003,"singerKor":"먼데이 키즈","singerEng":"Monday Kiz","songKor":"남자야","songEng":"Man."},
    {"id":1004,"singerKor":"먼데이 키즈","singerEng":"Monday Kiz","songKor":"발자국","songEng":"Footsteps"},
    {"id":1005,"singerKor":"먼데이 키즈","singerEng":"Monday Kiz","songKor":"사랑이 식었다고 말해도 돼","songEng":"You Are Allowed to Say Love Has Cooled"},
    {"id":1006,"singerKor":"먼데이 키즈","singerEng":"Monday Kiz","songKor":"흩어져","songEng":"Scattered."},
    {"id":1007,"singerKor":"먼데이 키즈(Monday Kiz)","singerEng":"Monday Kiz","songKor":"너를 보고 싶다_ 널 안고 싶다 (사귄 건 아닌데 X 먼데이 키즈 (Monday Kiz))","songEng":"I Miss You_ I Want to Hug You (Not Dating X Monday Kiz)"},
    {"id":1008,"singerKor":"먼데이 키즈(Monday Kiz)","singerEng":"Monday Kiz","songKor":"흰눈","songEng":"White Snow"},
    {"id":1009,"singerKor":"먼데이키즈(Mondaykiz)","singerEng":"Monday Kiz","songKor":"Bye Bye Bye","songEng":"Bye Bye Bye"},
    {"id":1010,"singerKor":"메이비","singerEng":"Maybe","songKor":"다소","songEng":"Daso"},
    {"id":1011,"singerKor":"멜로망스 (MeloMance)","singerEng":"MeloMance","songKor":"You","songEng":"You by BTS."},
    {"id":1012,"singerKor":"멜로망스 (MeloMance)","singerEng":"MeloMance","songKor":"고백","songEng":"Confession"},
    {"id":1013,"singerKor":"멜로망스 (MeloMance)","singerEng":"MeloMance","songKor":"그게 더 편할 것 같아 (N번째 연애 X 멜로망스)","songEng":"Maybe This Time by MeloMance"},
    {"id":1014,"singerKor":"멜로망스 (MeloMance)","singerEng":"MeloMance","songKor":"사랑인가 봐","songEng":"Love Must Be The Answer"},
    {"id":1015,"singerKor":"멜로망스","singerEng":"Melomance","songKor":"사랑인가 봐","songEng":"Love must be it"},
    {"id":1016,"singerKor":"멜로망스","singerEng":"Melomance","songKor":"고백","songEng":"Confession"},
    {"id":1017,"singerKor":"멜로망스","singerEng":"Melomance","songKor":"동화","songEng":"Fairytale"},
    {"id":1018,"singerKor":"멜로망스","singerEng":"Melomance","songKor":"사랑인가 봐","songEng":"Love Must Be the Reason."},
    {"id":1019,"singerKor":"멜로망스","singerEng":"Melomance","songKor":"선물","songEng":"Gift"},
    {"id":1020,"singerKor":"멜로망스","singerEng":"Melomance","songKor":"욕심","songEng":"Greed"},
    {"id":1021,"singerKor":"멜로망스(MeloMance)","singerEng":"MeloMance","songKor":"사랑인가 봐","songEng":"Love Must Be The Answer"},
    {"id":1022,"singerKor":"멜로망스(MeloMance)","singerEng":"MeloMance","songKor":"초대","songEng":"Invitation"},
    {"id":1023,"singerKor":"모모랜드 (MOMOLAND)","singerEng":"MOMOLAND","songKor":"뿜뿜","songEng":"Bboom Bboom"},
    {"id":1024,"singerKor":"무한도전","singerEng":"Infinite Challenge","songKor":"키 작은 꼬마","songEng":"Small Child with Short Height"},
    {"id":1025,"singerKor":"문근영","singerEng":"Moon Geun-young","songKor":"&Design","songEng":"Butter."},
    {"id":1026,"singerKor":"문문 (MoonMoon)","singerEng":"MoonMoon","songKor":"비행운","songEng":"Lucky."},
    {"id":1027,"singerKor":"문차일드","singerEng":"Moonchild","songKor":"08. 사랑하니까 ...문차일드","songEng":"Because I Love You ...Mun ChaeWon"},
    {"id":1028,"singerKor":"문희준","singerEng":"Moon Hee-joon","songKor":"아낌없이 주는 나무 (Generous...)","songEng":"Generous Tree"},
    {"id":1029,"singerKor":"미도와 파라솔","singerEng":"Mido and Parasol","songKor":"너에게 난_ 나에게 넌","songEng":"You and Me"},
    {"id":1030,"singerKor":"미란이_ 먼치맨_ KHUNDI PANDA_ 머쉬베놈","singerEng":"Mirani_ Meonchiman_ Khundi Panda_ Mushvenom.","songKor":"VVS (Feat. JUSTHIS) (Prod. GroovyRoom)","songEng":"VVS"},
    {"id":1031,"singerKor":"미스 에이(miss A)","singerEng":"Miss A","songKor":"Bad Girl Good Girl","songEng":"Bad Girl Good Girl"},
    {"id":1032,"singerKor":"미쓰에이","singerEng":"Miss A","songKor":"Hush","songEng":"Hush"},
    {"id":1033,"singerKor":"미쓰에이","singerEng":"Miss A","songKor":"남자 없이 잘 살아","songEng":"Without a Man."},
    {"id":1034,"singerKor":"민해경","singerEng":"Min Hae-kyung.","songKor":"보고싶은 얼굴","songEng":"Missing You"},
    {"id":1035,"singerKor":"민해경","singerEng":"Min Hae-kyung","songKor":"사랑은 이제 그만","songEng":"Love is Over Now"},
    {"id":1036,"singerKor":"바이브 (Vibe)","singerEng":"Vibe","songKor":"한숨만","songEng":"Sigh"},
    {"id":1037,"singerKor":"바이브","singerEng":"Vibe","songKor":"가을 타나 봐","songEng":"Autumn leaves are falling."},
    {"id":1038,"singerKor":"바이브","singerEng":"Vibe","songKor":"그 남자 그 여자 (Feat. 장혜진)","songEng":"That Man That Woman (Feat. Jang Hyejin)"},
    {"id":1039,"singerKor":"바이브","singerEng":"Vibe","songKor":"다시 와주라","songEng":"Come Back Again"},
    {"id":1040,"singerKor":"바이브","singerEng":"Vibe","songKor":"미워도 다시 한번 (Original Ver.)","songEng":"Hate You Again (Original Ver)"},
    {"id":1041,"singerKor":"바이브","singerEng":"Bibe","songKor":"오래오래","songEng":"Forever Forever"},
    {"id":1042,"singerKor":"박경 (블락비)","singerEng":"Park Kyung (Block B)","songKor":"보통연애 (Feat. 박보람)","songEng":"Ordinary Love (Feat. Park Boram)"},
    {"id":1043,"singerKor":"박경 (블락비)","singerEng":"Park Kyung (Block B)","songKor":"자격지심 (Feat. 은하 Of 여자친구)","songEng":"Inferiority Complex (Feat. Eunha Of GFRIEND)"},
    {"id":1044,"singerKor":"박경림","singerEng":"Park Kyung-rim","songKor":"착각의 늪","songEng":"Swamp of Despair"},
    {"id":1045,"singerKor":"박기영","singerEng":"Park Ki Young","songKor":"Blue Sky","songEng":"Blue Sky."},
    {"id":1046,"singerKor":"박명수","singerEng":"Park Myung Soo","songKor":"명수네 떡볶이 (Feat. 김예림_ UL)","songEng":"Myeongsu's Tteokbokki (Feat. Kim Ye-rim_ UL)"},
    {"id":1047,"singerKor":"박명수","singerEng":"Park Myung Soo","songKor":"바보에게… 바보가","songEng":"Foolish Love by Bolbbalgan4"},
    {"id":1048,"singerKor":"박보람","singerEng":"Park Boram","songKor":"예뻐졌다 (Feat. 지코 Of Block B)","songEng":"Beautiful (Feat. Zico Of Block B)"},
    {"id":1049,"singerKor":"박봄","singerEng":"Park Bom","songKor":"봄 (Feat. 산다라박)","songEng":"Spring (Feat. Sandara Park)"},
    {"id":1050,"singerKor":"박봄 (Park Bom)","singerEng":"Park Bom","songKor":"꽃 (With 김민석 of 멜로망스)","songEng":"Flower (With Kim Min-seok of MeloMance)"},
    {"id":1051,"singerKor":"박봄 (Park Bom)","singerEng":"Park Bom","songKor":"봄 (feat. 산다라박)","songEng":"Spring (feat. Sandara Park)"},
    {"id":1052,"singerKor":"박봄","singerEng":"Park Bom","songKor":"Anystar(feat.G-Dragon & Gummy)","songEng":"Anystar"},
    {"id":1053,"singerKor":"박봄","singerEng":"Park Bom","songKor":"DON`T CRY","songEng":"Don't Cry"},
    {"id":1054,"singerKor":"박봄","singerEng":"Park Bom","songKor":"YOU AND I","songEng":"You and I"},
    {"id":1055,"singerKor":"박상민","singerEng":"Park Sang-min","songKor":"상실","songEng":"Loss"},
    {"id":1056,"singerKor":"박상민","singerEng":"Park Sang-min","songKor":"연인 (戀人) (유니코드 인코딩 충돌)","songEng":"Lover (Encoding Conflict of Unicode)"},
    {"id":1057,"singerKor":"박상민","singerEng":"Park Sang-min","songKor":"연인 (戀人)","songEng":"Lover."},
    {"id":1058,"singerKor":"박상민","singerEng":"Park Sang-min","songKor":"하나의 사랑","songEng":"One Love"},
    {"id":1059,"singerKor":"박상민","singerEng":"Park Sang-min","songKor":"해바라기","songEng":"Sunflower"},
    {"id":1060,"singerKor":"박완규","singerEng":"Park Wan-kyu","songKor":"욕망이란 이름...","songEng":"Desire by DEAN."},
    {"id":1061,"singerKor":"박용하","singerEng":"Park Yong-ha","songKor":"처음 그 날처럼","songEng":"Just the Way You Are"},
    {"id":1062,"singerKor":"박원","singerEng":"Park Won","songKor":"all of my life","songEng":"All of My Life"},
    {"id":1063,"singerKor":"박원","singerEng":"Park Won","songKor":"노력","songEng":"Effort"},
    {"id":1064,"singerKor":"박재범","singerEng":"Jay Park","songKor":"ALL I WANNA DO (K) (Feat. Hoody_ Loco)","songEng":"All I Wanna Do"},
    {"id":1065,"singerKor":"박정현","singerEng":"Park Jung-hyun","songKor":"You Mean Everything To Me","songEng":"You Mean Everything To Me."},
    {"id":1066,"singerKor":"박정현","singerEng":"Park Jung-hyun","songKor":"꿈에","songEng":"Dream"},
    {"id":1067,"singerKor":"박정현","singerEng":"Park Jung-hyun","songKor":"앤","songEng":"Anne."},
    {"id":1068,"singerKor":"박지윤","singerEng":"Park Ji-yoon","songKor":"난 사랑에 빠졌죠","songEng":"I'm in Love"},
    {"id":1069,"singerKor":"박지윤","singerEng":"Park Ji-yoon","songKor":"미스터리 (Feat. San E)","songEng":"Mystery (Feat. San E)"},
    {"id":1070,"singerKor":"박지윤","singerEng":"Park Ji Yoon","songKor":"성인식","songEng":"Coming-of-Age Ceremony"},
    {"id":1071,"singerKor":"박지헌(V.O.S)","singerEng":"Park Ji-heon VOS","songKor":"보고싶은 날엔..","songEng":"Missing You Like Crazy"},
    {"id":1072,"singerKor":"박진영","singerEng":"Park Jinyoung","songKor":"When We Disco (Duet With 선미)","songEng":"When We Disco (Duet With 선미)"},
    {"id":1073,"singerKor":"박진영","singerEng":"Park Jinyoung","songKor":"그녀는 예뻤다","songEng":"She Was Pretty"},
    {"id":1074,"singerKor":"박진영","singerEng":"Park Jinyoung","songKor":"너뿐이야 (You`re The One)","songEng":"You're The One"},
    {"id":1075,"singerKor":"박진영","singerEng":"Park Jin Young","songKor":"대낮에 한 이별 (feat. 선예)","songEng":"In the daytime_ We Parted (feat. Sunye)"},
    {"id":1076,"singerKor":"박진영","singerEng":"Park Jin Young","songKor":"어머님이 누구니 (Feat. Jessi of Lucky J)","songEng":"Who's Your Mama (Feat. Jessi of Lucky J)"},
    {"id":1077,"singerKor":"박학기","singerEng":"Park Hak-gi","songKor":"이미 그댄","songEng":"Already You"},
    {"id":1078,"singerKor":"박혜경","singerEng":"Park Hye-kyung","songKor":"고백","songEng":"Confession"},
    {"id":1079,"singerKor":"박혜경","singerEng":"Park Hye-kyung","songKor":"빨간 운동화","songEng":"Red Sneakers."},
    {"id":1080,"singerKor":"박혜경","singerEng":"Park Hye-kyung","songKor":"안녕","songEng":"Hello"},
    {"id":1081,"singerKor":"박혜경","singerEng":"Park Hye-kyung","songKor":"주문을 걸어","songEng":"Love Scenario"},
    {"id":1082,"singerKor":"박혜경","singerEng":"Park Hye Kyung","songKor":"하루","songEng":"Day"},
    {"id":1083,"singerKor":"박화요비","singerEng":"Park Hwa Yoobi","songKor":"눈물","songEng":"Tears"},
    {"id":1084,"singerKor":"박효신 (Park Hyo Shin)","singerEng":"Park Hyo Shin","songKor":"Goodbye","songEng":"Goodbye"},
    {"id":1085,"singerKor":"박효신","singerEng":"Park Hyo Shin","songKor":"12. 먼곳에서 ...박효신","songEng":"Far Away...Park Hyo Shin"},
    {"id":1086,"singerKor":"박효신","singerEng":"Park Hyo Shin","songKor":"그곳에 서서","songEng":"Standing There"},
    {"id":1087,"singerKor":"박효신","singerEng":"Park Hyo Shin","songKor":"눈의 꽃","songEng":"Snow Flower"},
    {"id":1088,"singerKor":"박효신","singerEng":"Park Hyo Shin","songKor":"동경","songEng":"Tokyo"},
    {"id":1089,"singerKor":"박효신","singerEng":"Park Hyo Shin","songKor":"먼곳에서","songEng":"Far Away"},
    {"id":1090,"singerKor":"박효신","singerEng":"Park Hyo-shin","songKor":"바보","songEng":"Fool"},
    {"id":1091,"singerKor":"박효신","singerEng":"Park Hyo Shin","songKor":"사랑한 후에","songEng":"After Love"},
    {"id":1092,"singerKor":"박효신","singerEng":"Park Hyo Shin","songKor":"숨","songEng":"Breath"},
    {"id":1093,"singerKor":"박효신","singerEng":"Park Hyo Shin","songKor":"야생화","songEng":"Wildflower"},
    {"id":1094,"singerKor":"박효신","singerEng":"Park Hyo Shin","songKor":"좋은사람","songEng":"Good Person"},
    {"id":1095,"singerKor":"박효신","singerEng":"Park Hyo Shin","songKor":"추억은 사랑을 닮아","songEng":"The Memory Resembles Love"},
    {"id":1096,"singerKor":"박효신","singerEng":"Park Hyo Shin","songKor":"흩어진 나날들","songEng":"Scattered Days"},
    {"id":1097,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"0000 (Zero O’Clock)","songEng":"Zero O'Clock"},
    {"id":1098,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"Black Swan","songEng":"Black Swan"},
    {"id":1099,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"Butter","songEng":"Butter."},
    {"id":1100,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"DNA","songEng":"DNA"},
    {"id":1101,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"Dynamite","songEng":"Dynamite"},
    {"id":1102,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"Filter","songEng":"Filter."},
    {"id":1103,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"Inner Child","songEng":"Inner Child"},
    {"id":1104,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"Life Goes On","songEng":"Life Goes On"},
    {"id":1105,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"Moon","songEng":"Moon."},
    {"id":1106,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"ON","songEng":"ON"},
    {"id":1107,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"Permission to Dance","songEng":"Permission to Dance"},
    {"id":1108,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"We are Bulletproof  the Eternal","songEng":"We are Bulletproof The Eternal"},
    {"id":1109,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"Yet To Come","songEng":"Yet To Come"},
    {"id":1110,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"봄날","songEng":"Spring Day"},
    {"id":1111,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"시차","songEng":"Time diffece"},
    {"id":1112,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"작은 것들을 위한 시 (Boy with Luv) feat. Halsey","songEng":"Boy with Luv ft. Halsey"},
    {"id":1113,"singerKor":"방탄소년단 (BTS)","singerEng":"BTS","songKor":"친구","songEng":"Friend."},
    {"id":1114,"singerKor":"방탄소년단 (BTS)_ Halsey","singerEng":"BTS_ Halsey","songKor":"작은 것들을 위한 시 (Boy With Luv) (Feat. Halsey)","songEng":"Boy With Luv"},
    {"id":1115,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"Dynamite","songEng":"Dynamite."},
    {"id":1116,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"Butter","songEng":"Butter."},
    {"id":1117,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"봄날","songEng":"Spring Day"},
    {"id":1118,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"Permission to Dance","songEng":"Permission to Dance"},
    {"id":1119,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"Anpanman","songEng":"Anpanman"},
    {"id":1120,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"Born Singer","songEng":"Born Singer"},
    {"id":1121,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"Butter","songEng":"Butter"},
    {"id":1122,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"DNA","songEng":"DNA"},
    {"id":1123,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"Dynamite","songEng":"Dynamite"},
    {"id":1124,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"FAKE LOVE","songEng":"FAKE LOVE"},
    {"id":1125,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"For Youth","songEng":"For Youth"},
    {"id":1126,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"I NEED U","songEng":"I NEED U"},
    {"id":1127,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"IDOL","songEng":"IDOL"},
    {"id":1128,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"Make It Right","songEng":"Make It Right"},
    {"id":1129,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"Permission to Dance","songEng":"Permission to Dance"},
    {"id":1130,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"Yet To Come","songEng":"Yet To Come"},
    {"id":1131,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"달려라 방탄","songEng":"Run BTS"},
    {"id":1132,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"봄날","songEng":"Spring Day"},
    {"id":1133,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"불타오르네 (FIRE)","songEng":"Fire"},
    {"id":1134,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"소우주 (Mikrokosmos)","songEng":"Mikrokosmos"},
    {"id":1135,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"전하지 못한 진심 (Feat. Steve Aoki)","songEng":"Boy With Luv"},
    {"id":1136,"singerKor":"방탄소년단","singerEng":"BTS","songKor":"피 땀 눈물","songEng":"Blood Sweat & Tears"},
    {"id":1137,"singerKor":"배연서 (WEBSTER B)_ 오담률 (CHIN CHILLA)","singerEng":"Webster B_ Chin Chilla.","songKor":"북 (Prod. SLO)","songEng":"North (Prod. SLO)"},
    {"id":1138,"singerKor":"배치기","singerEng":"Baechigi","songKor":"눈물샤워 (Feat. 에일리)","songEng":"Tears Rain (Feat. Ailee)"},
    {"id":1139,"singerKor":"배치기","singerEng":"Baechigi","songKor":"뜨래요 (Feat. 앙리 Of 3B)","songEng":"Dduraeyo (Feat. Angri Of 3B)"},
    {"id":1140,"singerKor":"배치기","singerEng":"Baechigi","songKor":"마이동풍","songEng":"My Dong Phuong."},
    {"id":1141,"singerKor":"배치기(Baechigi)","singerEng":"Baechigi","songKor":"02 NO.3 (feat. Sol Flower)","songEng":"02 NO3 (feat. Sol Flower)"},
    {"id":1142,"singerKor":"백아","singerEng":"Baek Ah.","songKor":"첫사랑","songEng":"First Love"},
    {"id":1143,"singerKor":"백아","singerEng":"Baek A","songKor":"첫사랑","songEng":"First Love"},
    {"id":1144,"singerKor":"백아연","singerEng":"Baek A Yeon","songKor":"쏘쏘","songEng":"Soso"},
    {"id":1145,"singerKor":"백아연","singerEng":"Baek A-yeon","songKor":"이럴거면 그러지말지 (Feat. 영현)","songEng":"Don't Say You Love Me (Feat. Young Hyun)"},
    {"id":1146,"singerKor":"백아연","singerEng":"Baek A Yeon","songKor":"이렇게 우리","songEng":"As We"},
    {"id":1147,"singerKor":"백예린","singerEng":"Baek Yerin","songKor":"Square (2017)","songEng":"Square"},
    {"id":1148,"singerKor":"백예린","singerEng":"Baek Yerin","songKor":"다시 난_ 여기","songEng":"Again Here"},
    {"id":1149,"singerKor":"백예린","singerEng":"Baek Yerin","songKor":"우주를 건너","songEng":"Across the Universe"},
    {"id":1150,"singerKor":"백지영","singerEng":"Baek Jiyoung","songKor":"Because Of You","songEng":"Because Of You"},
    {"id":1151,"singerKor":"백지영","singerEng":"Baek Jiyoung","songKor":"Good Boy (Feat. 용준형 of 비스트)","songEng":"Good Boy (Feat. Yong Junhyung of BEAST)"},
    {"id":1152,"singerKor":"백지영","singerEng":"Baek Jiyoung","songKor":"거짓말이라도 해서 널 보고싶어","songEng":"Lie"},
    {"id":1153,"singerKor":"백지영","singerEng":"Baek Jiyoung","songKor":"그여자","songEng":"That Woman"},
    {"id":1154,"singerKor":"백지영","singerEng":"Baek Jiyoung","songKor":"대쉬","songEng":"Dash"},
    {"id":1155,"singerKor":"백지영","singerEng":"Baek Jiyoung","songKor":"목소리 (Feat. 개리 Of 리쌍)","songEng":"Voice (Feat. Garie of Leessang)"},
    {"id":1156,"singerKor":"백지영","singerEng":"Baek Jiyoung","songKor":"미소","songEng":"Mi So"},
    {"id":1157,"singerKor":"백지영","singerEng":"Baek Jiyoung","songKor":"사랑 안해","songEng":"Do not forsake me (사랑 안해)"},
    {"id":1158,"singerKor":"백지영","singerEng":"Baek Jiyoung","songKor":"사랑 하나면 돼","songEng":"Love Only Needed"},
    {"id":1159,"singerKor":"백지영","singerEng":"Baek Jiyoung","songKor":"사랑안해","songEng":"Love Does Not Exist"},
    {"id":1160,"singerKor":"백지영","singerEng":"Baek Jiyoung","songKor":"사랑이 올까요","songEng":"Love Will Come 가 됐나 봐."},
    {"id":1161,"singerKor":"백지영","singerEng":"Baek Jiyoung","songKor":"새벽 가로수길 (With 송유빈)","songEng":"Dawn Along the Road (With Song Yoo-bin)"},
    {"id":1162,"singerKor":"백지영","singerEng":"Baek Jiyoung","songKor":"싫다","songEng":"Hate."},
    {"id":1163,"singerKor":"백지영","singerEng":"Baek Jiyoung","songKor":"총맞은 것처럼","songEng":"Shot Like a Bullet"},
    {"id":1164,"singerKor":"백지영","singerEng":"Baek Jiyoung","songKor":"추락","songEng":"Chulak"},
    {"id":1165,"singerKor":"버벌진트","singerEng":"Verbal Jint","songKor":"굿모닝 (Feat. 권정열 Of 10cm)","songEng":"Good Morning (Feat. Kwon Jung Yeol Of 10cm)"},
    {"id":1166,"singerKor":"버벌진트","singerEng":"Verbal Jint","songKor":"비범벅 (Walking In The Rain) (Feat. 범키)","songEng":"Walking In The Rain (Feat. Beomky)"},
    {"id":1167,"singerKor":"버벌진트","singerEng":"Verbal Jint","songKor":"시작이 좋아 (Feat. 강민희 Of 미스에스)","songEng":"Good Beginning (Feat. Kang Minhee of Miss $)"},
    {"id":1168,"singerKor":"버벌진트","singerEng":"Verbal Jint","songKor":"이게 사랑이 아니면 (Feat. 에일리)","songEng":"If the song name is already in English_ return english name."},
    {"id":1169,"singerKor":"버벌진트","singerEng":"Verbal Jint","songKor":"충분히 예뻐 (Feat. 산체스 Of 팬텀)","songEng":"Pretty Enough (Feat. Sanchez of Phantom)"},
    {"id":1170,"singerKor":"버블 시스터즈","singerEng":"Bubble Sisters","songKor":"사랑 먼지","songEng":"Love Dust"},
    {"id":1171,"singerKor":"버블 시스터즈","singerEng":"Bubble Sisters","songKor":"악몽","songEng":"Nightmare"},
    {"id":1172,"singerKor":"버블시스터즈","singerEng":"Bubble Sisters","songKor":"바보처럼","songEng":"Foolish Love"},
    {"id":1173,"singerKor":"버스커 버스커 (Busker Busker)","singerEng":"Busker Busker","songKor":"벚꽃 엔딩","songEng":"Cherry Blossom Ending"},
    {"id":1174,"singerKor":"버스커 버스커","singerEng":"Busker Busker","songKor":"그댈 마주하는건 힘들어 (그마힘)","songEng":"It's Difficult to Face You (Geu Mahim)"},
    {"id":1175,"singerKor":"버스커 버스커","singerEng":"Busker Busker","songKor":"꽃송이가","songEng":"Flower Blossom"},
    {"id":1176,"singerKor":"버스커 버스커","singerEng":"Busker Busker","songKor":"동경소녀","songEng":"Tokyo Girl"},
    {"id":1177,"singerKor":"버스커 버스커","singerEng":"Busker Busker","songKor":"막걸리나","songEng":"Makgeolli Na"},
    {"id":1178,"singerKor":"버스커 버스커","singerEng":"Busker Busker","songKor":"벚꽃 엔딩","songEng":"Cherry Blossom Ending"},
    {"id":1179,"singerKor":"버스커 버스커","singerEng":"Busker Busker","songKor":"사랑은 타이밍","songEng":"Love Timing"},
    {"id":1180,"singerKor":"버스커 버스커","singerEng":"Busker Busker","songKor":"서울사람들","songEng":"Seoul People"},
    {"id":1181,"singerKor":"버스커 버스커","singerEng":"Busker Busker","songKor":"소나기 (주르르루)","songEng":"Rain Shower (Jururu)"},
    {"id":1182,"singerKor":"버스커 버스커","singerEng":"Busker Busker","songKor":"여수 밤바다","songEng":"Yeouido Spring Flower Festival"},
    {"id":1183,"singerKor":"버스커 버스커","singerEng":"Busker Busker","songKor":"외로움증폭장치 (브래드 드럼 한판 쉬기)","songEng":"Loneliness Amplification Device (Brad Drums Break)"},
    {"id":1184,"singerKor":"버스커 버스커","singerEng":"Busker Busker","songKor":"이상형","songEng":"Ideal Type"},
    {"id":1185,"singerKor":"버스커 버스커","singerEng":"Busker Busker","songKor":"잘할 걸","songEng":"Jalhal Geol"},
    {"id":1186,"singerKor":"버스커 버스커","singerEng":"Busker Busker","songKor":"정류장","songEng":"Bus Stop"},
    {"id":1187,"singerKor":"버스커 버스커","singerEng":"Busker Busker","songKor":"처음엔 사랑이란게","songEng":"At First Love Is."},
    {"id":1188,"singerKor":"버스커 버스커","singerEng":"Busker Busker","songKor":"첫사랑","songEng":"First Love"},
    {"id":1189,"singerKor":"버즈 (Buzz)","singerEng":"Buzz","songKor":"1st","songEng":"Boy With Luv"},
    {"id":1190,"singerKor":"버즈 (Buzz)","singerEng":"Buzz","songKor":"Monologue","songEng":"Monologue"},
    {"id":1191,"singerKor":"버즈 (Buzz)","singerEng":"Buzz","songKor":"My Darling (end)","songEng":"My Darling (end)"},
    {"id":1192,"singerKor":"버즈 (Buzz)","singerEng":"Buzz","songKor":"My Love (And)","songEng":"My Love And"},
    {"id":1193,"singerKor":"버즈 (Buzz)","singerEng":"Buzz","songKor":"가시","songEng":"Thorn"},
    {"id":1194,"singerKor":"버즈 (Buzz)","singerEng":"Buzz","songKor":"거짓말","songEng":"Lies"},
    {"id":1195,"singerKor":"버즈 (Buzz)","singerEng":"Buzz","songKor":"겁쟁이","songEng":"Coward."},
    {"id":1196,"singerKor":"버즈 (Buzz)","singerEng":"Buzz","songKor":"남자를 몰라","songEng":"I do not know."},
    {"id":1197,"singerKor":"버즈 (Buzz)","singerEng":"Buzz","songKor":"벌","songEng":"Vibe."},
    {"id":1198,"singerKor":"버즈 (Buzz)","singerEng":"Buzz","songKor":"빗방울","songEng":"Rain Drop"},
    {"id":1199,"singerKor":"버즈 (Buzz)","singerEng":"Buzz","songKor":"사랑은 가슴이 시킨다 Part.2","songEng":"Love Makes Your Heart Aches Part.2"},
    {"id":1200,"singerKor":"버즈 (Buzz)","singerEng":"Buzz","songKor":"사랑은 가슴이 시킨다","songEng":"Love Makes Our Heart Hurt Ache"},
    {"id":1201,"singerKor":"범키","singerEng":"Bumkey","songKor":"갖고놀래 (Feat. 다이나믹 듀오)","songEng":"Play With Toys (Feat. Dynamic Duo)"},
    {"id":1202,"singerKor":"범키","singerEng":"Bumkey","songKor":"미친연애 (Bad Girl) (Feat. E-Sens Of 슈프림팀)","songEng":"Bad Girl (Feat. E-Sens Of Supreme Team)"},
    {"id":1203,"singerKor":"베이비 복스","singerEng":"Baby Vox","songKor":"나 어떡해","songEng":"What is Love"},
    {"id":1204,"singerKor":"베이비 복스 1기","singerEng":"Baby Vox 1st Generation","songKor":"우연 (Deep club Re-mix)","songEng":"Coincidence (Deep club Re-mix)"},
    {"id":1205,"singerKor":"베이식 (Basick)","singerEng":"Basick","songKor":"만남은 쉽고 이별은 어려워 (Feat. Leellamarz) (Prod. TOIL)","songEng":"Meeting is Easy_ Separation is Hard (Feat. Leellamarz) (Prod. TOIL)"},
    {"id":1206,"singerKor":"베이식 (Basick)_ Leellamarz","singerEng":"Basick_ Leellamarz","songKor":"만남은 쉽고 이별은 어려워 (Feat. Leellamarz) (Prod. TOIL)","songEng":"Meeting Easy_ Separation Hard (Feat. Leellamarz) (Prod. TOIL)"},
    {"id":1207,"singerKor":"베이식","singerEng":"Basic","songKor":"만남은 쉽고 이별은 어려워 (Feat. Leellamarz) (Prod. by TOIL)","songEng":"Meeting You Was Easy_ Farewell Was Hard (Feat. Leellamarz) (Prod. by TOIL)"},
    {"id":1208,"singerKor":"베이식(Basick)","singerEng":"Basick","songKor":"만남은 쉽고 이별은 어려워 (Feat. Leellamarz) (Prod. TOIL)","songEng":"Meeting is Easy_ Separation is Hard (Feat. Leellamarz) (Prod. TOIL)"},
    {"id":1209,"singerKor":"벤","singerEng":"Ben","songKor":"180도","songEng":"180 Degree"},
    {"id":1210,"singerKor":"벤","singerEng":"Ben","songKor":"꿈처럼","songEng":"Like a Dream"},
    {"id":1211,"singerKor":"벤","singerEng":"Ben","songKor":"내 목소리 들리니","songEng":"Can't Hear My Voice"},
    {"id":1212,"singerKor":"벤","singerEng":"Ben","songKor":"벚꽃이 피면 우리 그만 헤어져","songEng":"When Cherry Blossoms Fade"},
    {"id":1213,"singerKor":"벤","singerEng":"Ben","songKor":"열애중","songEng":"Love in the Milky Way Caf?"},
    {"id":1214,"singerKor":"벤","singerEng":"Ben","songKor":"헤어져줘서 고마워","songEng":"Thanks for leaving me"},
    {"id":1215,"singerKor":"변진섭","singerEng":"Byun Jin-seob","songKor":"너무 늦었잖아요","songEng":"You're Too Late"},
    {"id":1216,"singerKor":"변진섭","singerEng":"Byun Jin-seop","songKor":"희망사항","songEng":"Hopeful."},
    {"id":1217,"singerKor":"별","singerEng":"Byul","songKor":"귀여워 (With 권정열 of 10cm)","songEng":"Cute (With Kwon Jung Yeol of 10cm)"},
    {"id":1218,"singerKor":"별","singerEng":"Byul","songKor":"눈물샘","songEng":"Tears"},
    {"id":1219,"singerKor":"별","singerEng":"Byul","songKor":"안부 (Duet With 나윤권)","songEng":"Anbu Duet With Naul"},
    {"id":1220,"singerKor":"별(Star)","singerEng":"Byul","songKor":"미워도 좋아","songEng":"Hate That... But I Love You"},
    {"id":1221,"singerKor":"보라미유 & MJ (써니사이드)","singerEng":"보라미유 & MJ (써니사이드)","songKor":"통화연결음","songEng":"Call Ringtone"},
    {"id":1222,"singerKor":"보라미유_ MJ(써니사이드)","singerEng":"Boramieyu_ MJ (Sunnyside)","songKor":"통화연결음","songEng":"Ringtone (Tonghwa Yeon-gyeol-eum)"},
    {"id":1223,"singerKor":"보아","singerEng":"BoA","songKor":"01. MOTO","songEng":"MOTO"},
    {"id":1224,"singerKor":"보아","singerEng":"BoA","songKor":"Girls On Top","songEng":"Girls On Top"},
    {"id":1225,"singerKor":"보아","singerEng":"BoA","songKor":"Hurricane Venus","songEng":"Hurricane Venus"},
    {"id":1226,"singerKor":"보아","singerEng":"BoA","songKor":"No.1","songEng":"No.1"},
    {"id":1227,"singerKor":"보아","singerEng":"Boa","songKor":"Only One","songEng":"Only One"},
    {"id":1228,"singerKor":"보아","singerEng":"BoA","songKor":"아틀란티스 소녀 (Atlantis Princess)","songEng":"Atlantis Princess"},
    {"id":1229,"singerKor":"보아","singerEng":"BoA","songKor":"아틀란티스 소녀","songEng":"Atlantis Girl"},
    {"id":1230,"singerKor":"보아(BoA)","singerEng":"BoA","songKor":"My Name","songEng":"My Name"},
    {"id":1231,"singerKor":"보이비","singerEng":"Boi B.","songKor":"호랑나비 (Feat. Gill_ 리듬파워)","songEng":"Tiger Butterfly (Feat. Gill_ Rhythm Power)"},
    {"id":1232,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"Love story","songEng":"Love Story"},
    {"id":1233,"singerKor":"볼빨간사춘기 (Bolbbalgan4)","singerEng":"Bolbbalgan4","songKor":"썸 탈꺼야","songEng":"Some Ttalgeoya."},
    {"id":1234,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"#첫사랑","songEng":"First Love"},
    {"id":1235,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"Love story","songEng":"Love Story."},
    {"id":1236,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"Love story","songEng":"Love Story"},
    {"id":1237,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"Seoul","songEng":"Seoul"},
    {"id":1238,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"You(=I)","songEng":"You Are My Everything"},
    {"id":1239,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"나만 안되는 연애","songEng":"Only You by Kim Jong-wan of NELL."},
    {"id":1240,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"나만_ 봄","songEng":"Only Spring."},
    {"id":1241,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"나비와 고양이 (feat.백현 (BAEKHYUN))","songEng":"Butterfly and Cat (feat. Baekhyun)"},
    {"id":1242,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"나의 사춘기에게","songEng":"To My Youth"},
    {"id":1243,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"별 보러 갈래","songEng":"Star 6408."},
    {"id":1244,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"심술","songEng":"Shimsul"},
    {"id":1245,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"썸 탈꺼야","songEng":"Some Sevit."},
    {"id":1246,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"여행","songEng":"Travel"},
    {"id":1247,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"우주를 줄게","songEng":"Give You My Universe."},
    {"id":1248,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"워커홀릭","songEng":"Workerholic"},
    {"id":1249,"singerKor":"볼빨간사춘기","singerEng":"Bolbbalgan4","songKor":"처음부터 너와 나","songEng":"From the beginning to me and you"},
    {"id":1250,"singerKor":"볼빨간사춘기_ 스무살","singerEng":"Bolbbalgan4_ Twenty years old","songKor":"남이 될 수 있을까","songEng":"Can't Become A Person to You"},
    {"id":1251,"singerKor":"봄여름가을겨울","singerEng":"봄여름가을겨울","songKor":"10년전의 일기를 꺼내어","songEng":"Retrieve the Diary from 10 Years Ago"},
    {"id":1252,"singerKor":"봄여름가을겨울","singerEng":"Bom Yeoreum Gaeul Gyeoul","songKor":"Bravo_ My Life!","songEng":"Bravo My Life"},
    {"id":1253,"singerKor":"봄여름가을겨울","singerEng":"봄여름가을겨울_ also known as SpringSummerFallWinter","songKor":"和解戀歌","songEng":"Loveholic - One Love"},
    {"id":1254,"singerKor":"부활","singerEng":"Boohwal","songKor":"Never Ending Story","songEng":"Never Ending Story"},
    {"id":1255,"singerKor":"부활","singerEng":"Boohwal","songKor":"사랑할수록","songEng":"Love will come back to me"},
    {"id":1256,"singerKor":"부활","singerEng":"Boohwal","songKor":"새벽","songEng":"Dawn"},
    {"id":1257,"singerKor":"부활","singerEng":"Boohwal","songKor":"생각이나","songEng":"Recollection"},
    {"id":1258,"singerKor":"불독맨션(Bulldogmansion)","singerEng":"Bulldogmansion","songKor":"Buenos Aires","songEng":"Buenos Aires"},
    {"id":1259,"singerKor":"불독맨션(Bulldogmansion)","singerEng":"Bulldogmansion","songKor":"El Disco Amor (사랑의 디스코) (Radio Edit)","songEng":"Love's Disco"},
    {"id":1260,"singerKor":"브라운 아이드 걸스","singerEng":"Brown Eyed Girls","songKor":"L. O. V. E","songEng":"LOVE"},
    {"id":1261,"singerKor":"브라운 아이드 걸스","singerEng":"Brown Eyed Girls","songKor":"My Style (Hidden Track)","songEng":"My Style (Hidden Track)"},
    {"id":1262,"singerKor":"브라운 아이드 걸스","singerEng":"Brown Eyed Girls","songKor":"어쩌다","songEng":"EOJJEODA is called How You Like That in English."},
    {"id":1263,"singerKor":"브라운 아이드 걸스","singerEng":"Brown Eyed Girls","songKor":"오아시스 (Feat. 이재훈)","songEng":"Oasis (Feat. Lee Jae Hoon)"},
    {"id":1264,"singerKor":"브라운 아이드 소울 (Brown Eyed Soul)","singerEng":"Brown Eyed Soul","songKor":"정말 사랑했을까","songEng":"Did I Really Love You"},
    {"id":1265,"singerKor":"브라운 아이즈","singerEng":"Brown Eyed","songKor":"With Coffee...","songEng":"With Coffee"},
    {"id":1266,"singerKor":"브라운 아이즈","singerEng":"Brown Eyes","songKor":"벌써 일년","songEng":"Already One Year"},
    {"id":1267,"singerKor":"브라운 아이즈","singerEng":"Brown Eyes","songKor":"점점","songEng":"Gradually."},
    {"id":1268,"singerKor":"브라운 아이즈(Brown Eyes)","singerEng":"Brown Eyes.","songKor":"벌써일년","songEng":"Already One Year"},
    {"id":1269,"singerKor":"브라운아이드걸스","singerEng":"Brown Eyed Girls","songKor":"Candy Man","songEng":"Candy Man"},
    {"id":1270,"singerKor":"브라운아이드걸스","singerEng":"Brown Eyed Girls","songKor":"Kill Bill","songEng":"Kill Bill"},
    {"id":1271,"singerKor":"브라운아이드걸스","singerEng":"Brown Eyed Girls","songKor":"Sign","songEng":"Sign"},
    {"id":1272,"singerKor":"브라운아이드걸스","singerEng":"Brown Eyed Girls","songKor":"Sixth Sense","songEng":"Sixth Sense"},
    {"id":1273,"singerKor":"브라운아이즈(Brown Eyes)","singerEng":"Brown Eyes","songKor":"02 가지마 가지마","songEng":"Don't Go Don't Go"},
    {"id":1274,"singerKor":"브라운아이즈(Brown Eyes)","singerEng":"Brown Eyes","songKor":"05 너 때문에","songEng":"You Because of You"},
    {"id":1275,"singerKor":"브라이언 (Brian)","singerEng":"Brian","songKor":"가지마","songEng":"Don't Go"},
    {"id":1276,"singerKor":"브랜 뉴 데이","singerEng":"Brand New Day","songKor":"살만해","songEng":"Salmanhae"},
    {"id":1277,"singerKor":"브레이브걸스 (Brave Girls)","singerEng":"Brave Girls","songKor":"롤린 (Rollin')","songEng":"Rollin"},
    {"id":1278,"singerKor":"브레이브걸스 (Brave Girls)","singerEng":"Brave Girls","songKor":"롤린 (Rollin`)","songEng":"Rollin"},
    {"id":1279,"singerKor":"브레이브걸스 (Brave Girls)","singerEng":"Brave Girls","songKor":"운전만해","songEng":"Drive"},
    {"id":1280,"singerKor":"브레이브걸스 (Brave Girls)","singerEng":"Brave Girls","songKor":"치맛바람 (Chi Mat Ba Ram)","songEng":"Chi Mat Ba Ram"},
    {"id":1281,"singerKor":"브레이브걸스","singerEng":"Brave Girls","songKor":"Rollin'","songEng":"Rollin"},
    {"id":1282,"singerKor":"브이오에스 (V.O.S)","singerEng":"V.O.S","songKor":"매일 매일","songEng":"Everyday Everyday."},
    {"id":1283,"singerKor":"브이원","singerEng":"V1Us","songKor":"면도","songEng":"Shaving."},
    {"id":1284,"singerKor":"블락비 (Block B)","singerEng":"Block B","songKor":"HER","songEng":"Her."},
    {"id":1285,"singerKor":"블락비 (Block B)","singerEng":"Block B","songKor":"JACKPOT","songEng":"Jackpot"},
    {"id":1286,"singerKor":"블락비 (Block B)","singerEng":"Block B","songKor":"YESTERDAY","songEng":"Yesterday."},
    {"id":1287,"singerKor":"블락비 (Block B)","singerEng":"Block B","songKor":"닐리리맘보","songEng":"Nillili Mambo"},
    {"id":1288,"singerKor":"블루 (BLOO)","singerEng":"BLOO","songKor":"Downtown Baby","songEng":"Downtown Baby"},
    {"id":1289,"singerKor":"비","singerEng":"Rain","songKor":"Hip Song","songEng":"Hip Song"},
    {"id":1290,"singerKor":"비","singerEng":"Bi","songKor":"I'm Coming(Feat. Tablo)","songEng":"I'm Coming (Feat. Tablo)"},
    {"id":1291,"singerKor":"비","singerEng":"Bi","songKor":"It's Raining","songEng":"It's Raining"},
    {"id":1292,"singerKor":"비","singerEng":"Bi","songKor":"La Song","songEng":"La Song"},
    {"id":1293,"singerKor":"비","singerEng":"Rain","songKor":"Love Story","songEng":"Love Story"},
    {"id":1294,"singerKor":"비","singerEng":"Bi","songKor":"Rainism","songEng":"Rainism"},
    {"id":1295,"singerKor":"비","singerEng":"Bi","songKor":"널 붙잡을 노래","songEng":"Hold Me Tight"},
    {"id":1296,"singerKor":"비(Rain)","singerEng":"Rain","songKor":"08 태양을 피하는 방법 Gtr. Rem","songEng":"Sunflower Guitar Remedy"},
    {"id":1297,"singerKor":"비(Rain)","singerEng":"Rain","songKor":"나쁜남자","songEng":"Bad Boy"},
    {"id":1298,"singerKor":"비(Rain)","singerEng":"Rain","songKor":"안녕이란 말대신","songEng":"Goodbye Instead of Saying Goodbye."},
    {"id":1299,"singerKor":"비바 소울(Viva Soul)","singerEng":"Viva Soul","songKor":"Cry (feat. 하동균)","songEng":"Cry (feat. Ha Dong-gyun)"},
    {"id":1300,"singerKor":"비비 (BIBI)","singerEng":"BIBI","songKor":"우리가 헤어져야 했던 이유","songEng":"We Had To Break Up"},
    {"id":1301,"singerKor":"비비 (BIBI) & 윤미래","singerEng":"BIBI & 윤미래","songKor":"LAW (Prod. by Czaer)","songEng":"Law"},
    {"id":1302,"singerKor":"비비 (BIBI)","singerEng":"BIBI","songKor":"우리가 헤어져야 했던 이유","songEng":"We Should Have Broken Up"},
    {"id":1303,"singerKor":"비비(BIBI)","singerEng":"BIBI","songKor":"아주_ 천천히","songEng":"Slowly_ Surely."},
    {"id":1304,"singerKor":"비비(BIBI)","singerEng":"BIBI","songKor":"우리가 헤어져야 했던 이유","songEng":"Reason why we had to break up"},
    {"id":1305,"singerKor":"비스트","singerEng":"Beast","songKor":"12:30","songEng":"12:30"},
    {"id":1306,"singerKor":"비스트","singerEng":"Beast","songKor":"Fiction","songEng":"Fiction"},
    {"id":1307,"singerKor":"비스트","singerEng":"Beast","songKor":"Good Luck","songEng":"Good Luck"},
    {"id":1308,"singerKor":"비스트","singerEng":"Beast","songKor":"Midnight (별 헤는 밤)","songEng":"Starry Night"},
    {"id":1309,"singerKor":"비스트","singerEng":"BEAST","songKor":"Shadow (그림자)","songEng":"Shadow"},
    {"id":1310,"singerKor":"비스트","singerEng":"BEAST","songKor":"Shock","songEng":"Shock"},
    {"id":1311,"singerKor":"비스트","singerEng":"BEAST","songKor":"괜찮겠니","songEng":"Are You Okay?"},
    {"id":1312,"singerKor":"비스트","singerEng":"BEAST","songKor":"비가 오는 날엔","songEng":"On Rainy Days"},
    {"id":1313,"singerKor":"비스트","singerEng":"BEAST","songKor":"아름다운 밤이야","songEng":"Beautiful Night"},
    {"id":1314,"singerKor":"비스트","singerEng":"Beast","songKor":"이럴 줄 알았어","songEng":"I Knew It"},
    {"id":1315,"singerKor":"비스트(Beast)","singerEng":"Beast","songKor":"비가 오는 날엔","songEng":"On Rainy Days"},
    {"id":1316,"singerKor":"비스트(Beast)","singerEng":"Beast","songKor":"숨","songEng":"Breath"},
    {"id":1317,"singerKor":"비투비 (BTOB)","singerEng":"BTOB","songKor":"그리워하다","songEng":"Longing."},
    {"id":1318,"singerKor":"비투비","singerEng":"BtoB","songKor":"MOVIE","songEng":"Movie"},
    {"id":1319,"singerKor":"비투비","singerEng":"BtoB","songKor":"그리워하다","songEng":"Longing."},
    {"id":1320,"singerKor":"비투비","singerEng":"BTOB","songKor":"노래 (The Song)","songEng":"The Song."},
    {"id":1321,"singerKor":"비투비","singerEng":"BTOB","songKor":"아름답고도 아프구나","songEng":"Beautiful Painful Love."},
    {"id":1322,"singerKor":"빅마마 (Big Mama)","singerEng":"Big Mama","songKor":"하루만 더","songEng":"One More Day"},
    {"id":1323,"singerKor":"빅마마","singerEng":"Big Mama","songKor":"Break Away","songEng":"Break Away"},
    {"id":1324,"singerKor":"빅마마","singerEng":"Big Mama","songKor":"Never Mind","songEng":"Never Mind"},
    {"id":1325,"singerKor":"빅마마","singerEng":"Big Mama","songKor":"배반","songEng":"Betrayal"},
    {"id":1326,"singerKor":"빅마마","singerEng":"Big Mama","songKor":"여자","songEng":"Woman."},
    {"id":1327,"singerKor":"빅마마","singerEng":"Big Mama","songKor":"체념 (후)","songEng":"Resignation (After)."},
    {"id":1328,"singerKor":"빅마마","singerEng":"Big Mama","songKor":"체념","songEng":"Resignation"},
    {"id":1329,"singerKor":"빅뱅 & 2NE1","singerEng":"Bigbang & 2NE1","songKor":"Lollipop","songEng":"Lollipop."},
    {"id":1330,"singerKor":"빅뱅","singerEng":"BigBang","songKor":"06. Wonderful","songEng":"Wonderful"},
    {"id":1331,"singerKor":"빅뱅","singerEng":"Bigbang","songKor":"BAD BOY","songEng":"Bad Boy"},
    {"id":1332,"singerKor":"빅뱅","singerEng":"Bigbang","songKor":"FANTASTIC BABY","songEng":"Fantastic Baby"},
    {"id":1333,"singerKor":"빅뱅","singerEng":"Bigbang","songKor":"MONSTER","songEng":"Monster"},
    {"id":1334,"singerKor":"빅뱅","singerEng":"Bigbang","songKor":"STILL ALIVE","songEng":"Still Alive"},
    {"id":1335,"singerKor":"빅뱅","singerEng":"Bigbang","songKor":"Stylish (The FILA)","songEng":"Stylish (The FILA)"},
    {"id":1336,"singerKor":"빅뱅","singerEng":"Big Bang","songKor":"TONIGHT","songEng":"Tonight"},
    {"id":1337,"singerKor":"빅뱅","singerEng":"Bigbang","songKor":"거짓말","songEng":"Lies"},
    {"id":1338,"singerKor":"빅뱅","singerEng":"Bigbang","songKor":"바보","songEng":"Fool."},
    {"id":1339,"singerKor":"빅뱅","singerEng":"Bigbang","songKor":"붉은 노을","songEng":"Red Sunset"},
    {"id":1340,"singerKor":"빅뱅(BIGBANG)","singerEng":"Bigbang","songKor":"Dirty Cash","songEng":"Dirty Cash"},
    {"id":1341,"singerKor":"빅뱅(Bigbang)","singerEng":"Bigbang","songKor":"LOVE SONG","songEng":"Love Song."},
    {"id":1342,"singerKor":"사이먼 도미닉_ GRAY (그레이)_ 원","singerEng":"Simon Dominic_ Gray_ Won","songKor":"맘 편히 (Comfortable)","songEng":"Comfortable"},
    {"id":1343,"singerKor":"사이먼 도미닉_ 원_ 지투 (G2)_ BewhY (비와이)","singerEng":"Simon Dominic_ Won_ G2_ BewhY (비와이)","songKor":"니가 알던 내가 아냐 (Prod. By GRAY)","songEng":"NIKEDAWN NAEGA ANYA (Prod. By GRAY)"},
    {"id":1344,"singerKor":"산다라","singerEng":"Sandara","songKor":"Kiss (Feat. CL)","songEng":"Kiss (Feat CL)"},
    {"id":1345,"singerKor":"산들","singerEng":"Sanduel","songKor":"취기를 빌려 (취향저격 그녀 X 산들)","songEng":"Give Me A Taste (Feat. Sondia)"},
    {"id":1346,"singerKor":"산들","singerEng":"Sanduel","songKor":"취기를 빌려 (취향저격 그녀 ♡ X 산들)","songEng":"Moonlight Drawn by Clouds"},
    {"id":1347,"singerKor":"산울림","singerEng":"Sanullim","songKor":"가지마오","songEng":"Gajima"},
    {"id":1348,"singerKor":"산울림","singerEng":"Sanullim","songKor":"내게 사랑은 너무 써","songEng":"Love is So Tough for Me"},
    {"id":1349,"singerKor":"샘김 (SAM KIM)","singerEng":"Sam Kim","songKor":"Who Are You","songEng":"Who Are You"},
    {"id":1350,"singerKor":"샘김(Sam Kim)","singerEng":"Sam Kim","songKor":"여름비","songEng":"Summer Rain"},
    {"id":1351,"singerKor":"샤이니 (SHINee)","singerEng":"SHINee","songKor":"View","songEng":"View"},
    {"id":1352,"singerKor":"샤이니","singerEng":"SHINee","songKor":"Dream Girl","songEng":"Dream Girl"},
    {"id":1353,"singerKor":"샤이니","singerEng":"SHINee","songKor":"Lucifer","songEng":"Lucifer"},
    {"id":1354,"singerKor":"샤이니","singerEng":"SHINee","songKor":"Ring Ding Dong","songEng":"Ring Ding Dong"},
    {"id":1355,"singerKor":"샤이니","singerEng":"SHINee","songKor":"줄리엣 (Juliette)","songEng":"Juliette"},
    {"id":1356,"singerKor":"샤크라","singerEng":"Shakira","songKor":"Hey U!","songEng":"Hey U!"},
    {"id":1357,"singerKor":"샤크라","singerEng":"Shakira","songKor":"끝","songEng":"End"},
    {"id":1358,"singerKor":"샤크라","singerEng":"Shakira.","songKor":"난 너에게 (To You)","songEng":"To You"},
    {"id":1359,"singerKor":"샵","singerEng":"Shop","songKor":"잘됐어","songEng":"Good for you"},
    {"id":1360,"singerKor":"샵(S#arp)","singerEng":"S#arp","songKor":"내 입술... 따뜻한 커피처럼","songEng":"My Lips... Warm Like Coffee"},
    {"id":1361,"singerKor":"서문탁","singerEng":"Seo Moon-Tak","songKor":"08 사미인곡","songEng":"Samiin Song"},
    {"id":1362,"singerKor":"서문탁","singerEng":"Seo Moon Tak","songKor":"가거라 사랑아","songEng":"Go Away_ My Love"},
    {"id":1363,"singerKor":"서문탁","singerEng":"Seo Mun-tak","songKor":"사슬","songEng":"Chain"},
    {"id":1364,"singerKor":"서영은","singerEng":"Seo Young-eun","songKor":"너에게로 또다시","songEng":"To You Again"},
    {"id":1365,"singerKor":"서영은","singerEng":"Seo Young-eun","songKor":"이 거지같은 말 (With 정엽 Of 브라운 아이드 소울)","songEng":"This Joke (With Jung Yeop Of Brown Eyed Soul)"},
    {"id":1366,"singerKor":"서영은","singerEng":"Seo Young-eun","songKor":"중독","songEng":"Addiction"},
    {"id":1367,"singerKor":"서영은","singerEng":"Seo Young-eun","songKor":"천사","songEng":"Angel"},
    {"id":1368,"singerKor":"서인국","singerEng":"Seo In-guk","songKor":"사랑해 U","songEng":"Love U"},
    {"id":1369,"singerKor":"서인국","singerEng":"Seo In-guk","songKor":"부른다","songEng":"Call me by your name"},
    {"id":1370,"singerKor":"서인영","singerEng":"Seo In Young","songKor":"너를 원해","songEng":"I Want You."},
    {"id":1371,"singerKor":"서인영","singerEng":"Seo In-young","songKor":"사랑이라 쓰고 아픔이라 부른다","songEng":"Love is Not a Pain by Lee Seung-chul"},
    {"id":1372,"singerKor":"서인영","singerEng":"Seo In Young","songKor":"신데렐라","songEng":"Cinderella"},
    {"id":1373,"singerKor":"서지영","singerEng":"Seo Jiyoung","songKor":"Stay In Me","songEng":"Stay In Me"},
    {"id":1374,"singerKor":"서태지","singerEng":"Seo Taiji","songKor":"로보트","songEng":"Robot"},
    {"id":1375,"singerKor":"서태지","singerEng":"Seo Taiji.","songKor":"오렌지","songEng":"Orange."},
    {"id":1376,"singerKor":"서태지","singerEng":"Seo Taiji","songKor":"울트라맨이야","songEng":"Ultramania"},
    {"id":1377,"singerKor":"서태지와 아이들","singerEng":"Seo Taiji and Boys","songKor":"난 알아요","songEng":"I Know"},
    {"id":1378,"singerKor":"서태지와 아이들","singerEng":"Seo Taiji and Boys","songKor":"환상속의 그대","songEng":"Fantasy You and Me"},
    {"id":1379,"singerKor":"선미 (SUNMI)","singerEng":"Sunmi","songKor":"사이렌 (Si)","songEng":"Si"},
    {"id":1380,"singerKor":"선미 (SUNMI)","singerEng":"Sunmi","songKor":"주인공","songEng":"Protagonist"},
    {"id":1381,"singerKor":"선미","singerEng":"Sunmi","songKor":"24시간이 모자라","songEng":"Twenty Four Hours is Not Enough"},
    {"id":1382,"singerKor":"선미","singerEng":"Sunmi","songKor":"가시나","songEng":"Gashina"},
    {"id":1383,"singerKor":"선미","singerEng":"Sunmi","songKor":"보라빛 밤 (pporappippam)","songEng":"Violet Night"},
    {"id":1384,"singerKor":"선미","singerEng":"Sunmi","songKor":"보름달 (Feat. Lena)","songEng":"Full Moon (Feat. Lena)"},
    {"id":1385,"singerKor":"선미","singerEng":"Sunmi","songKor":"열이올라요 (Heart Burn)","songEng":"Heart Burn."},
    {"id":1386,"singerKor":"선예_ 임슬옹","singerEng":"Sunye_ Im Seulong","songKor":"대낮에 한 이별","songEng":"Midday Farewell"},
    {"id":1387,"singerKor":"성시경","singerEng":"Sung Si-kyung","songKor":"너의 모든 순간","songEng":"Every Moment of You"},
    {"id":1388,"singerKor":"성시경","singerEng":"Sung Si-kyung","songKor":"04 안녕 나의 사랑","songEng":"Goodbye My Love"},
    {"id":1389,"singerKor":"성시경","singerEng":"Sung Si-kyung","songKor":"아픈 나를","songEng":"Painful Me"},
    {"id":1390,"singerKor":"성시경","singerEng":"Sung Si-kyung","songKor":"거리에서","songEng":"Street."},
    {"id":1391,"singerKor":"성시경","singerEng":"Sung Si-kyung.","songKor":"난 좋아","songEng":"I'm Good."},
    {"id":1392,"singerKor":"성시경","singerEng":"Sung Si-kyung","songKor":"너의 모든 순간","songEng":"Every Moment of You"},
    {"id":1393,"singerKor":"성시경","singerEng":"Sung Si-kyung.","songKor":"넌 감동이었어","songEng":"You were as a Heart Fluttering Moment."},
    {"id":1394,"singerKor":"성시경","singerEng":"Sung Sikyung","songKor":"미소천사","songEng":"Angel Smile"},
    {"id":1395,"singerKor":"성시경","singerEng":"Seong Sikyung","songKor":"아픈 나를","songEng":"Painful Me"},
    {"id":1396,"singerKor":"성시경","singerEng":"Sung Si-kyung","songKor":"우린 제법 잘 어울려요","songEng":"We go well together"},
    {"id":1397,"singerKor":"성시경","singerEng":"Sung Si Kyung","songKor":"제주도의 푸른 밤","songEng":"Jeju Island's Blue Night"},
    {"id":1398,"singerKor":"성시경","singerEng":"Sung Si-kyung","songKor":"처음처럼","songEng":"As If It's Your First Time."},
    {"id":1399,"singerKor":"성시경","singerEng":"Sung Si-kyung","songKor":"한번 더 이별","songEng":"Once Again Goodbye"},
    {"id":1400,"singerKor":"성시경_ 박효신_ 서인국_ 빅스_ 여동생","singerEng":"Sung Si-kyung_ Park Hyo-shin_ Seo In-guk_ VIXX_ Yeo Dong-saeng","songKor":"겨울 고백","songEng":"Winter Confession"},
    {"id":1401,"singerKor":"성시경_ 박효신_ 이석훈_ 서인국_ VIXX","singerEng":"Seong Sikyung_ Park Hyoshin_ Lee Seokhoon_ Seo In-guk_ VIXX","songKor":"크리스마스니까","songEng":"Since It's Christmas"},
    {"id":1402,"singerKor":"성시경_아이유(IU)","singerEng":"Sung Si-kyung_ IU","songKor":"그대네요","songEng":"You're My"},
    {"id":1403,"singerKor":"세븐 (SE7EN)","singerEng":"Se7en","songKor":"와줘..","songEng":"Come to Me"},
    {"id":1404,"singerKor":"세븐","singerEng":"Seven","songKor":"Better Together","songEng":"Better Together"},
    {"id":1405,"singerKor":"세븐","singerEng":"Seven","songKor":"난 알아요","songEng":"I Know."},
    {"id":1406,"singerKor":"세븐","singerEng":"Seven","songKor":"내가 노래를 못해도","songEng":"I Can't Sing"},
    {"id":1407,"singerKor":"세븐","singerEng":"Seven","songKor":"라라라","songEng":"Lalala"},
    {"id":1408,"singerKor":"세븐","singerEng":"Seven","songKor":"문신","songEng":"Munshin"},
    {"id":1409,"singerKor":"세븐","singerEng":"Seven","songKor":"한번 단 한번","songEng":"Once Just Once"},
    {"id":1410,"singerKor":"세븐틴(SEVENTEEN)","singerEng":"Seventeen.","songKor":"Darl+ing","songEng":"Darling"},
    {"id":1411,"singerKor":"소녀시대 (GIRLS' GENERATION)","singerEng":"Girls' Generation","songKor":"FOREVER 1","songEng":"Forever."},
    {"id":1412,"singerKor":"소녀시대","singerEng":"Girls' Generation","songKor":"FOREVER 1","songEng":"Forever"},
    {"id":1413,"singerKor":"소녀시대","singerEng":"Girls' Generation","songKor":"Baby Baby","songEng":"Baby Baby"},
    {"id":1414,"singerKor":"소녀시대","singerEng":"Girls' Generation","songKor":"Chocolate Love [Retro Pop Ver.]","songEng":"Chocolate Love Retro Pop Ver"},
    {"id":1415,"singerKor":"소녀시대","singerEng":"Girls' Generation","songKor":"Gee","songEng":"Gee"},
    {"id":1416,"singerKor":"소녀시대","singerEng":"Girls' Generation","songKor":"I Got A Boy","songEng":"I Got A Boy"},
    {"id":1417,"singerKor":"소녀시대","singerEng":"Girls' Generation","songKor":"Kissing You","songEng":"Kissing You"},
    {"id":1418,"singerKor":"소녀시대","singerEng":"Girls' Generation","songKor":"Lion Heart","songEng":"Lion Heart"},
    {"id":1419,"singerKor":"소녀시대","singerEng":"Girls' Generation","songKor":"Mr.Mr.","songEng":"MrMr"},
    {"id":1420,"singerKor":"소녀시대","singerEng":"Girls' Generation","songKor":"Oh!","songEng":"Oh!"},
    {"id":1421,"singerKor":"소녀시대","singerEng":"Girls' Generation","songKor":"PARTY","songEng":"Party"},
    {"id":1422,"singerKor":"소녀시대","singerEng":"Girls' Generation","songKor":"Run Devil Run","songEng":"Run Devil Run"},
    {"id":1423,"singerKor":"소녀시대","singerEng":"Girls' Generation","songKor":"The Boys","songEng":"The Boys"},
    {"id":1424,"singerKor":"소녀시대","singerEng":"Girls' Generation","songKor":"다시 만난 세계 (Into The New World)","songEng":"Into The New World"},
    {"id":1425,"singerKor":"소녀시대","singerEng":"Girls' Generation","songKor":"소원을 말해봐 (Genie)","songEng":"Genie"},
    {"id":1426,"singerKor":"소녀시대","singerEng":"Girls' Generation","songKor":"훗 (Hoot)","songEng":"Hoot"},
    {"id":1427,"singerKor":"소녀시대_태티서","singerEng":"Girls' Generation-TTS","songKor":"Twinkle","songEng":"Twinkle"},
    {"id":1428,"singerKor":"소녀온탑","singerEng":"Girl on Top","songKor":"같은 곳에서","songEng":"Same Place."},
    {"id":1429,"singerKor":"소유","singerEng":"Soyou","songKor":"I Miss You","songEng":"I Miss You"},
    {"id":1430,"singerKor":"소유_ 권순일 (어반 자카파)_ 박용인 (어반 자카파)","singerEng":"Soyou_ Kwon Sunil (Urban Zakapa)_ Park Yongin (Urban Zakapa)","songKor":"틈","songEng":"Gap"},
    {"id":1431,"singerKor":"소유_ 권정열","singerEng":"Soyou Kwon Jeong Yeol","songKor":"어깨","songEng":"Shoulder"},
    {"id":1432,"singerKor":"소유_ 기리보이_ NO.MERCY (노머시)","singerEng":"Soyou_ Giriboy_ No Mercy","songKor":"팔베개 (Feat. 기현) (Pillow)","songEng":"Pillow (Feat. Kihyun)"},
    {"id":1433,"singerKor":"소유_ 매드클라운","singerEng":"Soyou Mad Clown","songKor":"착해 빠졌어 (Stupid In Love)","songEng":"Stupid In Love"},
    {"id":1434,"singerKor":"소유_ 백현 (BAEKHYUN)","singerEng":"The name of the Korean artist in English is Soyou Baekhyun.","songKor":"비가와 (Rain)","songEng":"Rainy Season"},
    {"id":1435,"singerKor":"소유_ 브라더수","singerEng":"Soyou BrotherSu","songKor":"모르나봐","songEng":"Don't Know What To Do"},
    {"id":1436,"singerKor":"소유_ 정기고","singerEng":"Soyou Junggigo","songKor":"썸 (Feat. 릴보이 Of 긱스 )","songEng":"Some (Feat. Lil Boi Of Geeks)"},
    {"id":1437,"singerKor":"소정 (레이디스 코드)","singerEng":"Sojung (Ladies Code)","songKor":"함께 했는데 이별은 나 혼자인 거야","songEng":"Illegal"},
    {"id":1438,"singerKor":"소찬휘","singerEng":"So Chan Whee","songKor":"Change","songEng":"Change"},
    {"id":1439,"singerKor":"소찬휘","singerEng":"So Chan-hui","songKor":"Tears","songEng":"Tears"},
    {"id":1440,"singerKor":"손 담비_ 가희","singerEng":"Son Dam-bi_ Gahee","songKor":"Bad Boy","songEng":"Bad Boy"},
    {"id":1441,"singerKor":"손담비 & 애프터스쿨","singerEng":"Son Dambi & After School","songKor":"아몰레드 (AMOLED)","songEng":"AMOLED"},
    {"id":1442,"singerKor":"손담비","singerEng":"Sunmi.","songKor":"queen","songEng":"Queen"},
    {"id":1443,"singerKor":"손담비","singerEng":"Son Dam-bi","songKor":"미쳤어 (Feat. 에릭)","songEng":"Crazy (Feat. Eric)"},
    {"id":1444,"singerKor":"손담비","singerEng":"Son Dam-bi","songKor":"토요일밤에","songEng":"Saturday Night."},
    {"id":1445,"singerKor":"손호영","singerEng":"Son Hoyoung","songKor":"사랑은 이별을 데리고 오다 (New Ver.)","songEng":"Love brings farewell (New Ver.)"},
    {"id":1446,"singerKor":"손호영","singerEng":"Son Hoyoung","songKor":"운다","songEng":"Crying."},
    {"id":1447,"singerKor":"솔리드 (Solid)","singerEng":"Solid","songKor":"이 밤의 끝을 잡고 (R&B Ballad)","songEng":"Hold Me Tight"},
    {"id":1448,"singerKor":"송골매","singerEng":"Song Golmae","songKor":"모두 다 사랑하리","songEng":"Everyone Will Love"},
    {"id":1449,"singerKor":"송골매","singerEng":"Songgolmae","songKor":"하늘나라 우리님 1","songEng":"Heaven Is Our Lord"},
    {"id":1450,"singerKor":"송민호","singerEng":"Song Minho","songKor":"겁 (Feat. 태양)","songEng":"Afraid (Feat. TAEYANG)"},
    {"id":1451,"singerKor":"송민호_ 지코 (ZICO)","singerEng":"Song Minho_ Zico","songKor":"Okey Dokey","songEng":"Okey Dokey"},
    {"id":1452,"singerKor":"송지은","singerEng":"Song Ji Eun","songKor":"미친거니 (Feat. 방용국)","songEng":"Crazy (Feat. Bang Yong-guk)"},
    {"id":1453,"singerKor":"송창식","singerEng":"Song Chang-sik","songKor":"참새의 하루","songEng":"Sparrow's Day"},
    {"id":1454,"singerKor":"송하예","singerEng":"Song Hye-ye.","songKor":"그대를 알고","songEng":"Knowing You"},
    {"id":1455,"singerKor":"송하예","singerEng":"Song Hye-ye.","songKor":"새 사랑","songEng":"New Love"},
    {"id":1456,"singerKor":"숀 (SHAUN)","singerEng":"Shaun","songKor":"Way Back Home","songEng":"Way Back Home"},
    {"id":1457,"singerKor":"수연","singerEng":"Suyeon","songKor":"높은 하늘아","songEng":"High Sky."},
    {"id":1458,"singerKor":"수지","singerEng":"Suzy","songKor":"행복한 척","songEng":"Happy Pretending"},
    {"id":1459,"singerKor":"수지_ 백현 (BAEKHYUN)","singerEng":"Suzy_ Baekhyun","songKor":"Dream","songEng":"Dream"},
    {"id":1460,"singerKor":"순순희","singerEng":"Sunsunhee","songKor":"서면역에서","songEng":"Seomyeon Station"},
    {"id":1461,"singerKor":"슈가","singerEng":"Suga","songKor":"Shine","songEng":"Shine"},
    {"id":1462,"singerKor":"슈가","singerEng":"Sugar","songKor":"현명한 이별","songEng":"Wise Farewell"},
    {"id":1463,"singerKor":"슈퍼비 (Superbee)","singerEng":"Superbee","songKor":"냉탕에 상어 (Feat. 블랙넛)","songEng":"Shark in the Cold Bath (Feat. Black Nut)"},
    {"id":1464,"singerKor":"슈퍼주니어","singerEng":"Super Junior","songKor":"Mr. Simple","songEng":"Mr Simple"},
    {"id":1465,"singerKor":"슈퍼주니어","singerEng":"Super Junior","songKor":"U","songEng":"U"},
    {"id":1466,"singerKor":"슈퍼주니어","singerEng":"Super Junior","songKor":"너라고 (It`s You)","songEng":"It's You"},
    {"id":1467,"singerKor":"슈퍼주니어","singerEng":"Super Junior","songKor":"쏘리 쏘리 (SORRY_ SORRY)","songEng":"Sorry Sorry"},
    {"id":1468,"singerKor":"슈프림팀(Supreme Team)","singerEng":"Supreme Team","songKor":"땡땡땡","songEng":"Ddang Ddang Ddang"},
    {"id":1469,"singerKor":"슈프림팀(Supreme Team)_영준","singerEng":"Supreme Team_ Youngjun","songKor":"그땐 그땐 그땐","songEng":"At that time."},
    {"id":1470,"singerKor":"스윗 소로우","singerEng":"Sweet Sorrow","songKor":"아무리 생각해도 난 너를","songEng":"No word found."},
    {"id":1471,"singerKor":"스윙스_ 에일리","singerEng":"Swings_ Ailee","songKor":"A Real Man","songEng":"A Real Man"},
    {"id":1472,"singerKor":"스카이","singerEng":"Sky","songKor":"영원","songEng":"Forever"},
    {"id":1473,"singerKor":"스탠딩 에그","singerEng":"Standing Egg","songKor":"여름밤에 우린","songEng":"Summer Night's Dream"},
    {"id":1474,"singerKor":"스테이씨(STAYC)","singerEng":"StayC","songKor":"RUN2U","songEng":"RUN2U"},
    {"id":1475,"singerKor":"승리","singerEng":"Seungri","songKor":"셋 셀테니 (1_ 2_ 3!)","songEng":"Set Me Free"},
    {"id":1476,"singerKor":"시인과 촌장","singerEng":"Poet and Village Head","songKor":"가시나무","songEng":"Gashinamu."},
    {"id":1477,"singerKor":"시크릿","singerEng":"Secret","songKor":"Madonna","songEng":"Paparazzi"},
    {"id":1478,"singerKor":"시크릿","singerEng":"Secret","songKor":"Magic","songEng":"Abracadabra"},
    {"id":1479,"singerKor":"시크릿","singerEng":"Secret","songKor":"POISON","songEng":"Poison."},
    {"id":1480,"singerKor":"시크릿","singerEng":"Secret","songKor":"YooHoo","songEng":"Yoohoo"},
    {"id":1481,"singerKor":"시크릿","singerEng":"Secret","songKor":"별빛달빛","songEng":"Starlight Moonlight"},
    {"id":1482,"singerKor":"시크릿","singerEng":"Secret","songKor":"사랑은 Move","songEng":"Love is Move."},
    {"id":1483,"singerKor":"시크릿(Secret)","singerEng":"Secret","songKor":"샤이보이","songEng":"Shy Boy"},
    {"id":1484,"singerKor":"식케이 (Sik-K)_ pH-1_ 박재범_ 김하온 (HAON)","singerEng":"Sik-K_ pH-1_ Jay Park_ Haon","songKor":"깡 Official Remix","songEng":"Gang Official Remix"},
    {"id":1485,"singerKor":"신승훈","singerEng":"Shin Seung-hun","songKor":"I Believe","songEng":"I Believe"},
    {"id":1486,"singerKor":"신승훈","singerEng":"Shin Seung-hoon","songKor":"Loving You","songEng":"Loving You"},
    {"id":1487,"singerKor":"신승훈","singerEng":"Shin Seung-hoon","songKor":"그런 날이 오겠죠","songEng":"That Day_ Will Come."},
    {"id":1488,"singerKor":"신승훈","singerEng":"Shin Seung-hoon","songKor":"날 울리지마","songEng":"Don't Make Me Cry"},
    {"id":1489,"singerKor":"신승훈","singerEng":"Shin Seung-hoon","songKor":"널 사랑하니까","songEng":"Because I Love You"},
    {"id":1490,"singerKor":"신승훈","singerEng":"Shin Seung-hoon","songKor":"미소속에 비친 그대","songEng":"Reflecting You in My Smile"},
    {"id":1491,"singerKor":"신승훈","singerEng":"Shin Seung-hun","songKor":"보이지 않는 사랑","songEng":"Unseen Love"},
    {"id":1492,"singerKor":"신승훈","singerEng":"Shin Seung Hun","songKor":"엄마야","songEng":"Mommy"},
    {"id":1493,"singerKor":"신승훈","singerEng":"Shin Seung-hoon","songKor":"전설 속의 누군가처럼","songEng":"Like Someone in a Legend"},
    {"id":1494,"singerKor":"신승훈","singerEng":"Shin Seung-hoon","songKor":"지킬 수 없는 약속","songEng":"Unbreakable Promise"},
    {"id":1495,"singerKor":"신예영","singerEng":"Shin Ye-Young","songKor":"네가 보고 싶은 건 자연스러운 거겠지","songEng":"Maybe the song is called Natural?"},
    {"id":1496,"singerKor":"신예영","singerEng":"Sinyeyoung","songKor":"마지막 사랑","songEng":"Last Love"},
    {"id":1497,"singerKor":"신예영","singerEng":"Shin Ye-young","songKor":"우리 왜 헤어져야 해","songEng":"WhyDoWeHaveToBreakUp"},
    {"id":1498,"singerKor":"신용재 (포맨)","singerEng":"Shin Yong Jae (Poignant Men)","songKor":"가수가 된 이유","songEng":"Becoming a Singer"},
    {"id":1499,"singerKor":"신지","singerEng":"Shinji","songKor":"사랑한다는 한마디","songEng":"Love confession"},
    {"id":1500,"singerKor":"신해철","singerEng":"Shin Hae Chul","songKor":"아주 가끔은","songEng":"Very rarely."},
    {"id":1501,"singerKor":"신현희와김루트","singerEng":"Shin Hyun Hee and Kim Root","songKor":"오빠야","songEng":"Oppaya"},
    {"id":1502,"singerKor":"신형원","singerEng":"Shin Hyeong Won","songKor":"유리벽","songEng":"Glass Wall"},
    {"id":1503,"singerKor":"신혜성 & 린","singerEng":"Shin Hye-sung & Lyn","songKor":"사랑...후에","songEng":"Love...After."},
    {"id":1504,"singerKor":"신혜성","singerEng":"Shin Hye Sung","songKor":"사랑...후에","songEng":"Love...After."},
    {"id":1505,"singerKor":"신혜성","singerEng":"Shin Hye Sung","songKor":"신혜성 같은생각","songEng":"Like a Fool"},
    {"id":1506,"singerKor":"신화 (Shinhwa)","singerEng":"Shinhwa","songKor":"Hey  Come On!","songEng":"Come On!"},
    {"id":1507,"singerKor":"신화 (Shinhwa)","singerEng":"Shinhwa","songKor":"I Pray 4 U(이누야샤2기OP)","songEng":"I Pray 4 U"},
    {"id":1508,"singerKor":"신화 (Shinhwa)","singerEng":"Shinhwa","songKor":"Perfect Man","songEng":"Perfect Man"},
    {"id":1509,"singerKor":"신화","singerEng":"Shinhwa","songKor":"Brand New","songEng":"Brand New"},
    {"id":1510,"singerKor":"신화","singerEng":"Shinhwa","songKor":"Once In A Lifetime","songEng":"Once In A Lifetime"},
    {"id":1511,"singerKor":"신화","singerEng":"Shinhwa","songKor":"ONLY ONE","songEng":"Only One"},
    {"id":1512,"singerKor":"신화","singerEng":"Shinhwa","songKor":"너의 결혼식","songEng":"Your Wedding."},
    {"id":1513,"singerKor":"심수봉","singerEng":"Sim Soo Bong","songKor":"당신은 누구시길래","songEng":"Who Are You by G-Dragon (GD) feat. Jennie Kim of BLACKPINK"},
    {"id":1514,"singerKor":"싸이 (PSY)","singerEng":"PSY","songKor":"Celeb","songEng":"Celeb."},
    {"id":1515,"singerKor":"싸이 (PSY)","singerEng":"PSY","songKor":"I LUV IT","songEng":"ILUVIT"},
    {"id":1516,"singerKor":"싸이 (PSY)","singerEng":"PSY","songKor":"New Face","songEng":"New Face"},
    {"id":1517,"singerKor":"싸이 (Psy)","singerEng":"Psy","songKor":"03 새","songEng":"Bird"},
    {"id":1518,"singerKor":"싸이 (PSY)","singerEng":"PSY","songKor":"That That (prod. & feat. SUGA of BTS)","songEng":"That That"},
    {"id":1519,"singerKor":"싸이","singerEng":"Psy","songKor":"That That (prod. & feat. SUGA of BTS)","songEng":"That That (Prod & Feat SUGA of BTS)"},
    {"id":1520,"singerKor":"싸이","singerEng":"Psy","songKor":"DADDY (Feat. CL of 2NE1)","songEng":"DADDY"},
    {"id":1521,"singerKor":"싸이","singerEng":"PSY","songKor":"GENTLEMAN","songEng":"Gentleman"},
    {"id":1522,"singerKor":"싸이","singerEng":"Psy","songKor":"강남스타일","songEng":"Gangnam Style"},
    {"id":1523,"singerKor":"싸이","singerEng":"PSY","songKor":"낙원","songEng":"Paradise"},
    {"id":1524,"singerKor":"싸이","singerEng":"Psy","songKor":"뜨거운 안녕 (Feat. 성시경)","songEng":"Hot Goodbye (Feat. Sung Si-kyung)"},
    {"id":1525,"singerKor":"싸이","singerEng":"PSY","songKor":"아름다운 이별 2 (Feat. 이재훈)","songEng":"Beautiful Goodbye 2 (Feat. Lee Jae Hoon)"},
    {"id":1526,"singerKor":"싸이","singerEng":"Psy","songKor":"어땠을까 (Feat. 박정현)","songEng":"What If (Feat. Park Jung Hyun)"},
    {"id":1527,"singerKor":"싸이","singerEng":"PSY","songKor":"연예인","songEng":"Celebrity"},
    {"id":1528,"singerKor":"싸이","singerEng":"Psy","songKor":"챔피언","songEng":"Champion"},
    {"id":1529,"singerKor":"싸이(Psy)","singerEng":"Psy","songKor":"끝","songEng":"End"},
    {"id":1530,"singerKor":"싹쓰리 (유두래곤 & 린다G & 비룡)","singerEng":"Ssak3 (Yoo Jae-suk_ Lee Hyori_ Rain)","songKor":"여름 안에서 (Covered by 싹쓰리) (Feat. 황광희)","songEng":"In the Summer (Covered by SSak3) (Feat. Hwang Kwanghee)"},
    {"id":1531,"singerKor":"싹쓰리 (유두래곤_ 린다G_ 비룡)","singerEng":"Ssak3 (Yoo Jae-suk_ Lee Hyori_ Rain)","songKor":"그 여름을 틀어줘","songEng":"Play that summer."},
    {"id":1532,"singerKor":"싹쓰리 (유두래곤_ 린다G_ 비룡)","singerEng":"Ssak3 (Yoo Jae-suk_ Lee Hyori_ Rain)","songKor":"다시 여기 바닷가","songEng":"On the Beach Again"},
    {"id":1533,"singerKor":"써니힐","singerEng":"Sunny Hill","songKor":"Goodbye To Romance","songEng":"Goodbye To Romance"},
    {"id":1534,"singerKor":"써니힐","singerEng":"Sunny Hill","songKor":"Midnight Circus","songEng":"Midnight Circus"},
    {"id":1535,"singerKor":"써니힐","singerEng":"Sunmi","songKor":"베짱이 찬가","songEng":"Bejjangi Changga"},
    {"id":1536,"singerKor":"씨스타 (SISTAR)","singerEng":"SISTAR","songKor":"SHAKE IT","songEng":"SHAKE IT."},
    {"id":1537,"singerKor":"씨스타","singerEng":"Sistar","songKor":"Give It To Me","songEng":"Give It To Me"},
    {"id":1538,"singerKor":"씨스타","singerEng":"Sistar","songKor":"I Like That","songEng":"I Like That"},
    {"id":1539,"singerKor":"씨스타","singerEng":"Sistar","songKor":"I Swear","songEng":"I Swear"},
    {"id":1540,"singerKor":"씨스타","singerEng":"Sistar","songKor":"LONELY","songEng":"Lonely"},
    {"id":1541,"singerKor":"씨스타","singerEng":"Sistar","songKor":"Loving U (러빙유)","songEng":"Loving U"},
    {"id":1542,"singerKor":"씨스타","singerEng":"Sistar","songKor":"So Cool (쏘쿨)","songEng":"So Cool"},
    {"id":1543,"singerKor":"씨스타","singerEng":"Sistar","songKor":"Touch My Body","songEng":"Touch My Body"},
    {"id":1544,"singerKor":"씨스타","singerEng":"Sistar","songKor":"가식걸","songEng":"Gashina"},
    {"id":1545,"singerKor":"씨스타","singerEng":"Sistar","songKor":"나 혼자 (Alone)","songEng":"Alone"},
    {"id":1546,"singerKor":"씨스타","singerEng":"Sistar","songKor":"넌 너무 야해 (The Way You Make Me Melt) (Feat. 긱스)","songEng":"The Way You Make Me Melt (Feat. 긱스)"},
    {"id":1547,"singerKor":"씨스타","singerEng":"Sistar","songKor":"바빠 (Bad Boy)","songEng":"Bad Boy"},
    {"id":1548,"singerKor":"씨스타(Sistar)","singerEng":"Sistar","songKor":"Push Push (푸쉬푸쉬)","songEng":"Push Push"},
    {"id":1549,"singerKor":"씨스타(Sistar)","singerEng":"Sistar","songKor":"So Cool (쏘쿨)","songEng":"So Cool"},
    {"id":1550,"singerKor":"씨스타(Sistar)","singerEng":"Sistar","songKor":"니까짓게","songEng":"Nikakjittge"},
    {"id":1551,"singerKor":"씨스타19","singerEng":"Sistar19","songKor":"Ma Boy","songEng":"Ma Boy"},
    {"id":1552,"singerKor":"씨스타19","singerEng":"Sistar19","songKor":"있다 없으니까 (Gone Not Around Any Longer)","songEng":"Gone Not Around Any Longer"},
    {"id":1553,"singerKor":"씨야 & 다비치 & 블랙펄","singerEng":"Seeya & Davichi & Black Pearl","songKor":"Blue Moon","songEng":"Blue Moon"},
    {"id":1554,"singerKor":"씨야 & 다비치 & 티아라 (T-ara)","singerEng":"SeeYa & Davichi & T-ara","songKor":"여성시대","songEng":"Women's Generation"},
    {"id":1555,"singerKor":"씨야","singerEng":"SeeYa","songKor":"결혼할까요","songEng":"Shoulder Dance"},
    {"id":1556,"singerKor":"씨야","singerEng":"SeeYa","songKor":"구두","songEng":"Shoes"},
    {"id":1557,"singerKor":"씨야","singerEng":"SeeYa","songKor":"그 놈 목소리","songEng":"That Guy's Voice"},
    {"id":1558,"singerKor":"씨야","singerEng":"SeeYa","songKor":"미친 사랑의 노래","songEng":"Crazy Love Song"},
    {"id":1559,"singerKor":"씨야","singerEng":"SeeYa","songKor":"사랑의 인사","songEng":"Greeting of Love"},
    {"id":1560,"singerKor":"씨야","singerEng":"SeeYa","songKor":"여인의 향기","songEng":"Scent of a Woman"},
    {"id":1561,"singerKor":"씨야","singerEng":"Siya","songKor":"원더우먼","songEng":"Wonder Woman"},
    {"id":1562,"singerKor":"씨야&브라운아이드걸스","singerEng":"Seeya & Brown Eyed Girls","songKor":"정","songEng":"Jung"},
    {"id":1563,"singerKor":"씨야(SeeYa)","singerEng":"Seeya","songKor":"여인의향기","songEng":"Scent of a Woman"},
    {"id":1564,"singerKor":"씨엔블루","singerEng":"CNBLUE","songKor":"Can`t Stop","songEng":"Can't Stop"},
    {"id":1565,"singerKor":"씨엔블루","singerEng":"CNBLUE","songKor":"I`m Sorry","songEng":"Sorry"},
    {"id":1566,"singerKor":"씨엔블루","singerEng":"CNBLUE","songKor":"외톨이야","songEng":"Lonely"},
    {"id":1567,"singerKor":"씨엔블루(CNBLUE)","singerEng":"CNBLUE","songKor":"Love","songEng":"Love."},
    {"id":1568,"singerKor":"씨엔블루(CNBLUE)","singerEng":"CNBLUE","songKor":"사랑 빛","songEng":"Love Light"},
    {"id":1569,"singerKor":"씨엔블루(CNBLUE)","singerEng":"CNBLUE","songKor":"직감","songEng":"Intuition"},
    {"id":1570,"singerKor":"아넌딜라이트 (Anandelight) & Unofficialboyy & BE'O (비오) & 지구인 & Mudd The Student","singerEng":"I'm sorry_ but there is no single Korean artist named 아넌딜라이트 (Anandelight) & Unofficialboyy & BE'O (비오) & 지구인 & Mudd The Student. These are multiple artists.","songKor":"쉬어 (Feat. MINO) (Prod. by GRAY)","songEng":"Rest (Feat. MINO) (Prod. by GRAY)"},
    {"id":1571,"singerKor":"아소토 유니온","singerEng":"Asoto Union","songKor":"Think About'chu","songEng":"Think About You"},
    {"id":1572,"singerKor":"아웃사이더","singerEng":"Outsider","songKor":"슬피 우는 새 (Feat. 이수영)","songEng":"Sadly Crying Bird (Feat. Lee Soo-young)"},
    {"id":1573,"singerKor":"아웃사이더","singerEng":"Outsider","songKor":"외톨이","songEng":"Lonely"},
    {"id":1574,"singerKor":"아웃사이더","singerEng":"Outsider","songKor":"주변인","songEng":"Neighbor"},
    {"id":1575,"singerKor":"아이","singerEng":"Ailee","songKor":"봄날","songEng":"Spring Day"},
    {"id":1576,"singerKor":"아이비 (Ivy)","singerEng":"Ivy","songKor":"눈물아 안녕","songEng":"Goodbye_ Tears."},
    {"id":1577,"singerKor":"아이비","singerEng":"Ivy","songKor":"유혹의 소나타","songEng":"Sonata of Temptation."},
    {"id":1578,"singerKor":"아이오아이 (I.O.I)","singerEng":"I.O.I","songKor":"너무너무너무","songEng":"Very Very Very"},
    {"id":1579,"singerKor":"아이오아이 (I.O.I)","singerEng":"I.O.I","songKor":"소나기","songEng":"Rain Shower"},
    {"id":1580,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"Blueming","songEng":"Blueming"},
    {"id":1581,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"Celebrity","songEng":"Celebrity"},
    {"id":1582,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"Into the I-LAND","songEng":"Into the I-LAND"},
    {"id":1583,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"Love poem","songEng":"Love poem"},
    {"id":1584,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"strawberry moon","songEng":"Strawberry Moon"},
    {"id":1585,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"가을 아침","songEng":"Autumn Morning"},
    {"id":1586,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"겨울잠","songEng":"Winter Sleep"},
    {"id":1587,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"금요일에 만나요 (Feat. 장이정 Of HISTORY)","songEng":"Let's Meet on Friday (Feat. Jang Yi-jeong Of HISTORY)"},
    {"id":1588,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"나의 옛날이야기","songEng":"MyOldStory"},
    {"id":1589,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"내 손을 잡아","songEng":"Hold My Hand."},
    {"id":1590,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"너","songEng":"You by Taeyeon."},
    {"id":1591,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"너랑 나","songEng":"You and Me"},
    {"id":1592,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"드라마","songEng":"Drama"},
    {"id":1593,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"라일락","songEng":"Lilac"},
    {"id":1594,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"러브레터","songEng":"Love Letter"},
    {"id":1595,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"마음","songEng":"Heart"},
    {"id":1596,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"마음을 드려요","songEng":"Give your heart."},
    {"id":1597,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"미리 메리 크리스마스 (feat. 천둥 Of MBLAQ)","songEng":"Merry Christmas in Advance (feat. Thunder of MBLAQ)"},
    {"id":1598,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"밤편지","songEng":"Dear My Love"},
    {"id":1599,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"분홍신","songEng":"Pink Shoes."},
    {"id":1600,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"삐삐","songEng":"BBIBBI"},
    {"id":1601,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"사랑이 잘 (With 오혁)","songEng":"Love is So Nice (With Ohyuk)"},
    {"id":1602,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"스물셋","songEng":"Twenty Three"},
    {"id":1603,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"아이와 나의 바다","songEng":"AI and My Sea"},
    {"id":1604,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"얼음꽃 (Feat. 김세황)","songEng":"Ice Flower (Feat. Kim Sehwang)"},
    {"id":1605,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"에잇(Prod.&Feat. SUGA of BTS)","songEng":"Eight (Prod&Feat. SUGA of BTS)"},
    {"id":1606,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"이 지금","songEng":"Right Now"},
    {"id":1607,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"잔소리 (With 2AM 슬옹)","songEng":"Nagging"},
    {"id":1608,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"좋은 날","songEng":"Good Day."},
    {"id":1609,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"팔레트 (Feat. G-DRAGON)","songEng":"Palette (Feat. G-DRAGON)"},
    {"id":1610,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"푸르던","songEng":"Pur Duen"},
    {"id":1611,"singerKor":"아이유 (IU)","singerEng":"IU","songKor":"하루 끝","songEng":"End of the Day"},
    {"id":1612,"singerKor":"아이유","singerEng":"IU","songKor":"Blueming","songEng":"Blueming"},
    {"id":1613,"singerKor":"아이유","singerEng":"IU","songKor":"Celebrity","songEng":"Celebrity"},
    {"id":1614,"singerKor":"아이유","singerEng":"IU","songKor":"Love poem","songEng":"Love poem."},
    {"id":1615,"singerKor":"아이유","singerEng":"IU","songKor":"strawberry moon","songEng":"Strawberry Moon"},
    {"id":1616,"singerKor":"아이유","singerEng":"IU","songKor":"겨울잠","songEng":"Winter Sleep"},
    {"id":1617,"singerKor":"아이유","singerEng":"IU","songKor":"내 손을 잡아","songEng":"Hold My Hand"},
    {"id":1618,"singerKor":"아이유","singerEng":"IU","songKor":"드라마","songEng":"Drama."},
    {"id":1619,"singerKor":"아이유","singerEng":"IU","songKor":"라일락","songEng":"Lilac"},
    {"id":1620,"singerKor":"아이유","singerEng":"IU","songKor":"에잇(Prod.&Feat. SUGA of BTS)","songEng":"Eight (Prod&Feat. SUGA of BTS)"},
    {"id":1621,"singerKor":"아이유(IU)","singerEng":"IU","songKor":"Love poem","songEng":"Love poem"},
    {"id":1622,"singerKor":"아이유(IU)","singerEng":"IU","songKor":"마음을 드려요","songEng":"Give Heart to Me"},
    {"id":1623,"singerKor":"악동클럽","singerEng":"Akdong Musician","songKor":"Remember","songEng":"Remember"},
    {"id":1624,"singerKor":"안상수","singerEng":"An Sang Soo","songKor":"영원히 내게","songEng":"Forever with You"},
    {"id":1625,"singerKor":"안재욱","singerEng":"An Jaewook","songKor":"친구","songEng":"Friend."},
    {"id":1626,"singerKor":"알렉스","singerEng":"Alex","songKor":"화분 (Alex Ver.)","songEng":"Woodcutter and The Fairy (Alex Ver)"},
    {"id":1627,"singerKor":"알리","singerEng":"Ali","songKor":"지우개","songEng":"Eraser"},
    {"id":1628,"singerKor":"애프터스쿨","singerEng":"After School","songKor":"Diva","songEng":"Diva."},
    {"id":1629,"singerKor":"애프터스쿨","singerEng":"After School","songKor":"Shampoo","songEng":"Shampoo"},
    {"id":1630,"singerKor":"애프터스쿨","singerEng":"After School","songKor":"너 때문에","songEng":"Because of You"},
    {"id":1631,"singerKor":"애프터스쿨","singerEng":"After School","songKor":"뱅(Bang) !","songEng":"Bang"},
    {"id":1632,"singerKor":"양다일","singerEng":"Yang Da-il","songKor":"고백 (Sorry)","songEng":"Sorry"},
    {"id":1633,"singerKor":"양다일","singerEng":"Yang Da Il","songKor":"미안해","songEng":"Sorry"},
    {"id":1634,"singerKor":"양다일","singerEng":"Yang Dail","songKor":"사랑해도 될까요","songEng":"May I Love You."},
    {"id":1635,"singerKor":"양동근","singerEng":"Yang Dong-geun","songKor":"구리뱅뱅","songEng":"Guri Bang Bang"},
    {"id":1636,"singerKor":"양수경","singerEng":"Yang Soo Kyung","songKor":"바라볼 수 없는 그대","songEng":"Unviewable You"},
    {"id":1637,"singerKor":"양수경","singerEng":"Yang Sookyung","songKor":"이별의 끝은 어디인가요","songEng":"Where is the End of Goodbye"},
    {"id":1638,"singerKor":"양요섭 (비스트)","singerEng":"Yang Yoseob (BEAST)","songKor":"카페인 (Feat. 용준형 Of BEAST)","songEng":"Caffeine (Feat. Yong Junhyung Of BEAST)"},
    {"id":1639,"singerKor":"양요섭_ 은하 (EUNHA)","singerEng":"Yang Yoseob_ Eunha","songKor":"텔레파시 (상수리나무 아래 X 양요섭 X 은하)","songEng":"Telepathy (Under the Shade of a Willow Tree X Yang Yoseob X Eunha)"},
    {"id":1640,"singerKor":"양요섭_ 정은지","singerEng":"Yang Yoseob_ Jung Eunji","songKor":"LOVE DAY (2021) (바른연애 길잡이 X 양요섭_ 정은지)","songEng":"LOVE DAY (2021) (Guidance of Proper Romance X Yang Yo-seob_ Jeong Eun-ji)"},
    {"id":1641,"singerKor":"양요섭_ 정은지","singerEng":"Yang Yo-seob_ Jeong Eun-ji","songKor":"LOVE DAY","songEng":"Love Day"},
    {"id":1642,"singerKor":"양파","singerEng":"Yangpa","songKor":"다 알아요","songEng":"I Know It All"},
    {"id":1643,"singerKor":"양파","singerEng":"Yangpa","songKor":"사랑..그게 뭔데","songEng":"Love What Is It."},
    {"id":1644,"singerKor":"양파","singerEng":"Yangpa","songKor":"아디오","songEng":"Adios."},
    {"id":1645,"singerKor":"양파_ 다비치_ HANNA","singerEng":"Onion_ Davichi_ Hanna","songKor":"사랑은 다 그런거래요","songEng":"Love is like that."},
    {"id":1646,"singerKor":"양희은","singerEng":"Yang Hee Eun","songKor":"하얀 목련","songEng":"White Magnolia"},
    {"id":1647,"singerKor":"어반자카파 (URBAN ZAKAPA)","singerEng":"Urban Zakapa","songKor":"Get (Feat. Beenzino)","songEng":"Get (Feat. Beenzino)"},
    {"id":1648,"singerKor":"어반자카파 (URBAN ZAKAPA)","singerEng":"Urban Zakapa","songKor":"그대 고운 내사랑","songEng":"My Love_ My Beautiful One"},
    {"id":1649,"singerKor":"어반자카파 (URBAN ZAKAPA)","singerEng":"URBAN ZAKAPA","songKor":"그때의 나_ 그때의 우리","songEng":"At that time_ me and you."},
    {"id":1650,"singerKor":"어반자카파 (URBAN ZAKAPA)","singerEng":"URBAN ZAKAPA","songKor":"널 사랑하지 않아","songEng":"I Don't Love You."},
    {"id":1651,"singerKor":"어반자카파 (URBAN ZAKAPA)","singerEng":"Urban Zakapa","songKor":"목요일 밤 (Feat. 빈지노)","songEng":"Thursday Night (Feat. Beenzino)"},
    {"id":1652,"singerKor":"어쿠스틱 콜라보","singerEng":"Acoustic Collaborative","songKor":"묘해_ 너와","songEng":"Mystery_ You and..."},
    {"id":1653,"singerKor":"언니네 이발관","singerEng":"Unnieshairshop","songKor":"˝순간을 믿어요˝","songEng":"I believe in the moment"},
    {"id":1654,"singerKor":"언니쓰","singerEng":"Unnies.","songKor":"Shut Up (Feat. 유희열)","songEng":"Shut Up (Feat. Yoo Hee-yeol)"},
    {"id":1655,"singerKor":"언터쳐블","singerEng":"Untouchable","songKor":"It`s Okay (우리 결혼했어요 화요비 & 환희 커플 주제곡)","songEng":"It's Okay"},
    {"id":1656,"singerKor":"언터쳐블","singerEng":"Untouchable","songKor":"가슴에 살아 (Feat. 나르샤 Of Brown Eyed Girls)","songEng":"Living in the Heart (Feat. Narsha Of Brown Eyed Girls)"},
    {"id":1657,"singerKor":"엄정화","singerEng":"Uhm Jung-hwa","songKor":"D.I.S.C.O (Feat. TOP)","songEng":"D.I.S.C.O (Feat. TOP)"},
    {"id":1658,"singerKor":"엄정화","singerEng":"Um Jung-hwa","songKor":"Escape","songEng":"Escape"},
    {"id":1659,"singerKor":"엄정화","singerEng":"Uhm Jung-hwa","songKor":"Eternity","songEng":"Eternity."},
    {"id":1660,"singerKor":"엄정화","singerEng":"Uhm Jung-hwa","songKor":"Poison","songEng":"Poison"},
    {"id":1661,"singerKor":"엄정화","singerEng":"Uhm Jung-hwa","songKor":"다가라","songEng":"Approach."},
    {"id":1662,"singerKor":"엄정화","singerEng":"Uhm Jung-hwa","songKor":"크로스 (Cross)","songEng":"Cross"},
    {"id":1663,"singerKor":"에디킴","singerEng":"Eddy Kim","songKor":"내 입술 따뜻한 커피처럼 (Feat. 이성경)","songEng":"Like Warm Coffee (Feat. Lee Sung-kyung)"},
    {"id":1664,"singerKor":"에디킴","singerEng":"Eddy Kim","songKor":"이쁘다니까","songEng":"Pretty Hurts"},
    {"id":1665,"singerKor":"에릭남_ 웬디","singerEng":"Eric Nam_ Wendy","songKor":"봄인가 봐 (Spring Love)","songEng":"Spring Love"},
    {"id":1666,"singerKor":"에반(Evan)","singerEng":"Evan","songKor":"남자도... 어쩔 수 없다.","songEng":"Even A Man Can't Do Anything"},
    {"id":1667,"singerKor":"에이트 (8Eight)","singerEng":"Eight.","songKor":"사랑을 잃고 난 노래하네","songEng":"Love After Losing."},
    {"id":1668,"singerKor":"에이트 (8Eight)","singerEng":"Eight","songKor":"심장이 없어","songEng":"Heartless."},
    {"id":1669,"singerKor":"에이트 (8Eight)","singerEng":"8Eight","songKor":"이별이 온다","songEng":"Goodbye is coming"},
    {"id":1670,"singerKor":"에이트 (8Eight)","singerEng":"Eight","songKor":"잘가요 내사랑","songEng":"Goodbye My Love"},
    {"id":1671,"singerKor":"에이트 이현","singerEng":"Eight Lee Hyun","songKor":"30분 전 (feat. 임정희)","songEng":"30 Minutes Ago (feat. Lim Jeong Hee)"},
    {"id":1672,"singerKor":"에일리","singerEng":"Ailee","songKor":"Heaven","songEng":"Heaven"},
    {"id":1673,"singerKor":"에일리","singerEng":"Ailee","songKor":"If You","songEng":"If You"},
    {"id":1674,"singerKor":"에일리","singerEng":"Ailee","songKor":"U & I","songEng":"U and I."},
    {"id":1675,"singerKor":"에일리","singerEng":"Ailee","songKor":"손대지마","songEng":"Don't Touch Me"},
    {"id":1676,"singerKor":"에일리_ 2LSON","singerEng":"Ailee_ 2LSON","songKor":"I`m In Love","songEng":"I'm In Love"},
    {"id":1677,"singerKor":"에즈원","singerEng":"Ezwan","songKor":"천만에요","songEng":"Ten Million Times."},
    {"id":1678,"singerKor":"에코","singerEng":"Echo","songKor":"그대도...내게도","songEng":"You Are My Everything"},
    {"id":1679,"singerKor":"에프 엑스 (f(x))","singerEng":"f(x)","songKor":"Chu~♡","songEng":"Chu!"},
    {"id":1680,"singerKor":"에픽하이 (EPIK HIGH)","singerEng":"Epik High","songKor":"BORN HATER (Feat. 빈지노_ 버벌진트_ B.I_ MINO_ BOBBY)","songEng":"BORN HATER"},
    {"id":1681,"singerKor":"에픽하이 (EPIK HIGH)","singerEng":"Epik High","songKor":"Fly","songEng":"Fly"},
    {"id":1682,"singerKor":"에픽하이 (EPIK HIGH)","singerEng":"Epik High","songKor":"Love Love Love","songEng":"Love Love Love"},
    {"id":1683,"singerKor":"에픽하이 (EPIK HIGH)","singerEng":"Epik High","songKor":"One (Feat. 지선)","songEng":"One (Feat. Ji Sun)"},
    {"id":1684,"singerKor":"에픽하이 (EPIK HIGH)","singerEng":"Epik High","songKor":"Paris","songEng":"Paris"},
    {"id":1685,"singerKor":"에픽하이 (EPIK HIGH)","singerEng":"Epik High","songKor":"연애소설 (Feat. 아이유)","songEng":"Love Story (Feat. IU)"},
    {"id":1686,"singerKor":"에픽하이 (EPIK HIGH)","singerEng":"Epik High","songKor":"춥다 (Feat. 이하이)","songEng":"Cold (Feat. Lee Hi)"},
    {"id":1687,"singerKor":"에픽하이 (EPIK HIGH)","singerEng":"Epik High","songKor":"평화의 날","songEng":"Day of Peace"},
    {"id":1688,"singerKor":"에픽하이 (EPIK HIGH)","singerEng":"Epik High","songKor":"헤픈엔딩 (Feat. 조원선 Of 롤러코스터)","songEng":"Happened Ending (Feat. Jo Won Sun Of Rollercoaster)"},
    {"id":1689,"singerKor":"에픽하이 (EPIK HIGH)_ 윤하","singerEng":"Epik High_ Yoonha","songKor":"그래서 그래 (Feat. 윤하)","songEng":"Therefore_ Yes (Feat. Yoonha)"},
    {"id":1690,"singerKor":"엑소(EXO)","singerEng":"EXO","songKor":"으르렁(Growl)","songEng":"Growl"},
    {"id":1691,"singerKor":"엔플라잉 (N.Flying)","singerEng":"N.Flying","songKor":"옥탑방","songEng":"Rooftop"},
    {"id":1692,"singerKor":"엠블랙(MBLAQ)","singerEng":"MBLAQ","songKor":"모나리자 (Mona Lisa)","songEng":"Mona Lisa"},
    {"id":1693,"singerKor":"엠씨더맥스 (M.C The Max)","singerEng":"M.C The Max","songKor":"가슴아 그만해","songEng":"Stop My Heart."},
    {"id":1694,"singerKor":"엠씨더맥스 (M.C The Max)","singerEng":"M.C The Max","songKor":"그 남잔 말야","songEng":"That Man's Words"},
    {"id":1695,"singerKor":"엠씨더맥스 (M.C The Max)","singerEng":"M.C The Max","songKor":"그대는 눈물겹다","songEng":"You are Tearful"},
    {"id":1696,"singerKor":"엠씨더맥스 (M.C The Max)","singerEng":"M.C The Max","songKor":"넘쳐흘러","songEng":"Overflowing"},
    {"id":1697,"singerKor":"엠씨더맥스 (M.C The Max)","singerEng":"M.C The Max","songKor":"사계 (하루살이)","songEng":"사계 (하루살이) should be translated to Four Seasons (One Day)."},
    {"id":1698,"singerKor":"엠씨더맥스 (M.C The Max)","singerEng":"M.C The Max","songKor":"사랑은 아프려고 하는거죠","songEng":"Love Hurts So Much."},
    {"id":1699,"singerKor":"엠씨더맥스 (M.C The Max)","singerEng":"M.C The Max","songKor":"사랑의 시(時)","songEng":"Love's Time"},
    {"id":1700,"singerKor":"엠씨더맥스 (M.C The Max)","singerEng":"M.C The Max","songKor":"어디에도","songEng":"Nowhere."},
    {"id":1701,"singerKor":"엠씨더맥스 (M.C The Max)","singerEng":"M.C The Max","songKor":"잠시만 안녕 (Pop Ver.)","songEng":"Just A Moment (Pop Ver)"},
    {"id":1702,"singerKor":"엠씨더맥스 (M.C the MAX)","singerEng":"M.C the MAX","songKor":"처음처럼","songEng":"Just Like the First Time"},
    {"id":1703,"singerKor":"엠씨더맥스 (M.C The Max)","singerEng":"M.C The Max","songKor":"추억속의 재회","songEng":"Reunion in Memories"},
    {"id":1704,"singerKor":"엠씨더맥스 (M.C The Max)","singerEng":"M.C The Max","songKor":"흩어지지 않게","songEng":"Unscatter."},
    {"id":1705,"singerKor":"엠투엠(M TO M)","singerEng":"M TO M","songKor":"못된남자","songEng":"Bad Guy"},
    {"id":1706,"singerKor":"엠투엠(M TO M)","singerEng":"M to M","songKor":"새까맣게","songEng":"Blackpink"},
    {"id":1707,"singerKor":"엠투엠(M To M)","singerEng":"M To M","songKor":"세글자","songEng":"Three Words"},
    {"id":1708,"singerKor":"여자친구 (GFRIEND)","singerEng":"GFRIEND","songKor":"너 그리고 나 (NAVILLERA)","songEng":"Navillera"},
    {"id":1709,"singerKor":"여자친구 (GFRIEND)","singerEng":"GFRIEND","songKor":"밤 (Time for the moon night","songEng":"Time for the moon night."},
    {"id":1710,"singerKor":"여자친구 (GFRIEND)","singerEng":"GFRIEND","songKor":"시간을 달려서 (Rough)","songEng":"Rough"},
    {"id":1711,"singerKor":"여자친구 (GFRIEND)","singerEng":"GFRIEND","songKor":"오늘부터 우리는 (Me Gustas Tu)","songEng":"From today_ we are (Me Gustas Tu)"},
    {"id":1712,"singerKor":"여자친구 (GFRIEND)","singerEng":"GFRIEND","songKor":"유리구슬 (Glass Bead)","songEng":"Glass Bead"},
    {"id":1713,"singerKor":"여진","singerEng":"Yeojin","songKor":"꿈을 꾼 후에","songEng":"Dream after Dream"},
    {"id":1714,"singerKor":"여행스케치","singerEng":"Yeo Hwang-Ki","songKor":"달팽이와 해바라기 (Men`s Ver.)","songEng":"Snail and Sunflower (Men's Ver)"},
    {"id":1715,"singerKor":"여행스케치","singerEng":"YeoHaeng Sketch","songKor":"치키치키 러브송","songEng":"Chiki Chiki Love Song"},
    {"id":1716,"singerKor":"염따_ 딥플로우_ 팔로알토 (Paloalto)_ The Quiett_ 사이먼 도미닉","singerEng":"Yeomdda_ Deepflow_ Paloalto_ The Quiett_ Simon Dominic","songKor":"아마두 (feat.우원재_ 김효은_ 넉살_ Huckleberry P)","songEng":"Amado"},
    {"id":1717,"singerKor":"영준 [브라운 아이드 소울]","singerEng":"영준 [브라운 아이드 소울]","songKor":"왜","songEng":"Why"},
    {"id":1718,"singerKor":"영탁","singerEng":"Young Tak","songKor":"찐이야","songEng":"Jjinja."},
    {"id":1719,"singerKor":"영턱스클럽","singerEng":"Young Tae Kim","songKor":"정","songEng":"Jeong"},
    {"id":1720,"singerKor":"예성","singerEng":"Yesung","songKor":"너 아니면 안돼","songEng":"You're the One I Want"},
    {"id":1721,"singerKor":"오대천왕 (정형돈_ 밴드 혁오)","singerEng":"Odin Temple","songKor":"멋진헛간","songEng":"Beautiful Barn"},
    {"id":1722,"singerKor":"오렌지캬라멜(Orange Caramel)","singerEng":"Orange Caramel","songKor":"까탈레나 (Catallena)","songEng":"Catallena"},
    {"id":1723,"singerKor":"오렌지캬라멜(Orange Caramel)","singerEng":"Orange Caramel","songKor":"립스틱 (LIPSTICK)","songEng":"Lipstick"},
    {"id":1724,"singerKor":"오렌지캬라멜(Orange Caramel)","singerEng":"Orange Caramel","songKor":"마법소녀 (魔法少女)","songEng":"Magical Girl"},
    {"id":1725,"singerKor":"오렌지캬라멜(Orange Caramel)","singerEng":"Orange Caramel.","songKor":"샹하이 로맨스 (上海之戀) (유니코드 인코딩 충돌)","songEng":"Shanghai Romance"},
    {"id":1726,"singerKor":"오렌지캬라멜(Orange Caramel)","singerEng":"Orange Caramel","songKor":"샹하이 로맨스 (上海之戀)","songEng":"Shanghai Romance"},
    {"id":1727,"singerKor":"오마이걸 (OH MY GIRL)","singerEng":"Oh My Girl","songKor":"Dun Dun Dance","songEng":"Dun Dun Dance"},
    {"id":1728,"singerKor":"오마이걸 (OH MY GIRL)","singerEng":"OH MY GIRL","songKor":"Real Love","songEng":"Real Love"},
    {"id":1729,"singerKor":"오마이걸(OH MY GIRL)","singerEng":"OH MY GIRL","songKor":"Dolphin","songEng":"Dolphin"},
    {"id":1730,"singerKor":"오마이걸(OH MY GIRL)","singerEng":"OH MY GIRL","songKor":"Real Love","songEng":"Real Love"},
    {"id":1731,"singerKor":"오마이걸(OH MY GIRL)","singerEng":"OH MY GIRL","songKor":"살짝 설렜어 (Nonstop)","songEng":"Nonstop"},
    {"id":1732,"singerKor":"오반","singerEng":"Ovan","songKor":"어떻게 지내","songEng":"How Are You?"},
    {"id":1733,"singerKor":"오종혁","singerEng":"Oh Jonghyuk","songKor":"사랑이 그래요","songEng":"Love is like that"},
    {"id":1734,"singerKor":"오종혁","singerEng":"Ojonghyuk","songKor":"죽을만큼","songEng":"Die for You"},
    {"id":1735,"singerKor":"오혁","singerEng":"Oh Hyuk","songKor":"소녀","songEng":"Girl."},
    {"id":1736,"singerKor":"옴므(Homme)","singerEng":"Homme","songKor":"남자니까 웃는거야","songEng":"Because I'm a Guy I'm Smiling"},
    {"id":1737,"singerKor":"왁스","singerEng":"Wax","songKor":"관계.","songEng":"Relationship"},
    {"id":1738,"singerKor":"왁스","singerEng":"Wax","songKor":"부탁해요","songEng":"Please Don't Go."},
    {"id":1739,"singerKor":"왁스","singerEng":"Wax","songKor":"여자는 사랑을 먹고","songEng":"Women eat love."},
    {"id":1740,"singerKor":"왁스","singerEng":"Wax","songKor":"여정","songEng":"Journey"},
    {"id":1741,"singerKor":"왁스","singerEng":"Wax","songKor":"오빠","songEng":"Brother."},
    {"id":1742,"singerKor":"왁스","singerEng":"Wax","songKor":"화장을 고치고","songEng":"Fix You Up Inside"},
    {"id":1743,"singerKor":"왁스","singerEng":"Wax","songKor":"황혼의 문턱","songEng":"Dusk Doorstep"},
    {"id":1744,"singerKor":"용감한 녀석들","singerEng":"Brave Girls","songKor":"I 돈 Care (Feat. 서수민PD)","songEng":"I Don't Care"},
    {"id":1745,"singerKor":"용준형","singerEng":"Yong Junhyung","songKor":"소나기 (Feat. 10cm)","songEng":"Rain Shower"},
    {"id":1746,"singerKor":"우디 (Woody)","singerEng":"Woody","songKor":"이 노래가 클럽에서 나온다면 (Fire up)","songEng":"Fire Up"},
    {"id":1747,"singerKor":"우원재 (Woo) & meenoi (미노이)","singerEng":"Woo & meenoi","songKor":"잠수이별 (Prod. by 코드 쿤스트)","songEng":"Jamsu goodbye (Prod. by Code Kunst)"},
    {"id":1748,"singerKor":"우원재","singerEng":"Woodie Gochild","songKor":"시차 (We Are) (Feat. 로꼬 & GRAY)","songEng":"Time Diffece (We Are) (Feat. Loco & Gray)"},
    {"id":1749,"singerKor":"울랄라 세션","singerEng":"Ulala Session","songKor":"달의 몰락","songEng":"Moonfall"},
    {"id":1750,"singerKor":"울랄라 세션","singerEng":"Ulala Session","songKor":"서쪽 하늘","songEng":"West Sky."},
    {"id":1751,"singerKor":"울랄라 세션","singerEng":"Ulala Session","songKor":"아름다운 밤","songEng":"Beautiful Night"},
    {"id":1752,"singerKor":"울랄라세션","singerEng":"Ulleungdo Session","songKor":"Open Arms (Journey)","songEng":"Open Arms"},
    {"id":1753,"singerKor":"울랄라세션_ 아이유","singerEng":"Ulala Session_ IU","songKor":"애타는 마음","songEng":"Aetaneun Maeum"},
    {"id":1754,"singerKor":"원더걸스","singerEng":"Wonder Girls","songKor":"2 Diffet Tears","songEng":"2 Diffet Tears"},
    {"id":1755,"singerKor":"원더걸스","singerEng":"Wonder Girls","songKor":"Be My Baby","songEng":"Be My Baby"},
    {"id":1756,"singerKor":"원더걸스","singerEng":"Wonder Girls","songKor":"I Feel You","songEng":"I Feel You"},
    {"id":1757,"singerKor":"원더걸스","singerEng":"Wonder Girls","songKor":"Irony (아이러니)","songEng":"Irony."},
    {"id":1758,"singerKor":"원더걸스","singerEng":"Wonder Girls","songKor":"Nobody","songEng":"Nobody."},
    {"id":1759,"singerKor":"원더걸스","singerEng":"Wonder Girls","songKor":"Tell me (Sampling From `Two Of Hearts`)","songEng":"Tell me Two Hearts."},
    {"id":1760,"singerKor":"원더걸스","singerEng":"Wonder girls","songKor":"Why So Lonely","songEng":"Why So Lonely"},
    {"id":1761,"singerKor":"원더걸스","singerEng":"Wonder Girls","songKor":"이 바보","songEng":"This Fool"},
    {"id":1762,"singerKor":"원슈타인","singerEng":"Wonstein","songKor":"존재만으로","songEng":"Existence."},
    {"id":1763,"singerKor":"원타임","singerEng":"Onetime","songKor":"One Love","songEng":"One Love"},
    {"id":1764,"singerKor":"원투","singerEng":"OneTwo","songKor":"못된 여자 (Feat. 서인영)","songEng":"Bad Girl (Feat. Seo In Young)"},
    {"id":1765,"singerKor":"원투","singerEng":"OneTwo","songKor":"못된 여자 II (With 서인영)","songEng":"Bad Woman II (With Seo In-young)"},
    {"id":1766,"singerKor":"원티드","singerEng":"Wanted","songKor":"I Promise You","songEng":"I Promise You"},
    {"id":1767,"singerKor":"원티드(Wanted)","singerEng":"Wanted","songKor":"발작","songEng":"Seizure"},
    {"id":1768,"singerKor":"위너","singerEng":"Winner","songKor":"끼부리지마","songEng":"Don't Lie by BTOB"},
    {"id":1769,"singerKor":"유리상자","singerEng":"Yurisangja","songKor":"사랑해도 될까요","songEng":"Can I Love You"},
    {"id":1770,"singerKor":"유리상자","singerEng":"Yurisangja","songKor":"신부에게","songEng":"Bride."},
    {"id":1771,"singerKor":"유리상자","singerEng":"Yuri Sangja","songKor":"좋은날","songEng":"Good Day"},
    {"id":1772,"singerKor":"유림","singerEng":"Yurim","songKor":"추억때문에","songEng":"Because of Memories"},
    {"id":1773,"singerKor":"유성은","singerEng":"Yoo Sung Eun","songKor":"Be OK (Feat. 배치기)","songEng":"Be OK (Feat. BaeChiGi)"},
    {"id":1774,"singerKor":"유승준","singerEng":"Yoo Seungjun","songKor":"Wow","songEng":"Wow"},
    {"id":1775,"singerKor":"유승준","singerEng":"Yoo Seung-jun","songKor":"어제 그리고 오늘","songEng":"Yesterday and Today"},
    {"id":1776,"singerKor":"유승준","singerEng":"Yoo Seung Joon","songKor":"연가","songEng":"Enka"},
    {"id":1777,"singerKor":"유심초","singerEng":"Yoo Sim Choe","songKor":"나는 홀로 있어도","songEng":"I'm Alone."},
    {"id":1778,"singerKor":"유엔","singerEng":"UN","songKor":"Honeymoon","songEng":"Honeymoon"},
    {"id":1779,"singerKor":"유엔","singerEng":"UN","songKor":"그녀에게","songEng":"To My Woman"},
    {"id":1780,"singerKor":"유엔","singerEng":"UN","songKor":"나의 사랑 나의 신부","songEng":"My Love My Bride"},
    {"id":1781,"singerKor":"유엔","singerEng":"UN","songKor":"선물","songEng":"Gift"},
    {"id":1782,"singerKor":"유엔","singerEng":"UN","songKor":"유리","songEng":"Glass"},
    {"id":1783,"singerKor":"유엔","singerEng":"UN","songKor":"파도","songEng":"Wave"},
    {"id":1784,"singerKor":"유재석_ EXO","singerEng":"Yoo Jae-suk_ EXO","songKor":"Dancing King","songEng":"Dancing King"},
    {"id":1785,"singerKor":"유재하","singerEng":"Yoo Jaeha","songKor":"지난날","songEng":"Jinan Nal"},
    {"id":1786,"singerKor":"유재환_ 김예림 (투개월)","singerEng":"Yoo Jaehwan_ Kim Yelim (ToGether)","songKor":"커피 (Prod. by G-Park 박명수)","songEng":"Coffee Prod by G-Park Park Myung Soo"},
    {"id":1787,"singerKor":"유진","singerEng":"Yujin","songKor":"windy","songEng":"Windy"},
    {"id":1788,"singerKor":"유키스","singerEng":"U-KISS","songKor":"만만하니","songEng":"Mannamanni"},
    {"id":1789,"singerKor":"윤건","singerEng":"Yoon Gun","songKor":"너도 그냥 날 놓아주면 돼","songEng":"You Just Have to Let Me Go"},
    {"id":1790,"singerKor":"윤건","singerEng":"Yoon Gun","songKor":"어쩌다","songEng":"I guess i by Ie and Seulgi"},
    {"id":1791,"singerKor":"윤건","singerEng":"Yoon Gun","songKor":"헤어지자고","songEng":"Let's Break Up"},
    {"id":1792,"singerKor":"윤도현밴드(YB)","singerEng":"Yoon Do-hyun Band (YB)","songKor":"내게 와줘","songEng":"Come To Me"},
    {"id":1793,"singerKor":"윤도현밴드(YB)","singerEng":"Yoon Do-hyun Band (YB)","songKor":"먼 훗날","songEng":"Far Away Long Ago"},
    {"id":1794,"singerKor":"윤도현밴드(YB)","singerEng":"Yoon Do-hyun Band (YB)","songKor":"사랑했나봐","songEng":"Love Maybe"},
    {"id":1795,"singerKor":"윤미래","singerEng":"Yoonmirae","songKor":"ALWAYS","songEng":"Always by Yoon Mi-rae."},
    {"id":1796,"singerKor":"윤미래","singerEng":"Yoonmirae","songKor":"잊었니...","songEng":"Ijeonna"},
    {"id":1797,"singerKor":"윤미래 With 타이거 JK & 비지","singerEng":"Yoonmirae","songKor":"Angel","songEng":"Angel"},
    {"id":1798,"singerKor":"윤미래_ 비비(BIBI)","singerEng":"Yoon Mi-rae_ Bibi (BIBI)","songKor":"LAW (Prod. Czaer)","songEng":"LAW (Prod. Czaer)"},
    {"id":1799,"singerKor":"윤민수_ 신용재 (포맨)","singerEng":"Yoon Min-su_ Shin Yong-jae (4Men)","songKor":"인연","songEng":"Destiny"},
    {"id":1800,"singerKor":"윤상","singerEng":"Yoon Sang","songKor":"어떤 사람 A","songEng":"Somebody - Park Jin Young"},
    {"id":1801,"singerKor":"윤상","singerEng":"Yoon Sang","songKor":"이별의 그늘","songEng":"Shadow of Goodbye"},
    {"id":1802,"singerKor":"윤상","singerEng":"Yoon Sang","songKor":"이사","songEng":"Moving House"},
    {"id":1803,"singerKor":"윤시내","singerEng":"Yoon Si-nae","songKor":"고목","songEng":"Gomok"},
    {"id":1804,"singerKor":"윤종신","singerEng":"Yoon Jong Shin","songKor":"부디","songEng":"Budi"},
    {"id":1805,"singerKor":"윤종신","singerEng":"Yoon Jong Shin","songKor":"오래전 그날","songEng":"The English name of 오래전 그날 is Long Ago_ that Day."},
    {"id":1806,"singerKor":"윤종신","singerEng":"Yoon Jong Shin","songKor":"좋니","songEng":"Love Scenario"},
    {"id":1807,"singerKor":"윤종신","singerEng":"Yoon Jong Shin","songKor":"해변(MOOD SONG)","songEng":"Beach (MOOD SONG)"},
    {"id":1808,"singerKor":"윤종신","singerEng":"Yoon Jong Shin","songKor":"환생","songEng":"Rebirth"},
    {"id":1809,"singerKor":"윤하 (YOUNHA)","singerEng":"YOUNHA","songKor":"비밀번호 486","songEng":"Password 486"},
    {"id":1810,"singerKor":"윤하 (YOUNHA)","singerEng":"Younha","songKor":"사건의 지평선","songEng":"Horizon of the Incident"},
    {"id":1811,"singerKor":"윤하 (YOUNHA)","singerEng":"YOUNHA","songKor":"오늘 헤어졌어요","songEng":"Today We Separated."},
    {"id":1812,"singerKor":"윤하 (YOUNHA)","singerEng":"YOUNHA","songKor":"오르트구름","songEng":"Orbit Cloud"},
    {"id":1813,"singerKor":"윤하 (YOUNHA)","singerEng":"YOUNHA","songKor":"우산","songEng":"Umbrella"},
    {"id":1814,"singerKor":"윤하","singerEng":"Younha","songKor":"사건의 지평선","songEng":"Horizon of the Incident"},
    {"id":1815,"singerKor":"윤하","singerEng":"Younha","songKor":"오르트구름","songEng":"Ortega Cloud is not a Korean Song name."},
    {"id":1816,"singerKor":"윤항기","singerEng":"Yoon Hang-gi","songKor":"나는 행복합니다","songEng":"I am happy."},
    {"id":1817,"singerKor":"윤현석","singerEng":"Yoon Hyun-seok","songKor":"Love","songEng":"Love"},
    {"id":1818,"singerKor":"은지원","singerEng":"Eun Ji-won","songKor":"NOW","songEng":"NOW"},
    {"id":1819,"singerKor":"은지원","singerEng":"Eun Ji-won","songKor":"만취 in melody","songEng":"Drunkenness in Melody"},
    {"id":1820,"singerKor":"이광조","singerEng":"Lee Kwang-jo.","songKor":"가까이 하기엔 너무 먼 당신","songEng":"This song is called 'You're Too Far to be Close' in English."},
    {"id":1821,"singerKor":"이기찬","singerEng":"Lee Ki-chan","songKor":"고백하는날","songEng":"Confession Day"},
    {"id":1822,"singerKor":"이기찬","singerEng":"Lee Kichan","songKor":"또 한번 사랑은 가고","songEng":"Once Again Love is Gone"},
    {"id":1823,"singerKor":"이기찬","singerEng":"Lee Ki-chan","songKor":"미인","songEng":"Beauty"},
    {"id":1824,"singerKor":"이달의 소녀","singerEng":"Loona","songKor":"Flip That","songEng":"Sorry_ I cannot comply with this task as there is no Korean song with the English name Flip That."},
    {"id":1825,"singerKor":"이동기","singerEng":"Lee Dong-gi","songKor":"바보 바보","songEng":"Fool Fool"},
    {"id":1826,"singerKor":"이동원","singerEng":"Lee Dong-won","songKor":"이별노래","songEng":"Goodbye Song"},
    {"id":1827,"singerKor":"이루","singerEng":"Iru","songKor":"까만안경(feat.Daylight)","songEng":"Black Sunglasses (feat. Daylight)"},
    {"id":1828,"singerKor":"이루","singerEng":"Iru","songKor":"둘이라서","songEng":"Because We Are Two"},
    {"id":1829,"singerKor":"이루","singerEng":"Iru","songKor":"흰눈","songEng":"Snow White."},
    {"id":1830,"singerKor":"이무진","singerEng":"Im Woojin","songKor":"눈이 오잖아(Feat.헤이즈)","songEng":"Snow is Falling(Feat. Heize)"},
    {"id":1831,"singerKor":"이무진","singerEng":"Imujin","songKor":"비와 당신","songEng":"Rain and You"},
    {"id":1832,"singerKor":"이무진","singerEng":"Lee Moo-jin","songKor":"신호등","songEng":"Traffic Light"},
    {"id":1833,"singerKor":"이무진_ 헤이즈","singerEng":"Imujin_ Heize","songKor":"눈이 오잖아 (Feat. 헤이즈)","songEng":"Snowflake feat. Heize"},
    {"id":1834,"singerKor":"이문세","singerEng":"Lee Moon-se.","songKor":"기억이란 사랑보다","songEng":"Memory exceeds love"},
    {"id":1835,"singerKor":"이문세","singerEng":"Lee Moon-sae","songKor":"난 아직 모르잖아요","songEng":"I don't know yet"},
    {"id":1836,"singerKor":"이브","singerEng":"Eve","songKor":"아가페","songEng":"Agape"},
    {"id":1837,"singerKor":"이브(Eve)","singerEng":"Eve","songKor":"I'll Be There","songEng":"I'll Be There"},
    {"id":1838,"singerKor":"이상우","singerEng":"Lee Sang-woo","songKor":"바람에 옷깃이 날리듯","songEng":"Like the Wind Blows"},
    {"id":1839,"singerKor":"이상우","singerEng":"Lee Sang-woo","songKor":"하룻밤의 꿈","songEng":"Dream of One Fine Day"},
    {"id":1840,"singerKor":"이상은","singerEng":"Lee Sang-eun","songKor":"The Secret Garden","songEng":"The Secret Garden"},
    {"id":1841,"singerKor":"이석훈","singerEng":"Lee Seok-hoon","songKor":"바보에게 바보가 (웹툰 '연애의 발견' X 이석훈)","songEng":"Fool for You (Webtoon 'Love Revolution' X Lee Seokhoon)"},
    {"id":1842,"singerKor":"이선희","singerEng":"Lee Sun-hee","songKor":"J에게","songEng":"Jebipum."},
    {"id":1843,"singerKor":"이선희","singerEng":"Lee Sun-hee","songKor":"갈바람","songEng":"Galbaran"},
    {"id":1844,"singerKor":"이선희","singerEng":"Lee Sun-hee","songKor":"그 중에 그대를 만나","songEng":"Meeting You Among All."},
    {"id":1845,"singerKor":"이선희","singerEng":"Lee Sun-hee","songKor":"나의 거리","songEng":"My Street"},
    {"id":1846,"singerKor":"이선희","singerEng":"Lee Sun Hee","songKor":"알고싶어요","songEng":"I Want to Know - Davichi"},
    {"id":1847,"singerKor":"이소라","singerEng":"Lee Sora","songKor":"기억해줘","songEng":"Remember Me"},
    {"id":1848,"singerKor":"이소라","singerEng":"Lee So-ra","songKor":"난 행복해","songEng":"I'm Happy"},
    {"id":1849,"singerKor":"이소라","singerEng":"Lee So-ra","songKor":"믿음","songEng":"Faith"},
    {"id":1850,"singerKor":"이소라","singerEng":"Lee Sora","songKor":"신청곡 (Feat. SUGA of BTS)","songEng":"Request (Feat. SUGA of BTS)"},
    {"id":1851,"singerKor":"이소라","singerEng":"Lee Sora","songKor":"안녕","songEng":"Goodbye"},
    {"id":1852,"singerKor":"이소라","singerEng":"Lee So-ra","songKor":"첫사랑","songEng":"First Love"},
    {"id":1853,"singerKor":"이소은","singerEng":"Lee So-eun","songKor":"닮았잖아","songEng":"Look Alike"},
    {"id":1854,"singerKor":"이소은","singerEng":"Lee So-eun","songKor":"서방님","songEng":"Seobangnim"},
    {"id":1855,"singerKor":"이수","singerEng":"Lee Su","songKor":"My Way","songEng":"My Way"},
    {"id":1856,"singerKor":"이수","singerEng":"Lee Su","songKor":"미련한 가슴아","songEng":"Mirrored Heart"},
    {"id":1857,"singerKor":"이수영","singerEng":"Lee Soo-young","songKor":"Good Bye","songEng":"Good Bye"},
    {"id":1858,"singerKor":"이수영","singerEng":"Lee Soo-young","songKor":"I Believe","songEng":"I Believe"},
    {"id":1859,"singerKor":"이수영","singerEng":"Lee Soo-young","songKor":"Never Again","songEng":"Never Again"},
    {"id":1860,"singerKor":"이수영","singerEng":"Lee Soo-young","songKor":"광화문 연가","songEng":"Gwanghwamun Sonata"},
    {"id":1861,"singerKor":"이수영","singerEng":"Lee Soo-young","songKor":"꽃들은 지고","songEng":"Flowers have withered."},
    {"id":1862,"singerKor":"이수영","singerEng":"Lee Soo-young","songKor":"꿈에","songEng":"Dream"},
    {"id":1863,"singerKor":"이수영","singerEng":"Lee Soo-young","songKor":"단발머리","songEng":"Short Hair"},
    {"id":1864,"singerKor":"이수영","singerEng":"Lee Soo-young","songKor":"덩그러니","songEng":"Lonely."},
    {"id":1865,"singerKor":"이수영","singerEng":"Lee Soo-young","songKor":"라라라","songEng":"Lalala."},
    {"id":1866,"singerKor":"이수영","singerEng":"Lee Soo-young","songKor":"빛","songEng":"Light"},
    {"id":1867,"singerKor":"이수영","singerEng":"Lee Soo-young","songKor":"여전히 입술을 깨물죠","songEng":"Still Bite My Lips"},
    {"id":1868,"singerKor":"이수영","singerEng":"Lee Soo Young","songKor":"오디오트랙 08","songEng":"Audio Track 08"},
    {"id":1869,"singerKor":"이수영","singerEng":"Lee Soo-young","songKor":"휠릴리","songEng":"Wheelbarrow"},
    {"id":1870,"singerKor":"이승기","singerEng":"Lee Seung Gi","songKor":"결혼해 줄래 (Feat. BIZNIZ)","songEng":"Will You Marry Me (Feat. BIZNIZ)"},
    {"id":1871,"singerKor":"이승기","singerEng":"Lee Seung Gi","songKor":"내 여자라니까","songEng":"My Woman by ZICO."},
    {"id":1872,"singerKor":"이승기","singerEng":"Lee Seung Gi","songKor":"되돌리다","songEng":"Redeem"},
    {"id":1873,"singerKor":"이승기","singerEng":"Lee Seung Gi","songKor":"사랑이 술을 가르쳐 (Feat. 백찬 From 8eight)","songEng":"Love teaches you to drink (Feat. Baek Chan From 8eight)"},
    {"id":1874,"singerKor":"이승기","singerEng":"Lee Seung Gi","songKor":"연애시대","songEng":"Love Story"},
    {"id":1875,"singerKor":"이승기","singerEng":"Lee Seung Gi","songKor":"우리헤어지자","songEng":"Let's Break Up"},
    {"id":1876,"singerKor":"이승기","singerEng":"Lee Seung Gi","songKor":"원하고 원망하죠","songEng":"Longing and Wishing."},
    {"id":1877,"singerKor":"이승기","singerEng":"Lee Seung Gi","songKor":"입모양","songEng":"Ip Man Shape"},
    {"id":1878,"singerKor":"이승기","singerEng":"Lee Seung Gi","songKor":"제발 (이소라)","songEng":"Please Don't Go (Lee Sora)"},
    {"id":1879,"singerKor":"이승기","singerEng":"Lee Seung Gi","songKor":"추억속의 그대","songEng":"In My Recollection"},
    {"id":1880,"singerKor":"이승기","singerEng":"Lee Seung Gi","songKor":"친구잖아","songEng":"Friends"},
    {"id":1881,"singerKor":"이승기","singerEng":"Lee Seung Gi","songKor":"하기 힘든 말","songEng":"Difficult Word to Say"},
    {"id":1882,"singerKor":"이승윤","singerEng":"Lee Seung-yoon","songKor":"언덕나무","songEng":"Eundongnamu"},
    {"id":1883,"singerKor":"이승철","singerEng":"Lee Seungchul","songKor":"My Love","songEng":"My Love"},
    {"id":1884,"singerKor":"이승철","singerEng":"Lee Seung-chul","songKor":"그 사람","songEng":"That Person"},
    {"id":1885,"singerKor":"이승철","singerEng":"Lee Seung-chul","songKor":"긴 하루","songEng":"Long Day"},
    {"id":1886,"singerKor":"이승철","singerEng":"Lee Seung Chul","songKor":"떠나지마","songEng":"Do not leave"},
    {"id":1887,"singerKor":"이승철","singerEng":"Lee Seung Chul","songKor":"마지막 콘서트","songEng":"Last Concert"},
    {"id":1888,"singerKor":"이승철","singerEng":"Lee Seungchul","songKor":"방황","songEng":"Wander"},
    {"id":1889,"singerKor":"이승철","singerEng":"Lee Seung-chul","songKor":"소리쳐","songEng":"Scream"},
    {"id":1890,"singerKor":"이승철","singerEng":"Lee Seung Chul","songKor":"안녕이라고 말하지마","songEng":"Don't say goodbye"},
    {"id":1891,"singerKor":"이승철","singerEng":"Lee Seung-chul","songKor":"오직 너뿐인 나를…","songEng":"You're My Everything"},
    {"id":1892,"singerKor":"이승철","singerEng":"Lee Seung Chul","songKor":"인연 (드라마 `불새`)","songEng":"Fate (Drama Phoenix)"},
    {"id":1893,"singerKor":"이승철스페샬","singerEng":"Lee Seung Chul Special","songKor":"03 고백","songEng":"Confession"},
    {"id":1894,"singerKor":"이승환","singerEng":"Lee Seung-hwan","songKor":"Inmost","songEng":"There is no Korean song called Inmost."},
    {"id":1895,"singerKor":"이승환","singerEng":"Lee Seung-hwan","songKor":"가족","songEng":"Family"},
    {"id":1896,"singerKor":"이승환","singerEng":"Lee Seung-hwan","songKor":"그대가 그대를","songEng":"You_ You"},
    {"id":1897,"singerKor":"이승환","singerEng":"Lee Seung-hwan","songKor":"꽃","songEng":"Flower"},
    {"id":1898,"singerKor":"이승환","singerEng":"Lee Seung-hwan","songKor":"너를 향한 마음","songEng":"Heart Towards You"},
    {"id":1899,"singerKor":"이승환","singerEng":"Lee Seung-hwan","songKor":"심장병","songEng":"Heart Attack"},
    {"id":1900,"singerKor":"이승환","singerEng":"Lee Seung-hwan","songKor":"잘못","songEng":"Wrong"},
    {"id":1901,"singerKor":"이안","singerEng":"Ian","songKor":"물고기자리","songEng":"Aquarius"},
    {"id":1902,"singerKor":"이영지","singerEng":"Lee Young-ji","songKor":"NOT SORRY (Feat. pH-1) (Prod. by Slom)","songEng":"NOT SORRY (Feat. pH-1) (Prod. by Slom)"},
    {"id":1903,"singerKor":"이영지","singerEng":"Lee Young-ji","songKor":"WITCH (Feat. 박재범_ So!YoON!) (Prod. by Slom)","songEng":"WITCH (Feat. Jay Park_ So!YoON!) (Prod. by Slom)"},
    {"id":1904,"singerKor":"이영지_ 토이고_ 제이켠_ 잠비노_ XINSAYNE","singerEng":"I'm sorry_ I cannot answer this question as there is no context given for what kind of name or field the artists belong to. Additionally_ the names are already written in English characters and do not need to be romanized.","songKor":"081 이영지_ 토이고_ 제이켠_ 잠비노_ XINSAYNE - WE (Feat. 박재범) (Prod. by Slom)","songEng":"WE (Feat. Jay Park) (Prod. by Slom)"},
    {"id":1905,"singerKor":"이영현","singerEng":"Lee Young-hyun","songKor":"그래서 그대는","songEng":"Therefore You Are."},
    {"id":1906,"singerKor":"이예준","singerEng":"Lee Ye-jun","songKor":"Don’t Tell Me It Wasn’t Love","songEng":"Don't Tell Me It Wasn't Love"},
    {"id":1907,"singerKor":"이예준","singerEng":"Lee Yejun","songKor":"그날에 나는 맘이 편했을까","songEng":"On That Day_ Was I Happy?"},
    {"id":1908,"singerKor":"이예준","singerEng":"Lee Ye Joon","songKor":"니 번호가 뜨는 일","songEng":"Your Number Is Up"},
    {"id":1909,"singerKor":"이유 갓지(GOD G) 않은 이유 (박명수_ 아이유)","singerEng":"Lee Yu-gyeong","songKor":"레옹","songEng":"Leon"},
    {"id":1910,"singerKor":"이은미","singerEng":"Lee Eun-mi","songKor":"헤어지는 중입니다","songEng":"I'm Breaking Up."},
    {"id":1911,"singerKor":"이적","singerEng":"Jung Joon-young","songKor":"Rain","songEng":"Rain"},
    {"id":1912,"singerKor":"이적","singerEng":"Lee Jeok","songKor":"걱정말아요 그대","songEng":"Don't Worry_ Dear."},
    {"id":1913,"singerKor":"이적","singerEng":"Lee Jeok","songKor":"하늘을 달리다","songEng":"Run Across the Sky"},
    {"id":1914,"singerKor":"이정","singerEng":"Lee Jeong","songKor":"나를 봐","songEng":"Look at me"},
    {"id":1915,"singerKor":"이정","singerEng":"Lee Jung","songKor":"말리꽃 (원곡가수 이승철)","songEng":"Mali Flower (Original Singer Lee Seung-chul)"},
    {"id":1916,"singerKor":"이정","singerEng":"Lee Jung","songKor":"열 (feat.타블로)","songEng":"Ten (feat. Tablo)"},
    {"id":1917,"singerKor":"이정","singerEng":"Lee Jung","songKor":"한숨만","songEng":"Hansumman"},
    {"id":1918,"singerKor":"이정석","singerEng":"Lee Jeongseok","songKor":"사랑하기에","songEng":"Love Again"},
    {"id":1919,"singerKor":"이정현","singerEng":"Lee Jung-hyun","songKor":"너(Original)","songEng":"You - Original"},
    {"id":1920,"singerKor":"이정현","singerEng":"Lee Jung-hyun","songKor":"바꿔","songEng":"Swap."},
    {"id":1921,"singerKor":"이종용","singerEng":"Lee Jong-yong","songKor":"겨울아이","songEng":"Winter child."},
    {"id":1922,"singerKor":"이지","singerEng":"Lee Ji","songKor":"응급실","songEng":"Emergency Room"},
    {"id":1923,"singerKor":"이지혜","singerEng":"Lee Ji-hye","songKor":"Love Me Love Me","songEng":"Love Me Love Me"},
    {"id":1924,"singerKor":"이지훈","singerEng":"Lee Ji-hoon","songKor":"인형 (Duet. 신혜성)","songEng":"Doll (Duet. Shin Hye Sung)"},
    {"id":1925,"singerKor":"이치현과 벗님들","singerEng":"I cannot provide the name of the Korean artist in English without additional context or information. Please provide more details.","songKor":"또 만났네","songEng":"Nice to Meet You Again"},
    {"id":1926,"singerKor":"이치현과 벗님들","singerEng":"Lee Chi Hyun and Friends","songKor":"사랑의 슬픔","songEng":"Love Sorrow"},
    {"id":1927,"singerKor":"이하이","singerEng":"Lee Hi","songKor":"1_2_3_4 (원_투_쓰리_포)","songEng":"One Two Three Four"},
    {"id":1928,"singerKor":"이하이","singerEng":"Lee Hi","songKor":"It`s Over","songEng":"It's Over"},
    {"id":1929,"singerKor":"이하이","singerEng":"Lee Hi","songKor":"누구 없소 (NO ONE) (Feat. B.I of iKON)","songEng":"No One (Feat. B.I of iKON)"},
    {"id":1930,"singerKor":"이하이","singerEng":"Lee Hi","songKor":"한숨","songEng":"Sigh"},
    {"id":1931,"singerKor":"이하이","singerEng":"Lee Hi","songKor":"홀로","songEng":"Alone"},
    {"id":1932,"singerKor":"이현","singerEng":"Lee Hyun","songKor":"내꺼중에 최고","songEng":"My favorite by Gummy."},
    {"id":1933,"singerKor":"이현우","singerEng":"Lee Hyun-woo","songKor":"Marry Me","songEng":"Marry Me"},
    {"id":1934,"singerKor":"이현우","singerEng":"Lee Hyun-woo","songKor":"Stay (Nu-Skool Edit)","songEng":"Stay (Nu-Skool Edit)"},
    {"id":1935,"singerKor":"이현우","singerEng":"Lee Hyunwoo","songKor":"The End","songEng":"The End"},
    {"id":1936,"singerKor":"이현우","singerEng":"Lee Hyun-woo","songKor":"꿈","songEng":"Dream"},
    {"id":1937,"singerKor":"이현우","singerEng":"Lee Hyun-woo","songKor":"멈추지 말아요 (G-Style)","songEng":"Don't Stop (G-Style)"},
    {"id":1938,"singerKor":"이현우","singerEng":"Lee Hyun Woo","songKor":"요즘 너는","songEng":"Recently by Standing Egg."},
    {"id":1939,"singerKor":"이효리","singerEng":"Lee Hyori","songKor":"10 Minutes","songEng":"Ten Minutes"},
    {"id":1940,"singerKor":"이효리","singerEng":"Lee Hyori","songKor":"Bad Girls","songEng":"Bad Girls"},
    {"id":1941,"singerKor":"이효리","singerEng":"Lee Hyori","songKor":"Chitty Chitty Bang Bang (Feat. Ceejay Of Freshboyz)","songEng":"Chitty Chitty Bang Bang (Feat. Ceejay Of Freshboyz)"},
    {"id":1942,"singerKor":"이효리","singerEng":"Lee Hyori","songKor":"Get Ya`","songEng":"Get Ya"},
    {"id":1943,"singerKor":"이효리","singerEng":"Lee Hyori","songKor":"Toc Toc Toc","songEng":"Toc Toc Toc."},
    {"id":1944,"singerKor":"이효리","singerEng":"Lee Hyori","songKor":"U go Girl","songEng":"U go Girl"},
    {"id":1945,"singerKor":"이효리","singerEng":"Lee Hyori","songKor":"그녀를 사랑하지마","songEng":"Do not love her."},
    {"id":1946,"singerKor":"이효리","singerEng":"Lee Hyori","songKor":"미스코리아","songEng":"Miss Korea"},
    {"id":1947,"singerKor":"인크레더블_ 타블로_ 지누션","singerEng":"Incredible_ Tablo_ G-NUCCI","songKor":"오빠차","songEng":"Oppa Car"},
    {"id":1948,"singerKor":"인피니트","singerEng":"Infinite","songKor":"추격자","songEng":"Chase Me"},
    {"id":1949,"singerKor":"인피니트(Infinite)","singerEng":"Infinite","songKor":"내꺼하자","songEng":"Let's Get It Started"},
    {"id":1950,"singerKor":"임시","singerEng":"Simsi","songKor":"#VALUE!","songEng":""},
    {"id":1951,"singerKor":"임영웅","singerEng":"Lim Young Woong","songKor":"Bk Love.mp3","songEng":"BK Love"},
    {"id":1952,"singerKor":"임영웅","singerEng":"Im Young Woong","songKor":"HERO.mp3","songEng":"HERO"},
    {"id":1953,"singerKor":"임영웅","singerEng":"Lim Young Woong","songKor":"London Boy.mp3","songEng":"London Boy"},
    {"id":1954,"singerKor":"임영웅","singerEng":"Lim Young Woong","songKor":"Polaroid.mp3","songEng":"Polaroid"},
    {"id":1955,"singerKor":"임영웅","singerEng":"Lim Young Woong","songKor":"끝사랑.mp3","songEng":"Last Love"},
    {"id":1956,"singerKor":"임영웅","singerEng":"Im Young-woong","songKor":"다시 만날 수 있을까.mp3","songEng":"Can't We Meet Again"},
    {"id":1957,"singerKor":"임영웅","singerEng":"Lim Young Woong","songKor":"무지개.mp3","songEng":"Rainbow"},
    {"id":1958,"singerKor":"임영웅","singerEng":"Lim Young Woong","songKor":"별빛 같은 나의 사랑아.mp3","songEng":"Starlight Love by IZ*ONE"},
    {"id":1959,"singerKor":"임영웅","singerEng":"Lim Young Woong","songKor":"사랑은 늘 도망가.mp3","songEng":"Love Always Flee Away"},
    {"id":1960,"singerKor":"임영웅","singerEng":"Lim Young Woong","songKor":"손이 참 곱던 그대.mp3","songEng":"You Are So Beautiful by Kim Ha-neul"},
    {"id":1961,"singerKor":"임영웅","singerEng":"Im Young Woong","songKor":"아버지.mp3","songEng":"Father"},
    {"id":1962,"singerKor":"임영웅","singerEng":"Lim Young Woong","songKor":"우리들의 블루스.mp3","songEng":"Our Blues"},
    {"id":1963,"singerKor":"임영웅","singerEng":"Lim Young Woong","songKor":"이제 나만 믿어요.mp3","songEng":"I Only Trust Myself Now"},
    {"id":1964,"singerKor":"임영웅","singerEng":"Im Young Woong","songKor":"인생찬가.mp3","songEng":"Song of Life"},
    {"id":1965,"singerKor":"임재범","singerEng":"Kim Jae-bum","songKor":"너를 위해.mp3","songEng":"For You"},
    {"id":1966,"singerKor":"임재범","singerEng":"Im Jae Bum","songKor":"사진속에 담긴 추억.mp3","songEng":"Memories in the Photograph"},
    {"id":1967,"singerKor":"임재현","singerEng":"Im Jae Hyun","songKor":"고백하는 취한밤에 (Prod. 2soo).mp3","songEng":"Confessing Drunk at Night (Prod. 2soo)"},
    {"id":1968,"singerKor":"임재현","singerEng":"Im Jae-hyun","songKor":"고백하는 취한밤에 (Prod. by 2soo).mp3","songEng":"Confessing on a Tipsy Night (Prod. by 2soo)"},
    {"id":1969,"singerKor":"임재현","singerEng":"Im Jae-hyun","songKor":"사랑에 연습이 있었다면 (Prod. 2soo).mp3","songEng":"If the song name is already in English_ return english name."},
    {"id":1970,"singerKor":"임재현","singerEng":"Im Jae-hyun","songKor":"조금 취했어 (Prod. 2soo).mp3","songEng":"A little drunk (Prod. 2soo)"},
    {"id":1971,"singerKor":"임정희","singerEng":"Im Jeong-hee","songKor":"사랑아 가지마.mp3","songEng":"La La Love"},
    {"id":1972,"singerKor":"임정희","singerEng":"Im Jeonghee","songKor":"사랑에 미치면 (Feat. 아웃캐스트 빅보이).mp3","songEng":"Crazy In Love (Feat. Outcast Big Boy)"},
    {"id":1973,"singerKor":"임정희","singerEng":"Im Jeong Hee","songKor":"헤어지러 가는 길 (Feat. 조권).mp3","songEng":"On the Way to Break Up (Feat. Jo Kwon)"},
    {"id":1974,"singerKor":"임주리","singerEng":"Im Ju-ri","songKor":"립스틱짙게바르고.mp3","songEng":"Lipstick Boldly on My Lips"},
    {"id":1975,"singerKor":"임지훈","singerEng":"Lim Ji-hoon","songKor":"내 그리운 나라.mp3","songEng":"My beloved country"},
    {"id":1976,"singerKor":"임창정","singerEng":"Im Chang Jung","songKor":"그때 또 다시 (Again).mp3","songEng":"Again"},
    {"id":1977,"singerKor":"임창정","singerEng":"Im Chang Jung","songKor":"기다리는이유.mp3","songEng":"Reasons to Wait"},
    {"id":1978,"singerKor":"임창정","singerEng":"Im Chang Jung","songKor":"나는 트로트가 싫어요.mp3","songEng":"I Hate Trot"},
    {"id":1979,"singerKor":"임창정","singerEng":"Lim Chang Jung","songKor":"나란놈이란.mp3","songEng":"Naran Nomiran"},
    {"id":1980,"singerKor":"임창정","singerEng":"Im Chang-jung","songKor":"나의 연인.mp3","songEng":"My Love."},
    {"id":1981,"singerKor":"임창정","singerEng":"Im Chang Jung","songKor":"날 닮은 너.mp3","songEng":"Nal Damun Neo"},
    {"id":1982,"singerKor":"임창정","singerEng":"Im Chang Jung","songKor":"또 다시 사랑.mp3","songEng":"Once Again Love"},
    {"id":1983,"singerKor":"임창정","singerEng":"Im Chang Jung","songKor":"소주 한 잔.mp3","songEng":"One Shot of Soju"},
    {"id":1984,"singerKor":"임창정","singerEng":"Im Chang-jung","songKor":"슬픈 혼잣말.mp3","songEng":"Sad Lonely_ with You"},
    {"id":1985,"singerKor":"임창정","singerEng":"Lim Chang Jung","songKor":"오랜만이야.mp3","songEng":"Long Time No See"},
    {"id":1986,"singerKor":"임창정","singerEng":"Im Chang Jung","songKor":"하루도 그대를 사랑하지 않은 적이 없었다.mp3","songEng":"I've Never Not Loved You A Day"},
    {"id":1987,"singerKor":"임창정","singerEng":"Im Chang-jung","songKor":"흔한 노래.mp3","songEng":"Common Song"},
    {"id":1988,"singerKor":"임창정","singerEng":"Im Chang-jung","songKor":"힘든 건 사랑이 아니다.mp3","songEng":"Love is not a difficult thing"},
    {"id":1989,"singerKor":"임한별","singerEng":"Im Hanbyul","songKor":"떠나보낼 준비해 둘걸 그랬어.mp3","songEng":"I Should Have Left"},
    {"id":1990,"singerKor":"임한별","singerEng":"Im Han Byul","songKor":"이별하러 가는 길.mp3","songEng":"On the Way to Break Up"},
    {"id":1991,"singerKor":"자메즈 (Ja Mezz)_ Andup (앤덥)_ 송민호","singerEng":"James_ Andup_ Song Minho","songKor":"거북선 (Feat. 팔로알토).mp3","songEng":"Turtle Ship (Feat. Paloalto)"},
    {"id":1992,"singerKor":"자우림","singerEng":"Jaurim","songKor":"매직 카펫 라이드.mp3","songEng":"Magic Carpet Ride"},
    {"id":1993,"singerKor":"자우림","singerEng":"Jaurim","songKor":"스물다섯_ 스물하나.mp3","songEng":"Twenty Five_ Twenty One"},
    {"id":1994,"singerKor":"자우림","singerEng":"Jaurim","songKor":"팬이야.mp3","songEng":"Fan ya"},
    {"id":1995,"singerKor":"자우림","singerEng":"Jaurim","songKor":"하하하쏭.mp3","songEng":"Hahaha Song"},
    {"id":1996,"singerKor":"잔나비","singerEng":"Jannabi","songKor":"뜨거운 여름밤은 가고 남은 건 볼품없지만.mp3","songEng":"Hot Summer Nights"},
    {"id":1997,"singerKor":"잔나비","singerEng":"Jannabi","songKor":"주저하는 연인들을 위해.mp3","songEng":"For Hesitating Lovers"},
    {"id":1998,"singerKor":"장나라","singerEng":"Jang Nara","songKor":"4월 이야기.mp3","songEng":"April Story"},
    {"id":1999,"singerKor":"장나라","singerEng":"Jang Nara","songKor":"Sweet Dream.mp3","songEng":"Sweet Dream"},
    {"id":2000,"singerKor":"장나라","singerEng":"Jang Nara","songKor":"겨울일기.mp3","songEng":"Winter Diary"},
    {"id":2001,"singerKor":"장나라","singerEng":"Jang Nara","songKor":"그게 정말이니.mp3","songEng":"Is It True?"},
    {"id":2002,"singerKor":"장덕","singerEng":"Jang Deok","songKor":"님 떠난 후.mp3","songEng":"After You Left."},
    {"id":2003,"singerKor":"장덕철","singerEng":"Jang Deok-chul","songKor":"그날처럼.mp3","songEng":"That Day."},
    {"id":2004,"singerKor":"장덕철","singerEng":"Jang Deok-chul","songKor":"있어줘요.mp3","songEng":"Stay with me"},
    {"id":2005,"singerKor":"장리인","singerEng":"Jang Ri-in","songKor":"Timeless.mp3","songEng":"Timeless"},
    {"id":2006,"singerKor":"장범준","singerEng":"Jang Beom June","songKor":"노래방에서.mp3","songEng":"Karaoke Room"},
    {"id":2007,"singerKor":"장범준","singerEng":"Jang Beom June","songKor":"당신과는 천천히.mp3","songEng":"Take it Slow (당신과는 천천히)"},
    {"id":2008,"singerKor":"장범준","singerEng":"Jang Beom June","songKor":"사랑에 빠졌죠 (당신만이).mp3","songEng":"Fell in Love (Only You)"},
    {"id":2009,"singerKor":"장우혁","singerEng":"Changwook Jang","songKor":"지지않는 태양.mp3","songEng":"Unfading Sun"},
    {"id":2010,"singerKor":"장재인","singerEng":"Jang Ja-eun","songKor":"가로수 그늘 아래 서면.mp3","songEng":"Under the Shade of a Tree-Lined Road"},
    {"id":2011,"singerKor":"장재인","singerEng":"Jang JaeIn","songKor":"환청 (Feat. 나쑈 (NaShow)).mp3","songEng":"Delusion (Feat. NaShow)"},
    {"id":2012,"singerKor":"장현철","singerEng":"Jang Hyun-chul","songKor":"걸어서 하늘까지.mp3","songEng":"Walking on the Sky"},
    {"id":2013,"singerKor":"장혜진","singerEng":"Jang Hyejin","songKor":"내게로.mp3","songEng":"Come to Me"},
    {"id":2014,"singerKor":"장혜진","singerEng":"Jang Hye-jin","songKor":"마주치지 말자.mp3","songEng":"Let's Not Meet Again"},
    {"id":2015,"singerKor":"장혜진","singerEng":"Jang Hye-jin","songKor":"불꽃 Feat. 개리 from 리쌍.mp3","songEng":"Flower Feat. Gary from Leessang"},
    {"id":2016,"singerKor":"장혜진","singerEng":"Jang Hyejin","songKor":"아름다운 날들.mp3","songEng":"Beautiful Days"},
    {"id":2017,"singerKor":"잼","singerEng":"Zam","songKor":"난 멈추지 않는다.mp3","songEng":"I'm Sorry I Love You"},
    {"id":2018,"singerKor":"저스디스_ 던말릭_ 허성현_ KHAN_ 맥대디_ 로스","singerEng":"Justhis_ Dunn_ Huh Sung-hyun_ Khan_ Makdaddy_ Ross","songKor":"마이웨이 (MY WAY) (Prod. R.Tee).mp3","songEng":"My Way (Prod. R.Tee)"},
    {"id":2019,"singerKor":"적재","singerEng":"Jukjae","songKor":"나랑 같이 걸을래 (바른연애 길잡이 X 적재).mp3","songEng":"Do not say any other word than the song name."},
    {"id":2020,"singerKor":"적재","singerEng":"Jeong Jae.","songKor":"손을 잡는다거나_ 같이 걷는다거나.mp3","songEng":"You Make Me Feel Brand New"},
    {"id":2021,"singerKor":"전람회","singerEng":"Jeon Ramhoe","songKor":"기억의 습작.mp3","songEng":"Memory Sketches"},
    {"id":2022,"singerKor":"전미도","singerEng":"Jeon Mi-do","songKor":"사랑하게 될 줄 알았어.mp3","songEng":"I Will Fall In Love."},
    {"id":2023,"singerKor":"전상근","singerEng":"Jeon Sang-geun","songKor":"사랑은 지날수록 더욱 선명하게 남아.mp3","songEng":"Love grows more vivid as time passes by"},
    {"id":2024,"singerKor":"전상근","singerEng":"Jeon Sang-geun","songKor":"사랑의 시작은 고백에서부터 (Romance) (웹툰 '연애의 발견' X 전상근).mp3","songEng":"Romance"},
    {"id":2025,"singerKor":"전상근","singerEng":"Jeon Sang-geun","songKor":"사랑이란 멜로는 없어.mp3","songEng":"Love Does Not Have Melodrama."},
    {"id":2026,"singerKor":"전상근_ 신예영","singerEng":"Jeon Sang-keun_ Shin Ye-Young","songKor":"안녕 우린 헤어져야만 해.mp3","songEng":"Goodbye_ we have to break up."},
    {"id":2027,"singerKor":"전소미","singerEng":"Jeon Somi","songKor":"DUMB DUMB.mp3","songEng":"DUMB DUMB"},
    {"id":2028,"singerKor":"전영록","singerEng":"Jeon Young-rok","songKor":"아직도 어두운 밤인가봐.mp3","songEng":"It's still a dark night."},
    {"id":2029,"singerKor":"전영록","singerEng":"Jeon Younglok","songKor":"저녁놀.mp3","songEng":"Evening picnic"},
    {"id":2030,"singerKor":"전유나","singerEng":"Jeon Yuna","songKor":"너를 사랑하고도.mp3","songEng":"I'm sorry_ I cannot generate an answer as there are multiple Korean songs with the file name 너를 사랑하고도.mp3 and it's unclear which one is being referred to."},
    {"id":2031,"singerKor":"전인권","singerEng":"Jeon In-kwon","songKor":"돌고 돌고 돌고.mp3","songEng":"Rotating.mp3"},
    {"id":2032,"singerKor":"전혜빈","singerEng":"Jeon Hye-bin","songKor":"2 A.m.mp3","songEng":"Can't Let You Go Even If I Die"},
    {"id":2033,"singerKor":"전효성","singerEng":"Jeon Hyosung","songKor":"Good-night Kiss.mp3","songEng":"Goodnight Kiss"},
    {"id":2034,"singerKor":"정국","singerEng":"Jeongguk","songKor":"Dreamers [Music from the FIFA World Cup Qatar 2022 Official Soundtrack] (feat. FIFA Sound_ BTS).mp3","songEng":"Dreamers"},
    {"id":2035,"singerKor":"정국","singerEng":"Jeongguk","songKor":"Stay Alive (Prod. SUGA of BTS).mp3","songEng":"Stay Alive (Prod SUGA of BTS)"},
    {"id":2036,"singerKor":"정기고","singerEng":"Junggigo","songKor":"너를 원해 (Feat. Beenzino).mp3","songEng":"I Want You (Feat. Beenzino)"},
    {"id":2037,"singerKor":"정동하","singerEng":"Jung Dong-ha","songKor":"추억은 만남보다 이별에 남아.mp3","songEng":"Memories Remain After Goodbye"},
    {"id":2038,"singerKor":"정승환","singerEng":"Jung Seung-hwan","songKor":"너였다면.mp3","songEng":"If It Were You"},
    {"id":2039,"singerKor":"정승환","singerEng":"Jung Seung-hwan","songKor":"눈사람.mp3","songEng":"Snowman"},
    {"id":2040,"singerKor":"정승환","singerEng":"Jung Seung-hwan","songKor":"이 바보야.mp3","songEng":"This Foolish Love"},
    {"id":2041,"singerKor":"정승환_ 박윤하","singerEng":"Jeong Seung-hwan_ Park Yoon-ha","songKor":"슬픔 속에 그댈 지워야만 해.mp3","songEng":"I have to erase you from my sadness"},
    {"id":2042,"singerKor":"정용화 (씨엔블루)","singerEng":"Jung Yong Hwa (CNBLUE)","songKor":"처음 사랑하는 연인들을 위해 (반말송).mp3","songEng":"For First-Time Lovers (Casual Song)"},
    {"id":2043,"singerKor":"정은지","singerEng":"Jeong Eun-ji","songKor":"하늘바라기 (Feat. 하림).mp3","songEng":"Longing for the Sky (Feat. Harim)"},
    {"id":2044,"singerKor":"정인_ 개리","singerEng":"Jeong In_ Gary","songKor":"사람냄새.mp3","songEng":"Human Scent"},
    {"id":2045,"singerKor":"정재욱","singerEng":"Jeong Jae-wook","songKor":"Season In The Sun.mp3","songEng":"Season In The Sun"},
    {"id":2046,"singerKor":"정재욱","singerEng":"Jung Jaewook","songKor":"가만히 눈을 감고.mp3","songEng":"Close Your Eyes by Bumkey."},
    {"id":2047,"singerKor":"정재욱","singerEng":"Jung Jaewook","songKor":"들리나요.mp3","songEng":"Can't you hear me"},
    {"id":2048,"singerKor":"정재욱","singerEng":"Jung Jae-wook","songKor":"잘가요.mp3","songEng":"Goodbye"},
    {"id":2049,"singerKor":"정준영","singerEng":"Jung Joon Young","songKor":"공감 (Feat. 서영은).mp3","songEng":"Empathy (Feat. Seo Young Eun)"},
    {"id":2050,"singerKor":"정준일","singerEng":"Jung Joon-il","songKor":"안아줘.mp3","songEng":"Hug Me"},
    {"id":2051,"singerKor":"정준일","singerEng":"Jeong Joon-il","songKor":"첫 눈.mp3","songEng":"First Snow"},
    {"id":2052,"singerKor":"정키","singerEng":"Jungkook","songKor":"부담이 돼 (Feat. 휘인 Of 마마무).mp3","songEng":"It's You (Feat. Hwasa Of Mamamoo)"},
    {"id":2053,"singerKor":"정키","singerEng":"Jungkook","songKor":"홀로 (feat. 김나영).mp3","songEng":"Alone (feat. Kim Na-young)"},
    {"id":2054,"singerKor":"정형돈","singerEng":"Jung Hyung Don","songKor":"강북멋쟁이.mp3","songEng":"Gangnam Style"},
    {"id":2055,"singerKor":"제니 (JENNIE)","singerEng":"JENNIE","songKor":"SOLO.mp3","songEng":"Solo"},
    {"id":2056,"singerKor":"제드","singerEng":"Jed","songKor":"사랑의 숲에서 길을 잃다 (Feat. 임창정).mp3","songEng":"Lost in Love Forest (Feat. Lim Chang Jung)"},
    {"id":2057,"singerKor":"제시 (Jessi)","singerEng":"Jessi","songKor":"ZOOM.mp3","songEng":"Zoom"},
    {"id":2058,"singerKor":"제시 (Jessi)","singerEng":"Jessi","songKor":"눈누난나 (NUNU NANA).mp3","songEng":"Nunu Nana"},
    {"id":2059,"singerKor":"제시 (Jessi)_ 치타_ 강남","singerEng":"Jessi_ Cheetah_ Gangnam","songKor":"My Type (Prod. By 버벌진트).mp3","songEng":"My Type (Prod By 버벌진트)"},
    {"id":2060,"singerKor":"제이","singerEng":"Jay","songKor":"눈물로 (Duet 이정).mp3","songEng":"By Tears"},
    {"id":2061,"singerKor":"제이","singerEng":"Jay","songKor":"슬프지만 진실.mp3","songEng":"Sad But True."},
    {"id":2062,"singerKor":"제이워크","singerEng":"Jaywalk","songKor":"Suddenly.mp3","songEng":"Suddenly"},
    {"id":2063,"singerKor":"제이워크","singerEng":"Jaywalk","songKor":"Secret.mp3","songEng":"Secret"},
    {"id":2064,"singerKor":"제이티엘(JTL)","singerEng":"JTL","songKor":"A Better Day.mp3","songEng":"A Better Day"},
    {"id":2065,"singerKor":"조 성모","singerEng":"Jo Sungmo","songKor":"가시나무.mp3","songEng":"Thorn Tree"},
    {"id":2066,"singerKor":"조PD","singerEng":"Jo PD","songKor":"친구여 (Feat.인순이).mp3","songEng":"Friend (Feat. Insun Lee)"},
    {"id":2067,"singerKor":"조관우","singerEng":"Jo Kwan-woo","songKor":"모래성.mp3","songEng":"Sandcastle"},
    {"id":2068,"singerKor":"조관우","singerEng":"Jong Kwan Woo","songKor":"영원.mp3","songEng":"Forever"},
    {"id":2069,"singerKor":"조규찬","singerEng":"Joh Kyuchan","songKor":"Baby Baby.mp3","songEng":"Baby Baby"},
    {"id":2070,"singerKor":"조덕배","singerEng":"Jodeokbae","songKor":"꿈에.mp3","songEng":"Dream."},
    {"id":2071,"singerKor":"조동진","singerEng":"Jo Dong Jin","songKor":"나뭇잎 사이로.mp3","songEng":"Through the Tree Leaves"},
    {"id":2072,"singerKor":"조성모","singerEng":"Jo Sung-mo","songKor":"For Your Soul (슬픈 영혼식).mp3","songEng":"For Your Soul"},
    {"id":2073,"singerKor":"조성모","singerEng":"Jo Sung-mo","songKor":"Mr. Flower.mp3","songEng":"Mr Flower"},
    {"id":2074,"singerKor":"조성모","singerEng":"Jo Sung-mo","songKor":"가시나무.mp3","songEng":"Thorn Tree"},
    {"id":2075,"singerKor":"조성모","singerEng":"Jo Sung-mo","songKor":"너의 곁으로.mp3","songEng":"By Your Side"},
    {"id":2076,"singerKor":"조성모","singerEng":"Jo Sung-mo","songKor":"눈물이나요.mp3","songEng":"Tears are Falling"},
    {"id":2077,"singerKor":"조성모","singerEng":"Jo Sung-mo","songKor":"다짐.mp3","songEng":"Promise"},
    {"id":2078,"singerKor":"조성모","singerEng":"Jo Sung-mo","songKor":"불멸의 사랑.mp3","songEng":"Immortal Love"},
    {"id":2079,"singerKor":"조성모","singerEng":"Jung Sungha","songKor":"상처(像處).mp3","songEng":"Scar"},
    {"id":2080,"singerKor":"조성모","singerEng":"Jo Sung-mo","songKor":"아시나요.mp3","songEng":"Do You Know?"},
    {"id":2081,"singerKor":"조성모","singerEng":"Jang Seung-mo","songKor":"잘가요...내사랑.mp3","songEng":"Goodbye...My Love"},
    {"id":2082,"singerKor":"조성모","singerEng":"Jo Sung-mo","songKor":"피아노.mp3","songEng":"Piano"},
    {"id":2083,"singerKor":"조수미","singerEng":"Jo Sumi","songKor":"나가거든.mp3","songEng":"Nagagodeun"},
    {"id":2084,"singerKor":"조용필","singerEng":"Jonghyun","songKor":"Bounce.mp3","songEng":"Bounce"},
    {"id":2085,"singerKor":"조용필","singerEng":"Jo Yong Pil","songKor":"고추잠자리.mp3","songEng":"Red Dragonfly"},
    {"id":2086,"singerKor":"조용필","singerEng":"Jo Yong Pil","songKor":"꽃바람.mp3","songEng":"Flower Wind"},
    {"id":2087,"singerKor":"조용필","singerEng":"Jo Yong Pil","songKor":"난 아니야.mp3","songEng":"I'm Not OK"},
    {"id":2088,"singerKor":"조용필","singerEng":"Jo Yong-pil","songKor":"눈물의 파티.mp3","songEng":"Name of the song is Tears of the Party."},
    {"id":2089,"singerKor":"조용필","singerEng":"Jo Yong-pil","songKor":"단발머리.mp3","songEng":"Short Hair"},
    {"id":2090,"singerKor":"조용필","singerEng":"Jo Yong Pil","songKor":"어제_ 오늘 그리고.mp3","songEng":"Yesterday_ Today and..."},
    {"id":2091,"singerKor":"조용필","singerEng":"Jo Yong Pil","songKor":"허공.mp3","songEng":"Empty."},
    {"id":2092,"singerKor":"조이 (JOY)","singerEng":"JOY","songKor":"안녕 (Hello).mp3","songEng":"Hello"},
    {"id":2093,"singerKor":"조이 (JOY)","singerEng":"JOY","songKor":"좋은 사람 있으면 소개시켜줘.mp3","songEng":"Introduce Me a Good Person"},
    {"id":2094,"singerKor":"조장혁","singerEng":"Jang Hyuk Jo","songKor":"중독된 사랑.mp3","songEng":"Addicted Love"},
    {"id":2095,"singerKor":"조정석","singerEng":"Jo Jung-suk","songKor":"아로하.mp3","songEng":"Aloha"},
    {"id":2096,"singerKor":"조하문","singerEng":"Johamun","songKor":"이밤을 다시 한번.mp3","songEng":"Once Again This Night"},
    {"id":2097,"singerKor":"조현아 (어반자카파)","singerEng":"Jo Hyun-ah (Urban Zakapa)","songKor":"늦은 후회.mp3","songEng":"Late Regret"},
    {"id":2098,"singerKor":"존박","singerEng":"John Park","songKor":"Falling.mp3","songEng":"Falling"},
    {"id":2099,"singerKor":"존박","singerEng":"John Park","songKor":"네 생각.mp3","songEng":"I Think of You"},
    {"id":2100,"singerKor":"종현 (JONGHYUN)","singerEng":"JONGHYUN","songKor":"Lonely (Feat. 태연).mp3","songEng":"Lonely (Feat. Taeyeon)"},
    {"id":2101,"singerKor":"주","singerEng":"Ju","songKor":"남자 때문에.mp3","songEng":"Because of a Man."},
    {"id":2102,"singerKor":"주니엘","singerEng":"JUNIEL.","songKor":"illa illa (일라 일라).mp3","songEng":"Illa Illa"},
    {"id":2103,"singerKor":"주석","singerEng":"Ju Seok","songKor":"무한대.mp3","songEng":"Infinite."},
    {"id":2104,"singerKor":"주석","singerEng":"Ju Seok","songKor":"정상을 향한 독주 2 (Feat. 김범수).mp3","songEng":"Towards the Top Solo 2 (Feat. Kim Bum Soo)"},
    {"id":2105,"singerKor":"주시크 (Joosiq)","singerEng":"Joosiq","songKor":"너를 생각해.mp3","songEng":"Thinking of You"},
    {"id":2106,"singerKor":"주시크 (Joosiq)","singerEng":"Sorry_ as an AI language model_ I'm unable to provide the name of the Korean artist in English_ 주시크_ as I'm not programmed to guess or memorize information nor browse the internet. However_ I can say that period is not necessary in my reply.","songKor":"아무래도 난.mp3","songEng":"Amuraedo Nan."},
    {"id":2107,"singerKor":"주영훈","singerEng":"Ju Young-hoon","songKor":"노을의 연가.mp3","songEng":"Song name: The Song of the Sunset"},
    {"id":2108,"singerKor":"주현미","singerEng":"Ju Hyun-mi","songKor":"짝사랑.mp3","songEng":"One-sided Love"},
    {"id":2109,"singerKor":"주호","singerEng":"Joo Ho","songKor":"그 하루 (feat. 반하나).mp3","songEng":"That Day (feat. Banhana)"},
    {"id":2110,"singerKor":"주호","singerEng":"Joo Ho","songKor":"잘가요.mp3","songEng":"Goodbye"},
    {"id":2111,"singerKor":"주호_ 반하나","singerEng":"Juho_ Banhana","songKor":"그 하루 (feat. 반하나).mp3","songEng":"That Day (feat. Banhana)"},
    {"id":2112,"singerKor":"쥬얼리(Jewelry)","singerEng":"Jewelry","songKor":"One More Time.mp3","songEng":"One More Time"},
    {"id":2113,"singerKor":"쥬얼리(Jewelry)","singerEng":"Jewelry","songKor":"Super Star.mp3","songEng":"Superstar"},
    {"id":2114,"singerKor":"쥬얼리(Jewelry)","singerEng":"Jewelry","songKor":"모두다쉿!.mp3","songEng":"Everybody Be Quiet or Shh!"},
    {"id":2115,"singerKor":"지나","singerEng":"Gina","songKor":"2Hot.mp3","songEng":"2Hot"},
    {"id":2116,"singerKor":"지누션","singerEng":"Jinusean","songKor":"전화번호.mp3","songEng":"Telephone Number"},
    {"id":2117,"singerKor":"지누션","singerEng":"Zion.T","songKor":"한번 더 말해줘 (Feat. 장한나).mp3","songEng":"Say it Again (Feat. Jang Hanna)"},
    {"id":2118,"singerKor":"지다연","singerEng":"Ji Dayoung","songKor":"동반자.mp3","songEng":"Traveler"},
    {"id":2119,"singerKor":"지드래곤 (G-Dragon)","singerEng":"G-Dragon","songKor":"Black (Feat. Jennie Kim Of YG New Artist).mp3","songEng":"Black"},
    {"id":2120,"singerKor":"지드래곤 (G-Dragon)","singerEng":"G-Dragon","songKor":"Missing You (Feat. 김윤아 Of 자우림).mp3","songEng":"Missing You (Feat. Kim Yuna Of Jaurim)"},
    {"id":2121,"singerKor":"지드래곤 (G-Dragon)","singerEng":"G-Dragon","songKor":"그XX.mp3","songEng":"That song is titled That XX"},
    {"id":2122,"singerKor":"지드래곤 (G-Dragon)","singerEng":"G-Dragon","songKor":"니가 뭔데 (Who You).mp3","songEng":"Who You"},
    {"id":2123,"singerKor":"지드래곤 (G-Dragon)","singerEng":"G-Dragon","songKor":"삐딱하게 (Crooked).mp3","songEng":"Crooked"},
    {"id":2124,"singerKor":"지드래곤 (G-Dragon)","singerEng":"G-Dragon","songKor":"크레용 (Crayon).mp3","songEng":"Crayon"},
    {"id":2125,"singerKor":"지민_ 슬옹","singerEng":"Jimin_ Seulong","songKor":"시작이 좋아 2015 (Prod. By 버벌진트).mp3","songEng":"Good Start 2015 (Prod. By Verbal Jint)"},
    {"id":2126,"singerKor":"지민_ 하성운","singerEng":"Jimin Ha Sungwoon","songKor":"With You.mp3","songEng":"With You"},
    {"id":2127,"singerKor":"지아(Zia)","singerEng":"Zia","songKor":"내가 이렇지.mp3","songEng":"I'm So Afraid."},
    {"id":2128,"singerKor":"지아(Zia)","singerEng":"Zia","songKor":"술 한잔 해요.mp3","songEng":"One More Drink"},
    {"id":2129,"singerKor":"지아(Zia)","singerEng":"Zia","songKor":"웃음만...mp3","songEng":"Laughter."},
    {"id":2130,"singerKor":"지아_ 허각","singerEng":"Jia_ Heo Gak","songKor":"술이 뭐길래.mp3","songEng":"What."},
    {"id":2131,"singerKor":"지영선","singerEng":"Ji Youngsun","songKor":"가슴앓이.mp3","songEng":"Heartbreak"},
    {"id":2132,"singerKor":"지오디(god)","singerEng":"GOD","songKor":"길.mp3","songEng":"Street"},
    {"id":2133,"singerKor":"지코 (ZICO (Block B))","singerEng":"ZICO (Block B)","songKor":"Boys And Girls (feat. Babylon).mp3","songEng":"Boys And Girls (feat. Babylon)"},
    {"id":2134,"singerKor":"지코 (ZICO)","singerEng":"ZICO","songKor":"Boys And Girls (Feat. Babylon).mp3","songEng":"Boys And Girls (Feat. Babylon)"},
    {"id":2135,"singerKor":"지코 (ZICO)","singerEng":"ZICO","songKor":"She`s a Baby.mp3","songEng":"She's a Baby"},
    {"id":2136,"singerKor":"지코 (ZICO)","singerEng":"ZICO","songKor":"SoulMate (Feat. 아이유).mp3","songEng":"SoulMate (Feat. IU)"},
    {"id":2137,"singerKor":"지코 (ZICO)","singerEng":"ZICO","songKor":"Summer Hate (Feat. 비).mp3","songEng":"Summer Hate (Feat. Bi)"},
    {"id":2138,"singerKor":"지코 (ZICO)","singerEng":"ZICO","songKor":"너는 나 나는 너.mp3","songEng":"You Are Me_ I Am You"},
    {"id":2139,"singerKor":"지코 (ZICO)","singerEng":"ZICO","songKor":"사랑이었다 (Feat. 루나 of f(x)).mp3","songEng":"Love was (Feat. Luna of f(x))"},
    {"id":2140,"singerKor":"지코 (ZICO)","singerEng":"ZICO","songKor":"새삥 (Prod. by ZICO) (Feat. 호미들).mp3","songEng":"Saebyuk"},
    {"id":2141,"singerKor":"지코 (ZICO)","singerEng":"ZICO","songKor":"아무노래.mp3","songEng":"Any Song"},
    {"id":2142,"singerKor":"지코 (ZICO)","singerEng":"ZICO","songKor":"유레카 (Feat. Zion.T).mp3","songEng":"Eureka (Feat. Zion.T)"},
    {"id":2143,"singerKor":"진","singerEng":"Jin","songKor":"The Astronaut.mp3","songEng":"The Astronaut"},
    {"id":2144,"singerKor":"진민호","singerEng":"Jin Minho","songKor":"반만.mp3","songEng":"Half."},
    {"id":2145,"singerKor":"진주","singerEng":"Jinju","songKor":"가니.mp3","songEng":"Gani"},
    {"id":2146,"singerKor":"차태현","singerEng":"Chae Tae-hyun","songKor":"Again To Me.mp3","songEng":"Again To Me"},
    {"id":2147,"singerKor":"차태현","singerEng":"Cha Tae-hyun","songKor":"I Love You.mp3","songEng":"I Love You"},
    {"id":2148,"singerKor":"찬열 (CHANYEOL)_ 펀치 (Punch)","singerEng":"Chanyeol_ Punch","songKor":"Stay With Me.mp3","songEng":"Stay With Me"},
    {"id":2149,"singerKor":"창모 (CHANGMO)","singerEng":"Changmo","songKor":"METEOR.mp3","songEng":"Meteor"},
    {"id":2150,"singerKor":"창모 (CHANGMO)","singerEng":"Changmo.","songKor":"마에스트로 (Maestro).mp3","songEng":"Maestro"},
    {"id":2151,"singerKor":"창모 (CHANGMO)","singerEng":"Changmo","songKor":"빌었어.mp3","songEng":"Borrowed."},
    {"id":2152,"singerKor":"창모 (CHANGMO)","singerEng":"Changmo","songKor":"아름다워.mp3","songEng":"Beautiful."},
    {"id":2153,"singerKor":"창모_Hash Swan_ASH ISLAND_김효은","singerEng":"Changmo_ Hash Swan_ ASH ISLAND_ Kim Hyoeun","songKor":"BAND.mp3","songEng":"Band."},
    {"id":2154,"singerKor":"채연","singerEng":"Chaeyeon","songKor":"My Love.mp3","songEng":"My Love"},
    {"id":2155,"singerKor":"채연","singerEng":"ChaeYeon","songKor":"둘이서.mp3","songEng":"Two People"},
    {"id":2156,"singerKor":"채연","singerEng":"Chae Yeon","songKor":"흔들려.mp3","songEng":"Shake It"},
    {"id":2157,"singerKor":"채정안","singerEng":"Chae Jung-an","songKor":"편지(remix).mp3","songEng":"Letter (remix)"},
    {"id":2158,"singerKor":"처진 달팽이","singerEng":"Chojin Dalgpaengi","songKor":"압구정 날라리.mp3","songEng":"Apgujeong Nallari"},
    {"id":2159,"singerKor":"철싸","singerEng":"Cheolsa","songKor":"흔들어 주세요.mp3","songEng":"Shake It by SISTAR"},
    {"id":2160,"singerKor":"청하","singerEng":"Chung Ha","songKor":"Roller Coaster.mp3","songEng":"Roller Coaster"},
    {"id":2161,"singerKor":"청하","singerEng":"Chung Ha","songKor":"Snapping.mp3","songEng":"Snapping"},
    {"id":2162,"singerKor":"청하","singerEng":"Chung Ha","songKor":"Sparkling.mp3","songEng":"Sparkling"},
    {"id":2163,"singerKor":"청하","singerEng":"Chung Ha","songKor":"Why Don`t You Know (Feat. 넉살).mp3","songEng":"Why Don't You Know (Feat. 넉살)"},
    {"id":2164,"singerKor":"청하","singerEng":"Chungha","songKor":"벌써 12시.mp3","songEng":"Already 12 am"},
    {"id":2165,"singerKor":"체리필터","singerEng":"Cherry Filter","songKor":"낭만 고양이.mp3","songEng":"Romantic Cat"},
    {"id":2166,"singerKor":"체리필터","singerEng":"Cherry Filter","songKor":"내게로와.mp3","songEng":"Come to Me"},
    {"id":2167,"singerKor":"첸 (CHEN)","singerEng":"CHEN","songKor":"사월이 지나면 우리 헤어져요 (Beautiful goodbye).mp3","songEng":"Beautiful goodbye"},
    {"id":2168,"singerKor":"첸 (CHEN)","singerEng":"Chen","songKor":"최고의 행운.mp3","songEng":"Best of Luck"},
    {"id":2169,"singerKor":"첸 (CHEN)_ 백현 (BAEKHYUN)_ 시우민 (XIUMIN)","singerEng":"Chen Baekhyun Xiumin","songKor":"너를 위해.mp3","songEng":"For You"},
    {"id":2170,"singerKor":"첸 (CHEN)_ 펀치 (Punch)","singerEng":"Chen_ Punch","songKor":"Everytime.mp3","songEng":"Everytime"},
    {"id":2171,"singerKor":"최예나(YENA)","singerEng":"Choi Yena","songKor":"SMILEY (Feat. BIBI).mp3","songEng":"Smiley"},
    {"id":2172,"singerKor":"최유리","singerEng":"Choi Yoo-ri","songKor":"그댄 행복에 살텐데 (2022).mp3","songEng":"You Are Living in Happiness."},
    {"id":2173,"singerKor":"최재훈","singerEng":"Choi Jaehoon","songKor":"비의 랩소디.mp3","songEng":"Rain's Rhapsody"},
    {"id":2174,"singerKor":"최재훈","singerEng":"Choi Jaehoon","songKor":"편지.mp3","songEng":"Letter"},
    {"id":2175,"singerKor":"최혜영","singerEng":"Choi Hye-young","songKor":"그것은 인생.mp3","songEng":"That is Life"},
    {"id":2176,"singerKor":"치타","singerEng":"Cheeta","songKor":"아무도 모르게 (Prod. by MC몽).mp3","songEng":"Nobody Knows (Prod. by MC Mong)"},
    {"id":2177,"singerKor":"카라(Kara)","singerEng":"Kara","songKor":"Honey.mp3","songEng":"Honey"},
    {"id":2178,"singerKor":"카라(Kara)","singerEng":"Kara","songKor":"Pandora.mp3","songEng":"Pandora"},
    {"id":2179,"singerKor":"카라(Kara)","singerEng":"Kara","songKor":"Pretty Girl.mp3","songEng":"Pretty Girl"},
    {"id":2180,"singerKor":"카라(Kara)","singerEng":"Kara","songKor":"STEP.mp3","songEng":"Step"},
    {"id":2181,"singerKor":"카라(Kara)","singerEng":"Kara","songKor":"루팡 (Lupin).mp3","songEng":"Lupin"},
    {"id":2182,"singerKor":"카라(Kara)","singerEng":"Kara","songKor":"미스터.mp3","songEng":"Mister"},
    {"id":2183,"singerKor":"캔","singerEng":"Kan","songKor":"내 생에 봄날은.mp3","songEng":"Spring Day"},
    {"id":2184,"singerKor":"컨츄리 꼬꼬","singerEng":"Country Koko","songKor":"[2] 콩가.mp3","songEng":"Kongga"},
    {"id":2185,"singerKor":"컨츄리꼬꼬","singerEng":"Country Coco","songKor":"오 가니.mp3","songEng":"Oh NaNa."},
    {"id":2186,"singerKor":"케이시 (Kassy)","singerEng":"Kassy","songKor":"가을밤 떠난 너.mp3","songEng":"Autumn Night by You who Left."},
    {"id":2187,"singerKor":"케이시 (Kassy)","singerEng":"Kassy","songKor":"그때가 좋았어.mp3","songEng":"It's Good Old Days."},
    {"id":2188,"singerKor":"케이시 (Kassy)","singerEng":"Kassy","songKor":"나 그댈위해 시 한편을 쓰겠어.mp3","songEng":"I'll Write a Letter for You."},
    {"id":2189,"singerKor":"케이시 (Kassy)","singerEng":"Kassy","songKor":"늦은 밤 헤어지긴 너무 아쉬워.mp3","songEng":"Late Night Goodbyes are Too Heartbreaking."},
    {"id":2190,"singerKor":"케이시 (Kassy)","singerEng":"Kassy","songKor":"사랑이야.mp3","songEng":"Love is."},
    {"id":2191,"singerKor":"케이시 (Kassy)","singerEng":"Kassy","songKor":"언제나 사랑해.mp3","songEng":"Always love you"},
    {"id":2192,"singerKor":"케이시 (Kassy)","singerEng":"Kassy","songKor":"진심이 담긴 노래 (True Song).mp3","songEng":"True Song"},
    {"id":2193,"singerKor":"케이시 (Kassy)","singerEng":"Kassy","songKor":"찬바람이 불어오네요.mp3","songEng":"Blowing in the Cold Wind"},
    {"id":2194,"singerKor":"케이윌","singerEng":"K.Will","songKor":"Love Blossom (러브블러썸).mp3","songEng":"Love Blossom"},
    {"id":2195,"singerKor":"케이윌","singerEng":"K.Will","songKor":"가슴이 뛴다.mp3","songEng":"My Love is Gone"},
    {"id":2196,"singerKor":"케이윌","singerEng":"K.Will","songKor":"그립고 그립고 그립다.mp3","songEng":"Missing You Missing You Missing You."},
    {"id":2197,"singerKor":"케이윌","singerEng":"K.Will","songKor":"꽃이 핀다.mp3","songEng":"Blooming Days"},
    {"id":2198,"singerKor":"케이윌","singerEng":"K.Will","songKor":"내 생에 아름다운.mp3","songEng":"In My Life_ Beautiful."},
    {"id":2199,"singerKor":"케이윌","singerEng":"K.Will","songKor":"러브119 (Love119) (feat. MC몽).mp3","songEng":"Love119 (feat. MC Mong)"},
    {"id":2200,"singerKor":"케이윌","singerEng":"K.Will","songKor":"말해! 뭐해.mp3","songEng":"Tell Me What You're Doing"},
    {"id":2201,"singerKor":"케이윌","singerEng":"K.Will","songKor":"별처럼.mp3","songEng":"Star Like."},
    {"id":2202,"singerKor":"케이윌","singerEng":"K.Will","songKor":"선물 (Feat. 은지원).mp3","songEng":"Gift (Feat. Eun Ji Won)"},
    {"id":2203,"singerKor":"케이윌","singerEng":"K.Will","songKor":"시간을 거슬러 (낮에 뜨는 달 X 케이윌).mp3","songEng":"Rewind the Time (Moon Embracing the Sun OST)"},
    {"id":2204,"singerKor":"케이윌","singerEng":"K.Will","songKor":"오늘부터 1일 (Day 1).mp3","songEng":"Day 1"},
    {"id":2205,"singerKor":"케이윌","singerEng":"K.Will","songKor":"이러지마 제발 (Please Don`t...).mp3","songEng":"Please Don't"},
    {"id":2206,"singerKor":"케이윌","singerEng":"K.Will","songKor":"촌스럽게 왜 이래 (You Don’t Know Love).mp3","songEng":"You Don't Know Love."},
    {"id":2207,"singerKor":"코요태","singerEng":"Koyote","songKor":"03.1_2_3_4. (원_투_쓰리_포.).mp3","songEng":"One Two Three Four"},
    {"id":2208,"singerKor":"코요태","singerEng":"Koyote","songKor":"I Love Rock & Roll.mp3","songEng":"I Love Rock n Roll"},
    {"id":2209,"singerKor":"코요태","singerEng":"Koyote","songKor":"Passsion.mp3","songEng":"Passion"},
    {"id":2210,"singerKor":"코요태","singerEng":"Koyote","songKor":"Together.mp3","songEng":"Together"},
    {"id":2211,"singerKor":"코요태","singerEng":"Koyote","songKor":"불꽃.mp3","songEng":"Fireworks"},
    {"id":2212,"singerKor":"코요태","singerEng":"Koyote","songKor":"비몽.mp3","songEng":"Dream."},
    {"id":2213,"singerKor":"코요태","singerEng":"Koyote","songKor":"비상.mp3","songEng":"Emergency"},
    {"id":2214,"singerKor":"코요태","singerEng":"Koyote","songKor":"빙고.mp3","songEng":"Bingo"},
    {"id":2215,"singerKor":"코요태","singerEng":"Koyote","songKor":"애련.mp3","songEng":"Sorrowful Love"},
    {"id":2216,"singerKor":"코요태","singerEng":"Koyote","songKor":"파란.mp3","songEng":"Blue"},
    {"id":2217,"singerKor":"쿠기 (Coogie)_ BE'O (비오)","singerEng":"Coogie_ Be'o","songKor":"Good Night (Feat. BE'O).mp3","songEng":"Good Night (Feat. BE'O)"},
    {"id":2218,"singerKor":"크라운 제이(Crown J)","singerEng":"Crown J","songKor":"Too Much (feat. 서인영).mp3","songEng":"Too Much (feat. Seo In-young)"},
    {"id":2219,"singerKor":"크레용팝","singerEng":"Crayon Pop","songKor":"빠빠빠.mp3","songEng":"Bar Bar Bar"},
    {"id":2220,"singerKor":"클래지콰이","singerEng":"Clazziqiu","songKor":"She Is.mp3","songEng":"She Is"},
    {"id":2221,"singerKor":"클래지콰이","singerEng":"Clazziquai.","songKor":"Sweety.mp3","songEng":"Sweet Girl"},
    {"id":2222,"singerKor":"클론","singerEng":"Clon","songKor":"꿍따리 샤바라.mp3","songEng":"Kung Ddari Sha-Ba-La"},
    {"id":2223,"singerKor":"클론","singerEng":"Clone","songKor":"랄랄라 (feat. 엄정화).mp3","songEng":"Lallala (feat. Uhm Jung Hwa)"},
    {"id":2224,"singerKor":"클론","singerEng":"Clon","songKor":"초련 (Techno Mix).mp3","songEng":"Choryeon Techno Mix"},
    {"id":2225,"singerKor":"클릭비","singerEng":"Click-B","songKor":"백전무패.mp3","songEng":"No song name provided."},
    {"id":2226,"singerKor":"키썸_ San E_ 태완","singerEng":"Kisum_ San E_ Taewan","songKor":"슈퍼스타 (Prod. by D.O).mp3","songEng":"Superstar"},
    {"id":2227,"singerKor":"타블로","singerEng":"Tablo","songKor":"나쁘다 (feat. 진실).mp3","songEng":"Bad (feat. Jin Sil)"},
    {"id":2228,"singerKor":"탑현","singerEng":"Tophyun","songKor":"호랑수월가.mp3","songEng":"Tiger Flower Path"},
    {"id":2229,"singerKor":"태빈","singerEng":"Taebin","songKor":"내가 눈을 감는 이유.mp3","songEng":"Reason for Closing My Eyes"},
    {"id":2230,"singerKor":"태양","singerEng":"Taeyang","songKor":"I Need A Girl (Feat. G-dragon).mp3","songEng":"I Need A Girl Featuring G-dragon"},
    {"id":2231,"singerKor":"태양","singerEng":"Taeyang","songKor":"VIBE (Feat. Jimin of BTS).mp3","songEng":"VIBE (Feat. Jimin of BTS)"},
    {"id":2232,"singerKor":"태양","singerEng":"Taeyang","songKor":"나만 바라봐.mp3","songEng":"Only look at me."},
    {"id":2233,"singerKor":"태양","singerEng":"Taeyang","songKor":"눈_ 코_ 입.mp3","songEng":"Eyes Nose Lips"},
    {"id":2234,"singerKor":"태연 (TAEYEON)","singerEng":"Taeyeon","songKor":"Can't Control Myself.mp3","songEng":"Can't Control Myself"},
    {"id":2235,"singerKor":"태연 (TAEYEON)","singerEng":"Taeyeon","songKor":"Fine.mp3","songEng":"Fine"},
    {"id":2236,"singerKor":"태연 (Taeyeon)","singerEng":"Taeyeon","songKor":"I (Feat. 버벌진트).mp3","songEng":"I (Feat. Verbal Jint)"},
    {"id":2237,"singerKor":"태연 (TAEYEON)","singerEng":"Taeyeon","songKor":"Rain.mp3","songEng":"Rain"},
    {"id":2238,"singerKor":"태연 (TAEYEON)","singerEng":"Taeyeon","songKor":"Weekend.mp3","songEng":"Weekend"},
    {"id":2239,"singerKor":"태연 (TAEYEON)","singerEng":"Taeyeon","songKor":"그런 밤 (Some Nights).mp3","songEng":"Some Nights"},
    {"id":2240,"singerKor":"태연 (TAEYEON)","singerEng":"Taeyeon","songKor":"들리나요....mp3","songEng":"Can't Hear You"},
    {"id":2241,"singerKor":"태연 (TAEYEON)","singerEng":"Taeyeon","songKor":"만약에.mp3","songEng":"If"},
    {"id":2242,"singerKor":"태연 (TAEYEON)","singerEng":"Taeyeon","songKor":"사계 (Four Seasons).mp3","songEng":"Four Seasons"},
    {"id":2243,"singerKor":"태연 (TAEYEON)","singerEng":"Taeyeon","songKor":"혼자서 걸어요 (Prod. by 나얼).mp3","songEng":"I Walk Alone (Prod. by Naul)"},
    {"id":2244,"singerKor":"태연(TAEYEON)","singerEng":"Taeyeon","songKor":"Can't Control Myself.mp3","songEng":"Can't Control Myself"},
    {"id":2245,"singerKor":"태일 (TAEIL)","singerEng":"Taeil","songKor":"Starlight.mp3","songEng":"Starlight"},
    {"id":2246,"singerKor":"터보","singerEng":"Turbo","songKor":"CYBER LOVER.mp3","songEng":"Cyber Lover"},
    {"id":2247,"singerKor":"터보","singerEng":"Turbo","songKor":"LOVE IS(3+3.mp3","songEng":"Love Is."},
    {"id":2248,"singerKor":"터보","singerEng":"Turbo","songKor":"애인이 생겼어요.mp3","songEng":"I Have a Lover"},
    {"id":2249,"singerKor":"테이(Tei)","singerEng":"Tei","songKor":"Monologue.mp3","songEng":"Monologue"},
    {"id":2250,"singerKor":"테이(Tei)","singerEng":"Tei","songKor":"같은 베개....mp3","songEng":"Same Pillow"},
    {"id":2251,"singerKor":"테이(Tei)","singerEng":"Tei","songKor":"그리움을 사랑한 가시나무.mp3","songEng":"Thorn Tree Loving the Longing."},
    {"id":2252,"singerKor":"테이(Tei)","singerEng":"Tei","songKor":"닮은사람.mp3","songEng":"Similar Person"},
    {"id":2253,"singerKor":"테이(Tei)","singerEng":"Tei","songKor":"사랑은 하나다.mp3","songEng":"Love is One."},
    {"id":2254,"singerKor":"테이(Tei)","singerEng":"Tei","songKor":"사랑은...향기를 남기고....mp3","songEng":"Love leaves a scent behind."},
    {"id":2255,"singerKor":"테이(Tei)","singerEng":"Tei","songKor":"이 소설의 끝을 다시 써보려 해.m4a","songEng":"I'll Try Writing The End of This Novel Again"},
    {"id":2256,"singerKor":"테이크 (Take)","singerEng":"Take","songKor":"나비무덤.mp3","songEng":"Butterfly Grave"},
    {"id":2257,"singerKor":"토니 안","singerEng":"Tony Ahn","songKor":"사랑은 가질 수 없을 때 더 아름답다.mp3","songEng":"Love Is More Beautiful When You Can't Have It"},
    {"id":2258,"singerKor":"토요태","singerEng":"Toyo Tae","songKor":"Still I Love You.mp3","songEng":"Still I Love You"},
    {"id":2259,"singerKor":"토이","singerEng":"Toy","songKor":"세 사람 (With 성시경).mp3","songEng":"Three People (With Sung Si-kyung)"},
    {"id":2260,"singerKor":"토이","singerEng":"Toy","songKor":"좋은 사람.mp3","songEng":"Good Person"},
    {"id":2261,"singerKor":"투개월","singerEng":"오전 2:00","songKor":"여우야.mp3","songEng":"Fox."},
    {"id":2262,"singerKor":"투모로우바이투게더","singerEng":"Tomorrow by Together","songKor":"Devil by the Window.mp3","songEng":"Devil by the Window."},
    {"id":2263,"singerKor":"투모로우바이투게더","singerEng":"TXT","songKor":"Happy Fools (feat. Coi Leray).mp3","songEng":"Happy Fools (feat. Coi Leray)"},
    {"id":2264,"singerKor":"투모로우바이투게더","singerEng":"Tomorrow By Together","songKor":"Sugar Rush Ride.mp3","songEng":"Sugar Rush Ride"},
    {"id":2265,"singerKor":"투모로우바이투게더","singerEng":"Tomorrow by Together","songKor":"Tinnitus (돌멩이가 되고 싶어).mp3","songEng":"Tinnitus (돌멩이가 되고 싶어)"},
    {"id":2266,"singerKor":"투모로우바이투게더","singerEng":"Tomorrow by Together","songKor":"네버랜드를 떠나며.mp3","songEng":"Leaving to Neverland"},
    {"id":2267,"singerKor":"트러블메이커","singerEng":"Trouble Maker","songKor":"내일은 없어.mp3","songEng":"There's no tomorrow."},
    {"id":2268,"singerKor":"티아라","singerEng":"T-ara","songKor":"Bo Peep Bo Peep.mp3","songEng":"Bo Peep Bo Peep"},
    {"id":2269,"singerKor":"티아라","singerEng":"T-ara","songKor":"Cry Cry.mp3","songEng":"Cry Cry"},
    {"id":2270,"singerKor":"티아라","singerEng":"T-ara","songKor":"DAY BY DAY.mp3","songEng":"Day By Day"},
    {"id":2271,"singerKor":"티아라","singerEng":"T-ara","songKor":"Lovey-Dovey.mp3","songEng":"Lovey Dovey"},
    {"id":2272,"singerKor":"티아라","singerEng":"T-ara","songKor":"Roly-Poly.mp3","songEng":"Roly-Poly"},
    {"id":2273,"singerKor":"티아라","singerEng":"T-ara","songKor":"SEXY LOVE.mp3","songEng":"Sexy Love"},
    {"id":2274,"singerKor":"티아라","singerEng":"T-ara","songKor":"TTL (Time To Love).mp3","songEng":"Time To Love"},
    {"id":2275,"singerKor":"티아라","singerEng":"T-ara","songKor":"너 때문에 미쳐.mp3","songEng":"Because of You I'm going Crazy"},
    {"id":2276,"singerKor":"티아라","singerEng":"T-ara","songKor":"너때문에 미쳐.mp3","songEng":"Because of You I'm Going Crazy"},
    {"id":2277,"singerKor":"티아라","singerEng":"T-ara","songKor":"처음처럼.mp3","songEng":"Like The First Time"},
    {"id":2278,"singerKor":"틴탑","singerEng":"Teen Top","songKor":"긴 생머리 그녀 (Miss Right).mp3","songEng":"Miss Right"},
    {"id":2279,"singerKor":"틴탑","singerEng":"Teen Top","songKor":"미치겠어.mp3","songEng":"I Am Going Crazy"},
    {"id":2280,"singerKor":"팀","singerEng":"Team","songKor":"사랑합니다....mp3","songEng":"Love...mp3"},
    {"id":2281,"singerKor":"파리돼지앵","singerEng":"Paris Pigalle","songKor":"순정마초.mp3","songEng":"Pure Love Thistle"},
    {"id":2282,"singerKor":"파이브","singerEng":"Five","songKor":"I'm Sorry.mp3","songEng":"I'm Sorry"},
    {"id":2283,"singerKor":"패닉","singerEng":"Panic","songKor":"달팽이.mp3","songEng":"Snail"},
    {"id":2284,"singerKor":"패티김","singerEng":"Patty Kim","songKor":"가을을 남기고간 사랑.mp3","songEng":"Leaving for Autumn Love"},
    {"id":2285,"singerKor":"펀치 (Punch)","singerEng":"Punch","songKor":"밤이 되니까.mp3","songEng":"When the Night Comes"},
    {"id":2286,"singerKor":"펀치 (Punch)","singerEng":"Punch","songKor":"이 밤이 가면 그대 올까요.mp3","songEng":"Will reply as per instruction:If the song name is already in English_ return English name."},
    {"id":2287,"singerKor":"펀치 (Punch)","singerEng":"Punch","songKor":"헤어지는 중.mp3","songEng":"Breaking Up"},
    {"id":2288,"singerKor":"펀치(Punch)","singerEng":"Punch","songKor":"Done For Me.mp3","songEng":"Done For Me"},
    {"id":2289,"singerKor":"포맨 (4MEN)","singerEng":"4men","songKor":"U.mp3","songEng":"U"},
    {"id":2290,"singerKor":"포맨 (4MEN)","singerEng":"4Men","songKor":"눈 떠보니 이별이더라.mp3","songEng":"Upon opening my eyes_ it was a breakup."},
    {"id":2291,"singerKor":"포맨 (4MEN)","singerEng":"4MEN","songKor":"못해 (feat. 美).mp3","songEng":"Can't."},
    {"id":2292,"singerKor":"포맨 (4MEN)","singerEng":"4MEN","songKor":"살다가 한번쯤.mp3","songEng":"Once in a Lifetime"},
    {"id":2293,"singerKor":"포맨 (4MEN)","singerEng":"4MEN","songKor":"안녕 나야.mp3","songEng":"Goodbye I'm Leaving"},
    {"id":2294,"singerKor":"포맨 (4MEN)","singerEng":"4MEN","songKor":"청혼하는 거예요.mp3","songEng":"Engagement by Paul Kim"},
    {"id":2295,"singerKor":"포맨_ 美","singerEng":"Poman_ Me","songKor":"그 남자 그 여자.mp3","songEng":"That Man That Woman"},
    {"id":2296,"singerKor":"포미닛(4minute)","singerEng":"4minute","songKor":"Heart To Heart.mp3","songEng":"Heart To Heart"},
    {"id":2297,"singerKor":"포미닛(4minute)","singerEng":"4minute","songKor":"거울아 거울아.mp3","songEng":"Mirror Mirror"},
    {"id":2298,"singerKor":"포지션","singerEng":"Position","songKor":"I Love You.mp3","songEng":"I Love You"},
    {"id":2299,"singerKor":"포지션(Position)","singerEng":"Position","songKor":"Desperado.mp3","songEng":"Desperado"},
    {"id":2300,"singerKor":"포지션(PoSiTion)","singerEng":"Position","songKor":"Remember.mp3","songEng":"Remember"},
    {"id":2301,"singerKor":"포지션(Position)","singerEng":"Position","songKor":"마지막 약속.mp3","songEng":"Last Promise"},
    {"id":2302,"singerKor":"포지션(Position)","singerEng":"Position","songKor":"하루.mp3","songEng":"Day One"},
    {"id":2303,"singerKor":"폴킴 (Paul Kim)","singerEng":"Paul Kim","songKor":"너를 만나.mp3","songEng":"Meet You Now"},
    {"id":2304,"singerKor":"폴킴 (Paul Kim)","singerEng":"Paul Kim","songKor":"모든 날_ 모든 순간 (Every day_ Every Moment).mp3","songEng":"Every day_ Every Moment"},
    {"id":2305,"singerKor":"폴킴 (Paul Kim)","singerEng":"Paul Kim","songKor":"비.mp3","songEng":"Rain."},
    {"id":2306,"singerKor":"폴킴 (Paul Kim)","singerEng":"Paul Kim","songKor":"안녕.mp3","songEng":"Goodbye"},
    {"id":2307,"singerKor":"폴킴 (Paul Kim)","singerEng":"Paul Kim","songKor":"어제처럼.mp3","songEng":"Yesterday_ like it used to be"},
    {"id":2308,"singerKor":"폴킴 (Paul Kim)","singerEng":"Paul Kim","songKor":"초록빛.mp3","songEng":"Green Color"},
    {"id":2309,"singerKor":"퓨처라이거","singerEng":"Future R&B.","songKor":"Let`s Dance.mp3","songEng":"Let's Dance"},
    {"id":2310,"singerKor":"프라이머리","singerEng":"Primary","songKor":"(물음표) (Feat. 최자 Of 다이나믹듀오_ Zion.T).mp3","songEng":"Question Mark."},
    {"id":2311,"singerKor":"프라이머리","singerEng":"Primary","songKor":"씨스루 (Feat. Zion.T_ 개코 Of 다이나믹듀오).mp3","songEng":"No Love (Feat. Zion.T_ Gaeko of Dynamic Duo)"},
    {"id":2312,"singerKor":"프라이머리","singerEng":"Primary","songKor":"아끼지마 (Feat. 초아(AOA)_ 아이언) (Don`t Be Shy).mp3","songEng":"Don't Be Shy (Feat. Choa (AOA)_ Iron)"},
    {"id":2313,"singerKor":"프라이머리","singerEng":"Primary","songKor":"자니 (Feat. Dynamic Duo).mp3","songEng":"Jani (Feat. Dynamic Duo)"},
    {"id":2314,"singerKor":"프라이머리","singerEng":"Primary","songKor":"조만간 봐요 (Feat. BSK_ 개코).mp3","songEng":"See You Soon (Feat. BSK_ Gaeko)"},
    {"id":2315,"singerKor":"프리 스타일","singerEng":"Freestyle","songKor":"수취인불명.mp3","songEng":"Recipient Unknown"},
    {"id":2316,"singerKor":"프리스타일","singerEng":"Freestyle.","songKor":"Y (Feat. 애즈원).mp3","songEng":"Y (Feat. As One)"},
    {"id":2317,"singerKor":"프리스타일","singerEng":"Freestyle","songKor":"그리고그후.mp3","songEng":"And after that"},
    {"id":2318,"singerKor":"프리스타일","singerEng":"Freestyle","songKor":"마음으로 하는 말.mp3","songEng":"Words spoken from the heart"},
    {"id":2319,"singerKor":"플라워(Flower)","singerEng":"Flower","songKor":"Crying.mp3","songEng":"Crying"},
    {"id":2320,"singerKor":"플라워(Flower)","singerEng":"Flower","songKor":"Endless.mp3","songEng":"Endless"},
    {"id":2321,"singerKor":"플라이투더스카이(flytothesky)","singerEng":"Fly to the Sky","songKor":"Day By Day.mp3","songEng":"Day By Day"},
    {"id":2322,"singerKor":"플라이투더스카이(flytothesky)","singerEng":"Fly to the Sky","songKor":"My Angel.mp3","songEng":"My Angel"},
    {"id":2323,"singerKor":"플라이투더스카이(flytothesky)","singerEng":"Fly to the Sky","songKor":"남자답게.mp3","songEng":"Manly."},
    {"id":2324,"singerKor":"플라이투더스카이(flytothesky)","singerEng":"Fly to the Sky","songKor":"너를 너를 너를.mp3","songEng":"You You You"},
    {"id":2325,"singerKor":"피노키오","singerEng":"Pinocchio","songKor":"다시만난 너에게.mp3","songEng":"Again My Love"},
    {"id":2326,"singerKor":"핑클","singerEng":"Fin.K.L","songKor":"영원.mp3","songEng":"Eternal"},
    {"id":2327,"singerKor":"핑클","singerEng":"Fin.K.L","songKor":"영원한사랑.mp3","songEng":"Endless Love"},
    {"id":2328,"singerKor":"핑클","singerEng":"Fin.K.L","songKor":"Now.mp3","songEng":"Now"},
    {"id":2329,"singerKor":"하동균","singerEng":"Hadonggyun","songKor":"그녀를 사랑해줘요.mp3","songEng":"Love Her by Lee Jong-suk"},
    {"id":2330,"singerKor":"하동균","singerEng":"Hadonggyun","songKor":"나비야.mp3","songEng":"Butterfly"},
    {"id":2331,"singerKor":"하림","singerEng":"Ha Rim","songKor":"여기보다 어딘가엔.mp3","songEng":"Somewhere Out There"},
    {"id":2332,"singerKor":"하울","singerEng":"Howl","songKor":"Perhaps Love (사랑인가요).mp3","songEng":"Perhaps Love"},
    {"id":2333,"singerKor":"하이라이트 (Highlight)","singerEng":"Highlight","songKor":"DAYDREAM.mp3","songEng":"Daydream"},
    {"id":2334,"singerKor":"하이라이트(Highlight)","singerEng":"Highlight","songKor":"DAYDREAM.mp3","songEng":"Daydream"},
    {"id":2335,"singerKor":"하하","singerEng":"Haha","songKor":"너는 내 운명.mp3","songEng":"You are My Destiny"},
    {"id":2336,"singerKor":"하현우 (국카스텐)","singerEng":"HaHyunwoo (Guckkasten)","songKor":"돌덩이.mp3","songEng":"Stone."},
    {"id":2337,"singerKor":"한동근","singerEng":"Handonggeun","songKor":"사랑인걸.mp3","songEng":"Love is..."},
    {"id":2338,"singerKor":"한동근","singerEng":"Han Dong Geun","songKor":"새벽에?걸려온?너의?전화는?(dawn?call?from?you).mp3","songEng":"Dawn Call from You"},
    {"id":2339,"singerKor":"한동근","singerEng":"Han Dong Geun","songKor":"이 소설의 끝을 다시 써보려 해.mp3","songEng":"Attempt to Rewrite the Ending of This Novel by SG Wannabe"},
    {"id":2340,"singerKor":"한동준","singerEng":"Handongjun","songKor":"사랑의 서약.mp3","songEng":"Oath of Love"},
    {"id":2341,"singerKor":"한마음","singerEng":"Hanmaum","songKor":"가슴앓이.mp3","songEng":"Heartache"},
    {"id":2342,"singerKor":"한영애","singerEng":"Hanyoungae","songKor":"목포의 눈물.mp3","songEng":"Tears of Mokpo"},
    {"id":2343,"singerKor":"함중아","singerEng":"Ham Jung Ah","songKor":"내게도 사랑이.mp3","songEng":"My Love by Lee Hi"},
    {"id":2344,"singerKor":"해바라기","singerEng":"Sunflower.","songKor":"내 마음의 보석상자.mp3","songEng":"Jewel Box of My Heart"},
    {"id":2345,"singerKor":"해바라기","singerEng":"Sunflower","songKor":"모두가 사랑이에요.mp3","songEng":"Everyone is in Love."},
    {"id":2346,"singerKor":"해바라기","singerEng":"Sunflower","songKor":"이젠 사랑할 수 있어요.mp3","songEng":"Now I can love.mp3"},
    {"id":2347,"singerKor":"해이(Hey)","singerEng":"Hey","songKor":"Je T'aime(주뗌므).mp3","songEng":"Je T'aime."},
    {"id":2348,"singerKor":"햇빛촌","singerEng":"Sun Village","songKor":"유리창엔 비.mp3","songEng":"On The Window"},
    {"id":2349,"singerKor":"행주_ 양홍원 (Young B)_ Hash Swan_ 킬라그램 (Killagramz)","singerEng":"Haengju_ Yang Hongwon_ Young B_ Hash Swan_ Kilogramz","songKor":"요즘것들 (Feat. ZICO_ DEAN).mp3","songEng":"Recent Things (Feat. ZICO_ DEAN)"},
    {"id":2350,"singerKor":"허각","singerEng":"Huh Gak","songKor":"1440.mp3","songEng":"1440"},
    {"id":2351,"singerKor":"허각","singerEng":"Huh Gak","songKor":"hello.mp3","songEng":"Hello."},
    {"id":2352,"singerKor":"허각","singerEng":"Huh Gak","songKor":"고백 (바른연애 길잡이 X 허각).mp3","songEng":"Confession (Barunyeonae Giljab-i X Huh Gak)"},
    {"id":2353,"singerKor":"허각","singerEng":"Huh Gak","songKor":"넌 내꺼라는걸 (Feat. 스윙스).mp3","songEng":"You're Mine (Feat. Swings)"},
    {"id":2354,"singerKor":"허각","singerEng":"Huh Gak","songKor":"모노드라마 (With 유승우).mp3","songEng":"Monodrama (With Yu Seungwoo)"},
    {"id":2355,"singerKor":"허각","singerEng":"Heo Gak","songKor":"바보야.mp3","songEng":"Foolish Love"},
    {"id":2356,"singerKor":"허각","singerEng":"Huh Gak","songKor":"사월의 눈.mp3","songEng":"April Snow"},
    {"id":2357,"singerKor":"허각","singerEng":"Huh Gak","songKor":"아프다.mp3","songEng":"Apologize"},
    {"id":2358,"singerKor":"허각","singerEng":"Huh Gak","songKor":"언제나.mp3","songEng":"Always"},
    {"id":2359,"singerKor":"허각","singerEng":"Huh Gak","songKor":"오늘 같은 눈물이.mp3","songEng":"Today's Tears"},
    {"id":2360,"singerKor":"허각","singerEng":"Huh Gak","songKor":"우린 어쩌다 헤어진 걸까.mp3","songEng":"Why by Taeyeon"},
    {"id":2361,"singerKor":"허각","singerEng":"Huh Gak","songKor":"흔한 이별.mp3","songEng":"Common Farewell"},
    {"id":2362,"singerKor":"허각_ 정은지 (에이핑크)","singerEng":"Huh Gak_ Jeong Eun-ji (Apink)","songKor":"이제 그만 싸우자.mp3","songEng":"Let's not fight anymore"},
    {"id":2363,"singerKor":"허각_ 정은지 (에이핑크)","singerEng":"Huh Gak_ Jung Eun-ji (Apink)","songKor":"짧은머리.mp3","songEng":"Short Hair"},
    {"id":2364,"singerKor":"허각_ 정인","singerEng":"Huh Gak_ Jung In","songKor":"동네술집.mp3","songEng":"Neighborhood Bar"},
    {"id":2365,"singerKor":"허각_ 지아","singerEng":"Huh Gak_ Ji-a","songKor":"I Need You.mp3","songEng":"I Need You"},
    {"id":2366,"singerKor":"허성현","singerEng":"Huh Sung-Hyun","songKor":"미운오리새끼 (Feat. 선우정아_ BOBBY) (Prod. R.Tee).mp3","songEng":"Ugly Duckling (Feat. Sunwoo Jung-A_ BOBBY) (Prod. R.Tee)"},
    {"id":2367,"singerKor":"허영란","singerEng":"Heo Young-ran","songKor":"날개.mp3","songEng":"Wings"},
    {"id":2368,"singerKor":"헤이즈 (Heize)","singerEng":"Heize","songKor":"And July (Feat. DEAN_ DJ Friz).mp3","songEng":"And July (Feat. DEAN_ DJ Friz)"},
    {"id":2369,"singerKor":"헤이즈 (HEIZE)","singerEng":"Heize","songKor":"jenga (Feat. Gaeko).mp3","songEng":"Jenga (Feat. Gaeko)"},
    {"id":2370,"singerKor":"헤이즈 (Heize)","singerEng":"Heize","songKor":"널 너무 모르고.mp3","songEng":"You don't know love by K.Will"},
    {"id":2371,"singerKor":"헤이즈 (Heize)","singerEng":"Heize","songKor":"돌아오지마 (Feat. 용준형 Of 비스트).mp3","songEng":"Don't Come Back (Feat. Yong Junhyung of Beast)"},
    {"id":2372,"singerKor":"헤이즈 (Heize)","singerEng":"Heize","songKor":"비가 오는 날엔 (2021).mp3","songEng":"On Rainy Days."},
    {"id":2373,"singerKor":"헤이즈 (Heize)","singerEng":"Heize","songKor":"비도 오고 그래서 (Feat. 신용재).mp3","songEng":"Rain and So You (Feat. Shin Yong Jae)"},
    {"id":2374,"singerKor":"헤이즈 (Heize)","singerEng":"Heize","songKor":"엄마가 필요해.mp3","songEng":"I Need a Mom."},
    {"id":2375,"singerKor":"헤이즈 (Heize)","singerEng":"Heize","songKor":"저 별.mp3","songEng":"Star."},
    {"id":2376,"singerKor":"헤이즈 (Heize)","singerEng":"Heize","songKor":"헤픈 우연 (HAPPEN).mp3","songEng":"Happen"},
    {"id":2377,"singerKor":"헤이즈(Heize)","singerEng":"Heize","songKor":"We don`t talk together (Feat. 기리보이) (Prod. SUGA).mp3","songEng":"We don't talk together"},
    {"id":2378,"singerKor":"헤이즈(Heize)","singerEng":"Heize","songKor":"내 맘을 볼수 있나요.mp3","songEng":"Can You See My Heart?"},
    {"id":2379,"singerKor":"헤이즈(Heize)","singerEng":"Heize","songKor":"비도 오고 그래서 (Feat. 신용재).mp3","songEng":"Because It's Raining (Feat. Shin Yong Jae)"},
    {"id":2380,"singerKor":"혁오 (HYUKOH)","singerEng":"Hyukoh","songKor":"TOMBOY.mp3","songEng":"Tomboy"},
    {"id":2381,"singerKor":"현아 (4minute)","singerEng":"Hyuna","songKor":"Bubble Pop!.mp3","songEng":"Bubble Pop"},
    {"id":2382,"singerKor":"현아 (4minute)","singerEng":"Hyuna","songKor":"Change (Feat. 용준형 From Beast).mp3","songEng":"Change (Feat. Yong Jun-hyung From Beast)"},
    {"id":2383,"singerKor":"현아 (4minute)","singerEng":"Hyuna","songKor":"Ice Cream (Feat. Maboos).mp3","songEng":"Ice Cream (Feat. Maboos)"},
    {"id":2384,"singerKor":"현아 (4minute)","singerEng":"Hyuna","songKor":"빨개요.mp3","songEng":"Red Flavor"},
    {"id":2385,"singerKor":"형용돈죵 (정형돈 & G-DRAGON)","singerEng":"Hyungyongdonjong (or Hyungyongdon Song)","songKor":"해볼라고.mp3","songEng":"Haebolla-go"},
    {"id":2386,"singerKor":"호미들","singerEng":"Hommeuil","songKor":"사이렌 Remix (Feat. UNEDUCATED KID_ Paul Blanco).mp3","songEng":"Si Remix (Feat. UNEDUCATED KID_ Paul Blanco)"},
    {"id":2387,"singerKor":"호미들_ Uneducated Kid_ Paul Blanco","singerEng":"Homidel Uneducated Kid Paul Blanco","songKor":"사이렌 Remix (Feat. UNEDUCATED KID_ Paul Blanco).mp3","songEng":"Si Remix (Feat. UNEDUCATED KID_ Paul Blanco)"},
    {"id":2388,"singerKor":"홍경민","singerEng":"Hong Kyung-min","songKor":"Tonight.mp3","songEng":"Tonight"},
    {"id":2389,"singerKor":"홍경민","singerEng":"Hong Kyung-min","songKor":"가져가.mp3","songEng":"Take."},
    {"id":2390,"singerKor":"홍경민","singerEng":"Hong Kyung Min","songKor":"그녀의 매력 Disco.mp3","songEng":"Her Charm Disco"},
    {"id":2391,"singerKor":"홍경민","singerEng":"Hong Kyung-Min.","songKor":"사랑_ 참....mp3","songEng":"Love_ Really..."},
    {"id":2392,"singerKor":"홍경민","singerEng":"Hong Kyung-min","songKor":"후(後).mp3","songEng":"Later."},
    {"id":2393,"singerKor":"홍경민","singerEng":"Hong Kyung-min","songKor":"흔들린우정.mp3","songEng":"Shaken Friendship"},
    {"id":2394,"singerKor":"홍민","singerEng":"Hong Min","songKor":"결혼기념일의 노래.mp3","songEng":"Song for the Anniversary of My Marriage"},
    {"id":2395,"singerKor":"홍수철","singerEng":"Hong Su-cheol","songKor":"철없던 사랑.mp3","songEng":"Foolish Love"},
    {"id":2396,"singerKor":"홍진영","singerEng":"Hong Jin Young","songKor":"오늘 밤에.mp3","songEng":"Tonight by Jinu of WINNER."},
    {"id":2397,"singerKor":"화사 (Hwa Sa)","singerEng":"Hwa Sa","songKor":"마리아 (Maria).mp3","songEng":"Maria"},
    {"id":2398,"singerKor":"화사 (Hwa Sa)","singerEng":"Hwa Sa","songKor":"멍청이(twit).mp3","songEng":"Twit"},
    {"id":2399,"singerKor":"화요비","singerEng":"Hwasa","songKor":"Bye Bye Bye.mp3","songEng":"Bye Bye Bye"},
    {"id":2400,"singerKor":"화요비","singerEng":"Hwa Yobi","songKor":"Lie.mp3","songEng":"Lie"},
    {"id":2401,"singerKor":"화요비","singerEng":"Hwayobi","songKor":"당신과의 키스를 세어보아요.mp3","songEng":"Counting Kisses with You"},
    {"id":2402,"singerKor":"화요비","singerEng":"Hwasa","songKor":"반쪽.mp3","songEng":"Banjjak"},
    {"id":2403,"singerKor":"환불원정대","singerEng":"Refund Expedition","songKor":"DON'T TOUCH ME.mp3","songEng":"Don't Touch Me"},
    {"id":2404,"singerKor":"환희","singerEng":"Hwanhee","songKor":"Tomorrow.mp3","songEng":"Tomorrow"},
    {"id":2405,"singerKor":"환희","singerEng":"Hwanhee","songKor":"내사람.mp3","songEng":"My person"},
    {"id":2406,"singerKor":"황광희 X 개코","singerEng":"Hwang Kwanghee X Gaecko","songKor":"당신의 밤 (Feat. 오혁).mp3","songEng":"Your reply would be: Your Night (Feat. Oh Hyuk)"},
    {"id":2407,"singerKor":"황인욱","singerEng":"Hwang In-wook","songKor":"강변북로.mp3","songEng":"Gangnam Style"},
    {"id":2408,"singerKor":"황인욱","singerEng":"Hwang In-wook","songKor":"취했나봐.mp3","songEng":"I think I'm drunk"},
    {"id":2409,"singerKor":"황인욱","singerEng":"Hwang In-wook","songKor":"포장마차.mp3","songEng":"Foam Song"},
    {"id":2410,"singerKor":"황치열","singerEng":"Hwang Chi Yeol","songKor":"매일 듣는 노래 (A Daily Song).mp3","songEng":"A Daily Song"},
    {"id":2411,"singerKor":"황치훈","singerEng":"Hwang Chi-hoon","songKor":"추억속의 그대.mp3","songEng":"Memories of You"},
    {"id":2412,"singerKor":"황태지 (황광희_ 태양_ 지드래곤)","singerEng":"Hwang Ta-ji (Hwang Kwang-hee_ Taeyang_ G-Dragon)","songKor":"맙소사.mp3","songEng":"Oops!"},
    {"id":2413,"singerKor":"효린 (씨스타)","singerEng":"Hyorin","songKor":"안녕.mp3","songEng":"Goodbye"},
    {"id":2414,"singerKor":"효린","singerEng":"Hyolyn","songKor":"널 사랑하겠어.mp3","songEng":"I Will Love You"},
    {"id":2415,"singerKor":"효린_ 창모 (CHANGMO)","singerEng":"Hyolyn_ Changmo","songKor":"BLUE MOON (Prod. GroovyRoom).mp3","songEng":"Blue Moon"},
    {"id":2416,"singerKor":"휘성","singerEng":"Wheesung","songKor":"I Am Missing You.mp3","songEng":"I Am Missing You"},
    {"id":2417,"singerKor":"휘성","singerEng":"Whisung","songKor":"Insomnia (불면증).mp3","songEng":"Insomnia"},
    {"id":2418,"singerKor":"휘성","singerEng":"Wheesung","songKor":"with me.mp3","songEng":"With You"},
    {"id":2419,"singerKor":"휘성","singerEng":"Wheesung","songKor":"가슴 시린 이야기 (Feat. 용준형 Of Beast).mp3","songEng":"Heartding Story (Feat. Yong Junhyung Of Beast)"},
    {"id":2420,"singerKor":"휘성","singerEng":"Wheesung","songKor":"불치병.mp3","songEng":"Terminal Illness"},
    {"id":2421,"singerKor":"휘성","singerEng":"Wheesung","songKor":"사랑은 맛있다 .mp3","songEng":"Love is Delicious"},
    {"id":2422,"singerKor":"휘성","singerEng":"Wheesung","songKor":"안 되나요....mp3","songEng":"Can't Bear Anymore"},
    {"id":2423,"singerKor":"휘성","singerEng":"Wheesung","songKor":"전할 수 없는 이야기.mp3","songEng":"Unspeakable Story"},
    {"id":2424,"singerKor":"휘인 (Whee In)","singerEng":"Whee In","songKor":"너_ 너 (N번째 연애 X 휘인 (Whee In)).mp3","songEng":"You_ You (Nth Love X Whee In)"},
    {"id":2425,"singerKor":"휘인 (Whee In)","singerEng":"Whee In","songKor":"헤어지자 (Prod 정키).mp3","songEng":"Let's Break Up (Prod. JungKey)"},
    {"id":2426,"singerKor":"희자매","singerEng":"Heejae","songKor":"그 사람 바보 (1980年).mp3","songEng":"Foolish Person."}
];

export default songData;